/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Tab, Tabs, Button, Popover } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import OwnerDashboardBookingsTable from '../OwnerDashboardBookingsTable';
import { IMyBookings } from '../../../Interfaces/common/MyBookings/MyBookings';
import {
  dashboardBookingSectionEnums,
  filterDateFormat,
  // filterDateFormat,
  myBookingsStatusEnums,
} from '../../../Utils/enums';
import chevronDown from '../../../Assets/Svgs/chevronDown.svg';
import './ownerDashboard.css';
import {
  _dayjs,
  getDetailsFromLocalStorageForString,
} from '../../../Utils/utils';
import InternalRoute from '../../../Utils/internalRoutes';

interface IOwnerDashboardBookingsProps {
  allBookings: IMyBookings[];
  isLoading: boolean;
}
interface DateRange {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

const tabIndexArray = ['active', 'completed', 'cancelled', 'rejected'];

const OwnerDashboardBookings: React.FC<IOwnerDashboardBookingsProps> = (
  props
): JSX.Element => {
  const { allBookings, isLoading } = props;
  const [value, setValue] = React.useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentBookingType, setCurrentBookingType] = React.useState<
    string | null
  >(null);
  const [currentBookings, setCurrentBookings] = React.useState<IMyBookings[]>(
    []
  );
  const popoverRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  const [openFilterPopover, setOpenFilterPopover] = useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: dayjs(startDate) || null,
    endDate: dayjs(endDate) || null,
  });
  const handleChange = (
    _event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  const filterBookingsByDate = (bookings: IMyBookings[]) => {
    const rangeStartDate = dateRange.startDate
      ? _dayjs(dateRange.startDate).startOf('day')
      : null;
    const rangeEndDate = dateRange.endDate
      ? _dayjs(dateRange.endDate).endOf('day')
      : null;

    const filteredBookings = bookings.filter((booking) => {
      const bookingStartDate = _dayjs(booking.startDate).startOf('day');
      const bookingEndDate = _dayjs(booking.endDate).endOf('day');

      return (
        (bookingStartDate.isSame(rangeStartDate) &&
          bookingEndDate.isSame(rangeEndDate)) ||
        (bookingStartDate.isAfter(rangeStartDate) &&
          bookingEndDate.isBefore(rangeEndDate)) ||
        bookingStartDate.isSame(rangeStartDate) ||
        bookingEndDate.isSame(rangeEndDate)
      );
    });

    const currentBookingsResults = filteredBookings.filter(
      (booking) => booking.type === currentBookingType
    );

    setCurrentBookings(currentBookingsResults);
    setOpenFilterPopover(false);

    const formattedStartDateString = rangeStartDate?.toISOString();
    const formattedEndDateString = rangeEndDate?.toISOString();

    searchParams.set('startDate', formattedStartDateString ?? '');
    searchParams.set('endDate', formattedEndDateString ?? '');
    setSearchParams(searchParams);

    return filteredBookings;
  };

  useEffect(() => {
    const pathName = location.pathname;
    if (pathName.includes(dashboardBookingSectionEnums.confirmed)) {
      setValue(0);
      setCurrentBookingType(dashboardBookingSectionEnums.confirmed);
    }
    if (pathName.includes(dashboardBookingSectionEnums.cancelled)) {
      setValue(1);
      setCurrentBookingType(dashboardBookingSectionEnums.cancelled);
    }
    if (pathName.includes(dashboardBookingSectionEnums.completed)) {
      setValue(2);
      setCurrentBookingType(dashboardBookingSectionEnums.completed);
    }
    if (pathName.includes(dashboardBookingSectionEnums.rejected)) {
      setValue(3);
      setCurrentBookingType(dashboardBookingSectionEnums.rejected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBookingsList = (currentType: string) => {
    let localAllBookings = [];
    if (
      (dateRange.startDate?.isBefore(dateRange.endDate) ||
        dateRange.startDate?.isSame(dateRange.endDate)) &&
      dateRange.endDate &&
      dateRange.startDate
    ) {
      localAllBookings = filterBookingsByDate(allBookings);
    } else {
      localAllBookings = allBookings;
    }
    const filteredBookings = localAllBookings.filter(
      (booking) => booking.type === currentType
    );

    setCurrentBookings(filteredBookings);
    setCurrentBookingType(currentType);
    let path;
    if (currentType === dashboardBookingSectionEnums.completed) {
      path = InternalRoute.statusCompleted;
    } else if (currentType === dashboardBookingSectionEnums.cancelled) {
      path = InternalRoute.statusCancelled;
    } else if (currentType === dashboardBookingSectionEnums.confirmed) {
      path = InternalRoute.statusConfirmed;
    } else if (currentType === dashboardBookingSectionEnums.rejected) {
      path = InternalRoute.statusRejected;
    } else {
      path = InternalRoute.ownerDashboard;
    }
    navigate(`${path}?${searchParams.toString()}`, { replace: true });
  };

  const onStartDateChange = (dateValue: Dayjs | null) => {
    setDateRange((pre: DateRange) => {
      if (dateValue) {
        return { ...pre, startDate: dateValue };
      }
      return { ...pre };
    });
  };

  const onEndDateChange = (dateValue: Dayjs | null) => {
    setDateRange((pre: DateRange) => {
      if (dateValue) {
        return { ...pre, endDate: dateValue };
      }
      return { ...pre };
    });
  };

  const updateTabPanel = () => {
    // eslint-disable-next-line prefer-const
    let finalArrayForTabNames: { name: string; index: number }[] = [];

    // created array of all booking types without duplicate names
    const tabNamesArray = allBookings.map((booking) => booking.type);
    const arrayWithoutDuplicateNames = Array.from(new Set(tabNamesArray));

    // creating Array in Tab orders
    tabIndexArray.forEach((name, index) => {
      if (arrayWithoutDuplicateNames.includes(name)) {
        finalArrayForTabNames.push({ name, index });
      }
    });

    // assigning current type for update tab status
    const indexOfCurrentBookingType = finalArrayForTabNames.findIndex(
      (tabObject) => tabObject.name === currentBookingType
    );

    setValue(currentBookingType ? indexOfCurrentBookingType : 0);
    if (finalArrayForTabNames.length > 0)
      filterBookingsList(currentBookingType || finalArrayForTabNames[0].name);
  };

  useEffect(() => {
    if (allBookings.length > 0) {
      updateTabPanel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBookings]);

  const isIncludesBookingType = (type: string): boolean => {
    const isInclude =
      allBookings.filter((booking) => booking.type === type).length > 0;
    return isInclude;
  };

  // const open = Boolean(openFilterPopover);
  const id = openFilterPopover ? 'simple-popover' : undefined;

  const handleClosePopover = () => {
    setOpenFilterPopover(false);
  };

  const bookingDateFilterHandler = () => {
    filterBookingsByDate(allBookings);
  };

  const clearDateRangeFilter = () => {
    setDateRange({ startDate: null, endDate: null });
    const filteredBookings = allBookings.filter(
      (booking) => booking.type === currentBookingType
    );
    setSearchParams({});
    setCurrentBookings(filteredBookings);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderStyle = () => {
    const theme = getDetailsFromLocalStorageForString('theme');
    if (theme === 'dark') {
      return {
        '& .MuiInputLabel-shrink': {
          backgroundColor: '#323232 !important',
          color: '#ffffffb3 !important',
          marginTop: '-7px !important',
        },
      };
    }

    return {
      '& .MuiInputLabel-shrink': {
        backgroundColor: 'white !important',
        color: '#0009 !important',
        marginTop: '-7px !important',
      },
    };
  };

  return (
    <div className="owner-dashboard-container flex justify-center">
      <div className="max-w-midXL w-[94%]">
        <header className="flex justify-between">
          <h1 className="font-bold text-2xl sm:text-[32px] 2xl:text-4xl dark:text-white">
            {ApplicationString.OwnerDashboard.bookings.mainHeadBookings}
          </h1>
          <Button
            aria-describedby={id}
            variant="outlined"
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            data-testid="dashboard-filter-button"
            onClick={() => setOpenFilterPopover(true)}
            ref={popoverRef}
            className="flex justify-center rounded-4xl hover:bg-primary/80 normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 px-6  text-white bg-primary"
            endIcon={
              <img src={chevronDown} alt="" className="w-[15px] sm:w-[17px]" />
            }
          >
            {ApplicationString.OwnerDashboard.bookings.filterButtonTxt}
          </Button>
          <Popover
            id={id}
            open={openFilterPopover}
            className="owner-dashboard-popup"
            anchorEl={popoverRef.current}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            data-testid={`filter-popover-${id}`}
          >
            <div className="p-4 w-[270px] 2xsm:w-[330px]">
              <h2 className="text-primary text-base">
                {ApplicationString.OwnerDashboard.bookings.popOverText}
              </h2>
              <div className="flex my-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    <DatePicker
                      label={
                        ApplicationString.OwnerDashboard.bookings.startDate
                      }
                      onChange={onStartDateChange}
                      data-testid="dashboard-filter-start-date-picker"
                      value={dateRange.startDate}
                      format={filterDateFormat}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          inputProps: {
                            id: 'startDateInput',
                          },
                          sx: {
                            '& .MuiInputBase-root:before': { display: 'none' },
                            '& .MuiInputBase-root:after': {
                              borderBottom: '0px solid transparent',
                            },
                            '& .MuiFormControl-root': {
                              border: '1px solid red',
                            },
                            '& .MuiTabs-scroller::-webkit-scrollbar': {
                              height: '0px !important',
                              width: '0px !important',
                            },
                            ...renderStyle(),
                          },
                        },
                      }}
                      sx={{
                        borderTopLeftRadius: '5px',
                        borderBottomLeftRadius: '5px',
                        borderTopRightRadius: '0px',
                        borderBottomRightRadius: '0px',
                      }}
                      className="rounded-tl-2xl rounded-bl-2xl rounded-tr-[0] rounded-br-[0]"
                    />
                  </DemoContainer>
                  <DemoContainer
                    components={['DatePicker']}
                    sx={{
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    <DatePicker
                      label={ApplicationString.OwnerDashboard.bookings.endDate}
                      onChange={onEndDateChange}
                      data-testid="dashboard-filter-end-date-picker"
                      value={dateRange.endDate}
                      slotProps={{
                        textField: {
                          variant: 'standard',
                          inputProps: {
                            id: 'endDateInput',
                          },
                          sx: {
                            '& .MuiInputBase-root:before': { display: 'none' },
                            '& .MuiInputBase-root:after': {
                              borderBottom: '0px solid transparent',
                            },
                            ...renderStyle(),
                          },
                        },
                      }}
                      className="rounded-tr-2xl rounded-br-2xl rounded-tl-[0] rounded-bl-[0]"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              {dateRange.startDate?.isAfter(dateRange.endDate) && (
                <span className="block text-red-500 text-sm my-4 select-none">
                  {
                    ApplicationString.OwnerDashboard.bookings
                      .dateValidationMessage
                  }
                </span>
              )}
              <div className="flex justify-between items-center">
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!dateRange.startDate && !dateRange.endDate}
                  data-testid="dashboard-filter-clear-button"
                  onClick={() => clearDateRangeFilter()}
                  className="capitalize border-[1px] border-[#292929] dark:border-[#FAFAFA] rounded-[50px] text-xs font-bold text-[#292929] sm:py-3 py-2 px-6 dark:text-[#FAFAFA] bg-white dark:bg-dark_bg_secondary sm:text-sm dark:disabled:opacity-[0.5]"
                >
                  {ApplicationString.OwnerDashboard.bookings.clear}
                </Button>
                <Button
                  variant="outlined"
                  disabled={
                    !dateRange.startDate ||
                    !dateRange.endDate ||
                    dateRange.startDate?.isAfter(dateRange.endDate)
                  }
                  data-testid="dashboard-filter-apply-dateRange-btn"
                  color="primary"
                  onClick={() => bookingDateFilterHandler()}
                  sx={{ '& .MuiButton-root.Mui-disabled': { color: 'white' } }}
                  className="flex justify-center rounded-4xl my-4 float-right hover:bg-primary/80 normal-case sm:text-sm text-xs font-bold sm:py-3 py-2 px-6 text-white bg-primary disabled:text-white disabled:opacity-[0.6] "
                >
                  {ApplicationString.OwnerDashboard.bookings.apply}
                </Button>
              </div>
            </div>
          </Popover>
        </header>
        <div>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                '& .MuiTabs-scroller': {
                  overflow: 'scroll  !important',
                  overflowY: 'hidden !important',
                  overflowX: 'scroll !important',
                },
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {isIncludesBookingType(myBookingsStatusEnums.active) && (
                  <Tab
                    label={ApplicationString.OwnerDashboard.bookings.confirmed}
                    className="normal-case text-[1rem]"
                    onClick={() =>
                      filterBookingsList(myBookingsStatusEnums.active)
                    }
                  />
                )}
                {isIncludesBookingType(myBookingsStatusEnums.completed) && (
                  <Tab
                    label={ApplicationString.OwnerDashboard.bookings.completed}
                    className="normal-case text-[1rem]"
                    onClick={() =>
                      filterBookingsList(myBookingsStatusEnums.completed)
                    }
                  />
                )}
                {isIncludesBookingType(myBookingsStatusEnums.cancelled) && (
                  <Tab
                    label={ApplicationString.OwnerDashboard.bookings.cancelled}
                    className="normal-case text-[1rem]"
                    onClick={() =>
                      filterBookingsList(myBookingsStatusEnums.cancelled)
                    }
                  />
                )}
                {isIncludesBookingType(myBookingsStatusEnums.rejected) && (
                  <Tab
                    label={ApplicationString.OwnerDashboard.bookings.rejected}
                    className="normal-case text-[1rem]"
                    onClick={() =>
                      filterBookingsList(myBookingsStatusEnums.rejected)
                    }
                  />
                )}
              </Tabs>
            </Box>

            <div className="py-8">
              <div className="flex justify-center flex-col midXL:shadow-[0px_4px_16px_0px_#1212120F] shadow-none rounded-3xl gap-4 midXL:gap-0">
                <header className="flex gap-2 max-[1200px]:hidden bg-formFieldBg rounded-tr-3xl rounded-tl-3xl px-4 py-3 text-secondary_text dark:bg-[#333333] dark:text-[#B8B8B8] shadow-[0px_4px_16px_0px_#1212120F]">
                  <span className="w-[30%]">
                    {
                      ApplicationString.OwnerDashboard.instantBooking
                        .vehicleName
                    }
                  </span>
                  <span className="w-[25%]">
                    {' '}
                    {
                      ApplicationString.OwnerDashboard.instantBooking
                        .dateAndTime
                    }
                  </span>
                  <span className="w-[10%]">
                    {' '}
                    {ApplicationString.OwnerDashboard.instantBooking.price}
                  </span>
                  <span className="w-[10%]">
                    {' '}
                    {ApplicationString.OwnerDashboard.instantBooking.bookedBy}
                  </span>
                  <span className="w-[25%] px-4">
                    {currentBookingType === myBookingsStatusEnums.active &&
                      ApplicationString.OwnerDashboard.instantBooking.action}
                  </span>
                </header>
                {currentBookings.length > 0 ? (
                  currentBookings.map((booking) => (
                    <OwnerDashboardBookingsTable
                      key={booking.id}
                      booking={booking}
                    />
                  ))
                ) : dateRange.startDate && dateRange.startDate ? (
                  <div className="w-full bg-white px-4 py-6 rounded-md midXL:last:rounded-br-3xl midXL:last:rounded-bl-3xl midXL:rounded-[0px] dark:bg-[#292929] relative midXL:shadow-none shadow-[0px_4px_16px_0px_#1212120F] text-center text-2xl">
                    {
                      ApplicationString.OwnerDashboard.bookings
                        .noBookingsAvailable
                    }
                  </div>
                ) : !currentBookings.length && !isLoading ? (
                  <div className="w-full bg-white px-4 py-6 rounded-md midXL:last:rounded-br-3xl midXL:last:rounded-bl-3xl midXL:rounded-[0px] dark:bg-[#292929] relative midXL:shadow-none shadow-[0px_4px_16px_0px_#1212120F] text-center text-2xl">
                    {ApplicationString.OwnerDashboard.bookings.noBookings}
                  </div>
                ) : (
                  <div className="w-full bg-white px-4 py-6 rounded-md midXL:last:rounded-br-3xl midXL:last:rounded-bl-3xl midXL:rounded-[0px] dark:bg-[#292929] relative midXL:shadow-none shadow-[0px_4px_16px_0px_#1212120F] text-center text-2xl" />
                )}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default OwnerDashboardBookings;
