/* eslint-disable react/jsx-props-no-spreading */
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { LeftArrowSvg, RightArrowSvg } from '../../Assets/Svgs';
import ApplicationString from '../../Constants/applicationString';
import { IMyBookings } from '../../Interfaces/common/MyBookings/MyBookings';
import { IPaginationValues } from '../../Interfaces/interfaces';
// import cssUtils from '../../Utils/cssUtils';
import {
  myBookingsfilterOptions,
  PaginationButtonEnums,
} from '../../Utils/enums';
import { DefaultPaginationValueMyBookings } from '../../Utils/utils';
import MybookingsCard from './MybookingsCard';

interface IMyBookingComponentProps {
  myBookings: IMyBookings[];
  handleFilter: (value: string) => void;
  totalBookings: number;
  handlePageChange: (page: number) => void;
  paginationValues: IPaginationValues;
  searchByType: string;
  loading: boolean;
  getReviewDetails: (id: number) => void;
  reviewSubmitHandler: (rating: number, isOwnRating: boolean) => void;
  ratingModal: boolean;
  setRatingModal: React.Dispatch<React.SetStateAction<boolean>>;
  reviewStar: number;
  setCurrentBookingId: React.Dispatch<React.SetStateAction<number>>;
}

const CustomSelect = styled((props: SelectProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Select
    {...props}
    IconComponent={(iconProps) => (
      <FilterListIcon
        {...iconProps}
        sx={{ fontSize: '18px' }} // Adjust the icon size here
      />
    )}
  />
))({});
const MybookingComponent: React.FC<IMyBookingComponentProps> = ({
  myBookings,
  handleFilter,
  totalBookings,
  handlePageChange,
  paginationValues,
  searchByType,
  loading,
  getReviewDetails,
  reviewSubmitHandler,
  ratingModal,
  setRatingModal,
  reviewStar,
  setCurrentBookingId,
}) => {
  return (
    <div className="bg-bg_secondary dark:bg-dark_bg_primary">
      <div className="mx-auto max-w-7xl px-6 py-12 lg:px-8 2xl:px-0 lg:py-12">
        <div className="flex flex-col gap-4 sm:gap-0 sm:flex-row justify-between mb-5">
          <span className="text-2xl 2xl:text-3xl font-bold text-primary_text dark:text-dark_primary_text">
            {ApplicationString.MyBookingsListing.title}
          </span>
          <div className="flex bg-white w-54 justify-between space-x-1 dark:bg-dark_bg_primary items-center px-3 py-1.5 rounded-full border border-borderStroke">
            <Typography fontSize={14} className="text-secondary_text min-w-max">
              {ApplicationString.MyBookingsListing.otherText.filterBy}
            </Typography>
            <CustomSelect
              defaultValue={searchByType}
              value={searchByType}
              variant="outlined"
              sx={{
                border: 'none',
                padding: 0,
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiSelect-select': {
                  padding: 0,
                  marginRight: '4px',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    width: '250px',
                    marginTop: '13px',
                    translate: '-18px',
                    paddingX: '8px',
                  },
                },
                disableScrollLock: true,
                marginThreshold: null,
              }}
              title={
                myBookingsfilterOptions.filter(
                  (item) => item.value === searchByType
                )[0]?.label || ''
              }
              className="font-semibold bg-white dark:bg-dark_bg_primary text-sm cursor-pointer overflow-hidden"
              onChange={(e) => {
                handleFilter(e.target.value as string);
              }}
              data-testid="my-bookings-filter-select"
            >
              {myBookingsfilterOptions.map((option) => (
                <MenuItem
                  title={option.label}
                  value={option.value}
                  key={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </CustomSelect>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {loading
            ? [...Array(12)].map(() => (
                <div
                  className="flex flex-col gap-2 bg-card_bg dark:bg-dark_bg_secondary p-2.5 rounded-xl"
                  key={`skeleton-${Math.random()}`}
                >
                  <Skeleton
                    variant="rounded"
                    className="w-full h-45 rounded-xl"
                  />
                  <div className="flex justify-between">
                    <Skeleton
                      variant="rounded"
                      className=" w-1/3 h-10 rounded-xl"
                    />
                    <Skeleton
                      variant="rounded"
                      className="w-1/4 h-10 rounded-xl"
                    />
                  </div>
                  <Skeleton
                    variant="rounded"
                    className=" w-4/6 h-7 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-1/2 h-7 rounded-xl"
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-full h-10 rounded-full"
                  />
                </div>
              ))
            : myBookings?.map((booking) => (
                <MybookingsCard
                  key={booking.id}
                  booking={booking}
                  getReviewDetails={getReviewDetails}
                  reviewSubmitHandler={reviewSubmitHandler}
                  ratingModal={ratingModal}
                  setRatingModal={setRatingModal}
                  reviewStar={reviewStar}
                  setCurrentBookingId={setCurrentBookingId}
                />
              ))}
        </div>
        {totalBookings === 0 && !loading && (
          <div className="min-h-[50vh] w-full flex justify-center items-center text-6xl text-secondary_text dark:text-dark_secondary_text">
            {ApplicationString.MyBookingsListing.noData}
          </div>
        )}
        <div className="pagination mt-0 md:mt-5 flex justify-end">
          {!loading && totalBookings > 0 && (
            <Pagination
              count={
                totalBookings === 0
                  ? 1
                  : Math.ceil(
                      totalBookings / DefaultPaginationValueMyBookings.PAGE_SIZE
                    )
              }
              page={paginationValues.PAGE}
              onChange={(event, page) => handlePageChange(page)}
              renderItem={(item) => {
                if (item.type === PaginationButtonEnums.previous) {
                  return (
                    <Button
                      className={`bg-white text-slate-500 hover:bg-white gap-1 font-semibold ${
                        paginationValues.PAGE === 1 && 'cursor-not-allowed'
                      } rounded-md py-1.5 px-2 capitalize translate-y-8 md:translate-y-0 lg:translate-y-0 `}
                      data-testid="my-bookings-pagination-previous-button"
                      onClick={() =>
                        item.page !== null &&
                        paginationValues.PAGE !== 1 &&
                        handlePageChange(item.page)
                      }
                    >
                      <LeftArrowSvg />
                      {
                        ApplicationString.searchScreen.buttonLabels.pagination
                          .previous
                      }
                    </Button>
                  );
                }
                if (item.type === PaginationButtonEnums.next) {
                  return (
                    <Button
                      className={`bg-white text-slate-500 hover:bg-white gap-1 font-semibold ${
                        paginationValues.PAGE ===
                          Math.ceil(
                            totalBookings /
                              DefaultPaginationValueMyBookings.PAGE_SIZE
                          ) && 'cursor-not-allowed'
                      }  rounded-md py-1.5 px-2 capitalize translate-y-8 md:translate-y-0 lg:translate-y-0 `}
                      data-testid="my-bookings-pagination-next-button"
                      onClick={() =>
                        item.page !== null &&
                        paginationValues.PAGE !==
                          Math.ceil(
                            totalBookings /
                              DefaultPaginationValueMyBookings.PAGE_SIZE
                          ) &&
                        handlePageChange(item.page)
                      }
                    >
                      {
                        ApplicationString.searchScreen.buttonLabels.pagination
                          .next
                      }
                      <RightArrowSvg />
                    </Button>
                  );
                }
                return (
                  <PaginationItem
                    color="primary"
                    className={`mx-2 text-slate-500 font-semibold md:translate-y-0 lg:translate-y-0 translate-y-8 ${
                      item.selected && 'text-white py-1 rounded-md'
                    }`}
                    page={item.page}
                    data-testid="my-bookings-pagination-item"
                    onClick={() =>
                      item.page !== null && handlePageChange(item.page)
                    }
                    selected={item.selected}
                    type={item.type}
                  />
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MybookingComponent;
