// src/components/StackCards.tsx
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ApplicationString from '../../Constants/applicationString';
import cssUtils from '../../Utils/cssUtils';
import Card from './AboutUsCard';

const StackCards: React.FC = () => {
  const stackCardsRef = useRef<HTMLUListElement>(null);
  const Navigate = useNavigate();

  useEffect(() => {
    const element = stackCardsRef.current;

    if (!element) return;

    const items = element.getElementsByClassName(
      'stack-cards__item'
    ) as HTMLCollectionOf<HTMLElement>;

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.addEventListener('scroll', handleScroll);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.removeEventListener('scroll', handleScroll);
      }
    });

    observer.observe(element);

    const handleScroll = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      window.requestAnimationFrame(() => animateStackCards(element, items));
    };

    const animateStackCards = (
      // eslint-disable-next-line @typescript-eslint/no-shadow
      element: HTMLElement,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      items: HTMLCollectionOf<HTMLElement>
    ) => {
      const { top } = element.getBoundingClientRect();

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < items.length; i++) {
        const cardHeight = items[i].offsetHeight;
        const marginY = 80; // Adjust margin as needed
        const scrolling = cardHeight - top - i * (cardHeight + marginY);
        if (scrolling > 0) {
          // eslint-disable-next-line no-param-reassign
          items[i].style.transform =
            `translateY(${marginY * i}px) scale(${(cardHeight - scrolling * 0.05) / cardHeight})`;
        } else {
          // eslint-disable-next-line no-param-reassign
          items[i].style.transform = `translateY(${marginY * i}px) scale(1)`;
        }
      }
    };

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <ul
        ref={stackCardsRef}
        className={`min-h-max ${cssUtils.layout.sectionLayout} `}
      >
        {ApplicationString.aboutUs.cardsDetails.map((item, i) => (
          <Card
            key={item.title}
            imgSrc={item.imgSrc}
            mobileImgSrc={item.mobileImgSrc}
            title={item.title}
            description={item.description}
            description2={item.description2}
            cardExtraClasses={`z-[${i + 1}]`}
          />
        ))}
      </ul>
      <div className={`min-h-max ${cssUtils.layout.sectionLayout} `}>
        <span className="mt-45 py-10 px-6 sm:h-[400px] h-[550px] xsm:h-115 max-h-min w-full bg-title_text stack-cards__item dark:bg-dark_web_accent rounded-4xl flex justify-center items-center lg:h-94 z-[10] sticky top-28">
          <div className="flex flex-col gap-15">
            <div className="flex flex-col gap-4">
              <span className="text-[20px] sm:text-[22px] lg:text-[26px] xl:text-[28px] 2xl:text-[30px] font-bold text-white dark:text-white text-center">
                {ApplicationString.aboutUs.footerCard.title}
              </span>
              <span className="text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] font-normal text-center  text-[#FAFAFA]">
                {ApplicationString.aboutUs.footerCard.description}
              </span>
            </div>

            <div>
              <div className="flex flex-col md:flex-row gap-5 justify-center ">
                <Button
                  data-testid="rentNow-aboutUs-button"
                  variant="contained"
                  color="primary"
                  className="flex justify-center hover:bg-slate-400 rounded-4xl normal-case text-base font-bold sm:py-3 p-6 bg-white text-primary dark:text-primary dark:bg-black"
                  onClick={() => Navigate('/search')}
                >
                  {ApplicationString.aboutUs.footerCard.rentNowBtn}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="flex justify-center rounded-4xl hover:bg-primary/80 normal-case text-base font-bold sm:py-3 p-6  text-white bg-primary dark:text-primary_text"
                  onClick={() => Navigate('/owner/myListings')}
                  data-testid="listNow-aboutUs-button"
                >
                  {ApplicationString.aboutUs.footerCard.listNowBtn}
                </Button>
              </div>
            </div>
          </div>
        </span>
      </div>
    </>
  );
};

export default StackCards;
