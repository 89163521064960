import React from 'react';
import { TextField } from '@mui/material';
import infoIcon from '../../Assets/infoIcon.png';
import ApplicationString from '../../Constants/applicationString';
import { checkMaxWordLength } from '../../Utils/utils';
import { maxWorkLen } from '../../Constants/commonConst';

interface InputAreaProps {
  specialRequest: string;
  specialRequestError: string | boolean;
  setSpecialRequest: (value: string) => void;
  setSpecialRequestError: (value: string | boolean) => void;
}

const InputArea: React.FC<InputAreaProps> = ({
  specialRequest,
  specialRequestError,
  setSpecialRequest,
  setSpecialRequestError,
}) => {
  const handleInputChange = (value: string) => {
    const specialRequestMessage = checkMaxWordLength(
      value,
      maxWorkLen.specialRequest,
      ApplicationString.specialRequestErrorMessage
    );
    if (specialRequestMessage) {
      setSpecialRequestError(specialRequestMessage);
    } else {
      setSpecialRequestError(specialRequestMessage);
    }
    setSpecialRequest(value);
  };

  return (
    <div className="mt-4">
      <div className="font-semibold dark:text-white">
        {ApplicationString.bookingDetailsData.specialRequest}
      </div>

      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#D1D5DB',
            },
          },
          '& .MuiOutlinedInput-input': {
            height: '78% !important',
          },
        }}
        data-testid="input-vehicle-description"
        label={ApplicationString.bookingDetailsData.addListingDescription}
        className="w-full h-39 mt-2 rounded-3xl bg-[#ffffff] border dark:bg-dark_bg_primary dark:border-none"
        InputProps={{
          className: 'rounded-3xl h-39 pl-2 pt-0',
          classes: {
            root: 'left-0',
          },
        }}
        multiline
        rows={4}
        value={specialRequest}
        onChange={(e) => handleInputChange(e.target.value)}
        InputLabelProps={{
          className: 'text-secondary_text ',
        }}
      />

      <div className="flex gap-2 mt-2 items-center">
        <img
          src={infoIcon}
          className="w-4 h-4"
          alt="info text"
          data-testid="infoImage-bookingDetails-image"
        />
        {specialRequestError && (
          <div className="text-red-500 text-sm">{specialRequestError}</div>
        )}
        {!specialRequestError && (
          <div className="text-slate-500 text-sm">
            {ApplicationString.bookingDetailsData.wordsMax}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputArea;
