import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InternalRoute from '../../../../Utils/internalRoutes';
import { setLocalStorageInfo } from '../../../../Utils/utils';
import { localStorageEnums } from '../../../../Utils/enums';
// import { setLocalStorageInfo } from '../../../../Utils/utils';
// import { localStorageEnums } from '../../../../Utils/enums';
// import { Link } from 'react-router-dom';

const TextHeading = ({
  label,
  buttonText,
  navigatePathStep,
  isEdit,
}: {
  label: string;
  buttonText: string;
  navigatePathStep: number;
  isEdit: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();

  const handleEditClick = () => {
    if (isEdit) {
      const editRoute =
        vehicleId && InternalRoute.vehicleEdit.replace(':vehicleId', vehicleId);
      navigate(`${editRoute}?step=${navigatePathStep}`);
    } else {
      navigate(`${InternalRoute.vehicleCreate}?step=${navigatePathStep}`);
    }
    setLocalStorageInfo<number>(navigatePathStep - 1, localStorageEnums.step);
  };
  return (
    <div className="flex justify-between items-center">
      <div className="text-sm md:text-lg font-bold text-black dark:text-white">
        {label}
      </div>
      <div
        role="button"
        tabIndex={0}
        data-testid={`preview-edit-button-${navigatePathStep}`}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleEditClick();
          }
        }}
        className="text-sm md:text-lg font-bold text-blue-600 cursor-pointer"
        onClick={handleEditClick}
      >
        {buttonText}
      </div>
    </div>
  );
};

export default TextHeading;
