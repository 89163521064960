const InternalRoute = {
  Home: '/',
  Signup: '/signup',
  ListVehicles: '/MyVehicles',
  Forbidden: '/forbidden',
  NotFound: '/notFound',
  Owner: '/owner',
  User: '/user',
  // Login routes
  EmailLogin: '/login',
  PhoneLogin: '/login/phone',
  // Signup routes
  EmailSignup: '/signup',
  PhoneSignup: '/signup/phone',
  EmailVerificationCode: '/signup/emailOtpVerification',
  PhoneVerificationCode: '/signup/phoneOtpVerification',
  SignupEmailVerified: '/signup/emailVerified',
  SignupPhoneVerified: '/signup/phoneVerified',
  SignUpNameAndPassword: '/signup/createAccount',
  AccountCreated: '/signup/accountCreated',
  // Forgot password routes
  EmailForgotPassword: '/forgot-password/email',
  PhoneForgotPassword: '/forgot-password/phone',
  ForgotPasswordEmailVerificationCode: '/forgot-password/emailOtpVerification',
  ForgotPasswordPhoneVerificationCode: '/forgot-password/phoneOtpVerification',
  ForgotPasswordCreateNewPassword: '/forgot-password/createNewPassword',
  ForgotPasswordCreateNewPasswordWithId:
    '/forgot-password/createNewPassword?verificationId=#verificationId',
  ForgotPasswordSuccess: '/forgot-password/success',
  // My bookings routes
  MyBookings: '/myBookings',
  MyBookingsDetails: '/myBookings/details/:id',
  // Owner Booking Details
  OwnerBookingDetails: '/owner/bookingDetails/:id',
  // Owner My Calendar
  OwnerMyCalendar: '/owner/calendar',
  // other routes
  Logout: '/logout',
  AboutUs: '/aboutUs',
  Watercrafts: '/watercrafts',
  LandVehicle: '/LandVehicle',
  Contact: '/contact',
  OwnerContact: '/owner/contact',
  Login: '/login',
  FAQ: '/faq',
  ownerFAQ: '/owner/faq',
  explore: '/explore',
  mui_setup: '/mui_setup',
  search: '/search',
  vehicle_booking: '/booking',
  searchParamUrl: '/search?#queryParams',

  myAccountOwner: '/owner/myAccount',
  myBookings: '/myBookings',
  Vehicle_details: '/details/:VehicleId',
  Owner_Vehicle_details: '/owner/vehicle-details/:vehicleId',
  Vehicle_photo_gallery: '/details/:VehicleId/photoGallery',
  owner_vehicle_photo_gallery: '/owner/vehicle-details/:vehicleId/photoGallery',
  bookingDetails: '/bookingDetails/:id',
  example: '/explore/watercraft',
  // my account routes
  myAccount: '/myAccount/accountInfo',
  settings: '/myAccount/settings',
  payment: '/myAccount/payment',
  changePassword: '/myAccount/changePassword',
  // ***************** */
  dashboard: '/owner/dashboard',
  statusCompleted: '/owner/dashboard/bookings/completed',
  statusConfirmed: '/owner/dashboard/bookings/confirmed',
  statusCancelled: '/owner/dashboard/bookings/cancelled',
  statusRejected: '/owner/dashboard/bookings/rejected',
  owner_vehicle_details: '/owner/vehicle-details/:vehicleId',
  ownerDashboard: '/owner/dashboard',
  my_listings: '/owner/myListings',
  vehicleCreate: '/owner/vehicleCreate',
  vehicleEdit: '/owner/vehicleEdit/:vehicleId',
  splashScreen: '/owner/splashScreen',
  privacyPolicy: '/privacy',
  termsAndCondition: '/terms_and_conditions',
};

export const ExternalRoute = {
  google: 'https://www.google.com',
};

export default InternalRoute;
