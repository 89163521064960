import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionHeading from '../../common/SectionHeading';
import pinIcon from '../../../Assets/Svgs/pinIcon.png';
import ApplicationString from '../../../Constants/applicationString';
import cssUtils from '../../../Utils/cssUtils';
import NextPreviousComponent from '../../common/NextPreviousComopnent';
import usePageSize from '../../../Hooks/usePageSize';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';
import InternalRoute from '../../../Utils/internalRoutes';
import { minPopularListingCount } from '../../../Constants/commonConst';

interface IPopularListingProps {
  popularListings: IVehiclesData[];
}

const PopularListing: React.FC<IPopularListingProps> = ({
  popularListings,
}) => {
  const { heading, unitData } = ApplicationString.ownerListing;
  const pageSize = usePageSize();
  const navigate = useNavigate();

  const [popularListingData, setPopularListingData] = useState(popularListings);
  const hideNextAndPrevious =
    popularListingData.length > minPopularListingCount;

  const handleNext = () => {
    setPopularListingData((prevData) => {
      const newData = [...prevData];
      const firstItem = newData.shift();
      if (firstItem) {
        newData.push(firstItem);
      }
      return newData;
    });
  };

  const handlePrevious = () => {
    setPopularListingData((prevData) => {
      const newData = [...prevData];
      const lastItem = newData.pop();
      if (lastItem) {
        newData.unshift(lastItem);
      }
      return newData;
    });
  };

  const getCurrentCards = () => {
    const cardsPerPage = pageSize;
    return popularListingData.slice(0, cardsPerPage);
  };

  return (
    <div className="w-full dark:bg-dark_bg_secondary">
      <div className={cssUtils.layout.sectionLayout}>
        <div className="flex items-center flex-col sm:flex-row justify-between">
          <SectionHeading
            title={heading.title}
            description={' '}
            descTextColor={cssUtils.colors.slate}
          />
          {hideNextAndPrevious && (
            <NextPreviousComponent
              // currentPage={currentPage}
              // totalPages={totalPages}

              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4 justify-center transition-transform duration-3000 mt-5">
          {getCurrentCards().map((listing) => (
            <div
              onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === 'Enter') {
                  navigate(
                    InternalRoute.Vehicle_details.replace(
                      ':VehicleId',
                      listing.id?.toString()
                    )
                  );
                }
              }}
              data-testid="popular-listing-card"
              tabIndex={0}
              role="button"
              key={listing.id}
              onClick={() =>
                navigate(
                  InternalRoute.Vehicle_details.replace(
                    ':VehicleId',
                    listing.id?.toString()
                  )
                )
              }
              className="border cursor-pointer border-slate-200 dark:bg-dark_bg_secondary dark:border-dark_borderStroke dark:shadow-slate-700 dark:shadow-2xl shadow-lg rounded-2xl transition-transform duration-300 transform hover:scale-105 flex flex-col"
            >
              <div className="flex sm:flex-row flex-col gap-4 p-4 pb-0 sm:p-2">
                <img
                  className="h-60 sm:h-30 w-full min-w-30 sm:w-30 lg:w-60 lg:max-w-60 sm:max-w-30 xl:min-30 xl:max-w-30 rounded-2xl object-cover"
                  src={
                    listing.images?.find((item) => item.isCover === true)?.path
                  }
                  alt={listing.title}
                />
                <div className="flex flex-col gap-2 sm:gap-4 py-2 pr-4 sm:py-4">
                  <p className="font-semibold text-xl leading-tight dark:text-dart_web_accent text-blue-950 line-clamp-2">
                    {listing.title}
                  </p>
                  <div className="flex gap-1 items-start">
                    <div className="flex w-auto mt-[2px] min-w-[20px]">
                      <img className="h-4 w-5" src={pinIcon} alt="pin-icon" />
                    </div>
                    <p className="font-sans font-normal dark:text-dark_text_sub_primary text-slate-600 text-base leading-tight line-clamp-1">
                      {listing.city}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4 p-4 flex-grow">
                <div>
                  <p className="text-slate-600 dark:text-dark_text_sub_primary">
                    {listing.capacity && (
                      <>
                        <strong className="text-black-2 dark:text-white">
                          {listing.capacity}{' '}
                        </strong>
                        {unitData.personCapacity} <span>|</span>
                      </>
                    )}
                    {listing.size && (
                      <>
                        {' '}
                        <strong className="text-black-2 dark:text-white">
                          {listing.size}
                        </strong>{' '}
                        {unitData.length}
                      </>
                    )}
                  </p>
                </div>
                <div>
                  <p className="font-sans dark:text-dark_text_sub_primary text-slate-600 font-normal text-base leading-tight">
                    {listing.description}
                  </p>
                </div>
                <div className="mt-auto">
                  <button
                    type="button"
                    className="bg-blue-100 dark:bg-dark_blue_primary dark:text-dark_text_sub_primary rounded-xl p-4 py-3 text-lg"
                    data-testid={`listing-price-button-${listing.id}`}
                  >
                    <div className="flex">
                      <strong className="text-blue-600">
                        {unitData.currency}{' '}
                        {listing.bookingRateHourly ||
                          listing.bookingRate12Hourly ||
                          listing.bookingRate24Hourly}
                      </strong>
                      <div className="dark:text-dark_text_sub_primary ml-1 text-slate-600">
                        / {unitData.hour}
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopularListing;
