import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ApplicationString from '../../../Constants/applicationString';
import InternalRoute from '../../../Utils/internalRoutes';
import { localStorageEnums } from '../../../Utils/enums';
import { removeLocalStorageItems } from '../../../Utils/utils';

type LinkType = {
  key: string;
  label: string;
  route?: string;
  children?: LinkType[];
};

type NavbarProps = {
  links: LinkType[];
};

const Navbar: React.FC<NavbarProps> = ({ links }) => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpenKeys([]);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const getParentKey = (key: string): string | null => {
    const link = links.find((urllinks) => {
      if (urllinks.children) {
        const childKey = urllinks.children.find((child) => {
          if (child.key === key) return true;
          if (child.children) {
            const subChildKey = child.children.find(
              (subChild) => subChild.key === key
            );
            if (subChildKey) return true;
          }
          return false;
        });
        if (childKey) return true;
      }
      return false;
    });

    return link ? link.key : null;
  };

  const getSiblingKeys = (parentKey: string | null): string[] => {
    const parentLink = links.find(
      (link) => link.key === parentKey && link.children
    );

    if (parentLink && parentLink.children) {
      return parentLink.children.map((child) => child.key);
    }

    const childLink = links
      .flatMap((link) => link.children ?? [])
      .find((child) => child.key === parentKey && child.children);

    if (childLink && childLink.children) {
      return childLink.children.map((subChild) => subChild.key);
    }

    return [];
  };

  const getCategoryParams = (() => {
    const state = {
      category: '',
      subcategory: '',
    };

    return (link: LinkType): string => {
      const { key } = link;

      if (key === 'explore') {
        state.category = 'explore';
        state.subcategory = '';
        return `${InternalRoute.explore}?category=${ApplicationString.searchScreen.categories.watercraft}`;
      }
      if (
        key === ApplicationString.searchScreen.categories.watercraft ||
        key === ApplicationString.searchScreen.categories.landVehicles
      ) {
        state.category = key;
        state.subcategory = '';
        return `${InternalRoute.explore}?category=${key}`;
      }

      if (state.category) {
        state.subcategory = key;
        return `${InternalRoute.explore}?category=${state.category}&subcategory=${key}`;
      }
      return '';
    };
  })();

  const handleRemoveLocalStorageData = (key: string) => {
    if (key) {
      removeLocalStorageItems([
        localStorageEnums.skippedSteps,
        localStorageEnums.step,
        localStorageEnums.stepsData,
      ]);
    }
  };

  const handleToggle = (key: string) => {
    setOpenKeys((prevKeys) => {
      if (prevKeys.includes(key)) {
        // If key is already open, close it
        return prevKeys.filter((k) => k !== key);
      }
      // Otherwise, close all sibling keys and open this one
      const parentKey = getParentKey(key);
      const siblingKeys = getSiblingKeys(parentKey);
      return [...prevKeys.filter((k) => !siblingKeys.includes(k)), key];
    });
  };

  const handleMouseEnter = (key: string) => {
    setOpenKeys((prevKeys) => {
      if (!prevKeys.includes(key)) {
        return [...prevKeys, key];
      }
      return prevKeys;
    });
  };

  const handleMouseLeave = (key: string) => {
    setOpenKeys((prevKeys) => prevKeys.filter((k) => k !== key));
  };

  const isActive = (route: string): boolean => {
    return location.pathname === route;
  };

  const renderLinks = (uselinks: LinkType[], level = 0) => {
    return uselinks.map((link) => {
      const isOpen = openKeys.includes(link.key);
      const isChild = level > 0;
      return (
        <div
          key={link.key}
          className={`relative ${isChild ? 'ml-0 w-full flex justify-between' : ''}`}
          onMouseEnter={() => handleMouseEnter(link.key)}
          onMouseLeave={() => handleMouseLeave(link.key)}
          data-testid={`header-common-${link.key}`}
        >
          {link.route ? (
            <Link
              to={link.route}
              onClick={() => handleRemoveLocalStorageData(link.key)}
              className={`
                ${isActive(link.route) ? 'font-bold' : ''}${
                  isChild
                    ? 'flex justify-between dark:text-dart_web_accent dark:hover:text-dart_web_accent hover:bg-slate-100 w-full rounded-sm'
                    : ''
                } px-4 py-2 mt-2 text-sm bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-dark_bg_surface  dark:focus:text-white dark:hover:text-white dark:text-dark_text_sub_primary md:mt-0 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200  focus:outline-none focus:shadow-outline `}
              data-testid={`header-link-${link.key}`}
            >
              {link.label}
            </Link>
          ) : (
            <Link
              to={getCategoryParams(link)}
              type="button"
              state={{ key: link.key }}
              onClick={() => handleToggle(link.key)}
              data-testid={`header-link-${link.key}`}
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              className={`
             ${isActive(link.route || '') ? 'font-bold' : ''}
              ${
                isChild
                  ? 'flex justify-between dark:text-dart_web_accent dark:hover:bg-dark_bg_surface dark:hover:text-dart_web_accent hover:bg-slate-100  w-full rounded-sm'
                  : ``
              } flex flex-row items-center w-full px-4 py-2 mt-2 text-sm text-left bg-transparent rounded-lg dark:bg-transparent dark:hover:bg-dark_bg_surface dark:focus:text-white dark:hover:text-white dark:focus:bg-gray-600 dark:text-dark_text_sub_primary md:w-auto md:inline md:mt-0 hover:text-gray-900 focus:text-gray-900  focus:bg-gray-200 focus:outline-none focus:shadow-outline`}
            >
              <span>{link.label}</span>
              {link.children &&
                (isChild ? (
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    style={{ rotate: isOpen ? '180deg' : '270deg' }}
                    className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-0 ${
                      isOpen ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                ))}
            </Link>
          )}
          {link.children && isOpen && (
            <div
              className={`absolute z-10 w-full origin-top-right bg-slate-0 rounded-md  md:w-48
             
              ${isChild ? 'left-full top-0 pl-2' : ''}`}
            >
              <div
                className={` py-2 bg-white border border-slate-200 dark:border-dark_borderStroke rounded-md shadow dark:bg-dark_bg_secondary`}
              >
                {renderLinks(link?.children, level + 1)}
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div
      ref={menuRef}
      className="w-full text-gray-700 bg-white dark:text-gray-200 dark:bg-dark_bg_secondary"
      data-testid="header-common-ref"
    >
      <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-6 2xl:px-8">
        <nav
          className={`flex-col text-slate-600 items-center flex-grow pb-4 md:pb-0 ${
            openKeys.length ? 'flex' : 'hidden'
          } md:flex md:justify-end md:flex-row`}
        >
          {renderLinks(links)}
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
