/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import OwnerCalendarComponent from './ownerCalendar';
import StatusBar from '../OwnerComponent/MyCalendarStatusBar';
import cssUtils from '../../Utils/cssUtils';
import { BookingsDetails } from '../BookingDetails/bookingInterface';
import BookingPersonList from './bookingPersonList';
import OwnerVehicleListComponent from './ownerVehicleList';

const OwnerMyCalendarComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState<BookingsDetails[]>([]);
  const [monthLimit, setMonthLimit] = useState<number>(12);

  return (
    <div className="w-full flex flex-col gap-6 xl:gap-8 bg-blue-50 dark:bg-dark_bg_surface  dark:bg-dark_bg_gray_primary pb-12">
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={55000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          top: '13% !important',
          position: 'fixed',
          left: 0,
          right: '10% !important',
          margin: '0 auto',
          zIndex: 9999,
        }}
      >
        <Alert onClose={handleCloseSnackbar} severity="warning">
          {ApplicationString.OwnerCalendar.warningMessage}
        </Alert>
      </Snackbar> */}
      <OwnerVehicleListComponent setMonthLimit={setMonthLimit} />
      <div className={`!py-0 w-full ${cssUtils.layout.sectionLayout}`}>
        <StatusBar />
      </div>
      <div className={`!py-0 w-full ${cssUtils.layout.sectionLayout}`}>
        <div className="w-full gap-8 flex flex-col lg:flex-row xl:flex-row justify-between">
          <div className="w-full lg:w-4/12 xl:w-4/12  dark:bg-dark_bg_surface  dark:bg-dark_bg_gray_primary">
            <BookingPersonList
              isLoading={isLoading}
              bookingData={bookingData}
            />
          </div>
          <div className="w-full xl:w-8/12">
            <OwnerCalendarComponent
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setBookingData={setBookingData}
              monthLimit={monthLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerMyCalendarComponent;
