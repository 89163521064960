import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Snackbar,
  TextField,
  styled,
} from '@mui/material';
import React, { ChangeEvent, useEffect } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import Alert from '@mui/material/Alert';
import {
  ContactSvgIcon,
  EmailSvgIcon,
  LocationSvgIcon,
  UploadSvgIcon,
} from '../../Assets/Svgs';
// import image from '../../Assets/Images/cover/image.png';
import { ApiRequest } from '../../Api/api';
import { uploadMaxFileSize, contactUsAttachment } from '../../Utils/enums';
import ApplicationString from '../../Constants/applicationString';
import contactUs1Img from '../../Assets/Images/contactus1Img.png';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import commmonRegexs from '../../Utils/commonRegex';
import uploadImage from '../../Assets/Images/image.png';
import TrashIcon from '../../Assets/Images/delete.png';
import LoadingButton from '../common/LoadingButton/LoadingButton';
import URLS from '../../Constants/urls';
import './contactUs.css';
import { getBrowserName } from '../../Utils/utils';
import { useToast } from '../../Services/ToastService';

interface IContactUsFormData {
  fullName: string;
  email: string;
  message: string;
  attachment: File | null;
}
interface IErrosArray {
  propertyName: string;
  message: string;
}

interface IErrorsData {
  fullName: {
    error: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    ValidationMessage: string;
  };
  message: {
    error: boolean;
    ValidationMessage: string;
  };
  attachment: {
    error: boolean;
    ValidationMessage: string;
  };
}
const ContactUs: React.FC = () => {
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [browser, setBrowser] = React.useState<string>('');

  const handleClick = () => {
    setOpen(true);
  };

  const toast = useToast();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [formData, setFormData] = React.useState<IContactUsFormData>({
    fullName: '',
    email: '',
    message: '',
    attachment: null,
  });
  const [errorsData, setErrorsData] = React.useState<IErrorsData>({
    fullName: {
      error: false,
      ValidationMessage: '',
    },
    email: {
      error: false,
      ValidationMessage: '',
    },
    message: {
      error: false,
      ValidationMessage: '',
    },
    attachment: {
      error: false,
      ValidationMessage: '',
    },
  });

  useEffect(() => {
    const detectedBrowser = getBrowserName();
    setBrowser(detectedBrowser);
  }, []);

  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (err?.response?.data?.errors && err.response.data.errors.length > 0) {
      const errorDataArray = err.response.data.errors as IErrosArray[];
      errorDataArray.forEach((error) => {
        if (error.propertyName === 'fullName') {
          setErrorsData((prevState) => ({
            ...prevState,
            fullName: {
              error: true,
              ValidationMessage: error.message,
            },
          }));
        }
        if (error.propertyName === 'email') {
          setErrorsData((prevState) => ({
            ...prevState,
            email: {
              error: true,
              ValidationMessage: error.message,
            },
          }));
        }
      });
    } else {
      if (
        err?.response?.status === HttpStatusCode.NotFound &&
        formData.email.trim()
      ) {
        setErrorsData((prevState) => ({
          ...prevState,
          email: {
            error: true,
            ValidationMessage: err?.response ? err?.response.data.message : '',
          },
        }));
      }
      if (
        err?.response?.status === HttpStatusCode.BadRequest &&
        formData.fullName.trim()
      ) {
        setErrorsData((prevState) => ({
          ...prevState,
          fullName: {
            error: true,
            ValidationMessage: err?.response ? err?.response.data.message : '',
          },
        }));
      }
    }
  };
  const fullNameChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const fullName = e.target.value;
    const nameRegex = commmonRegexs.name;
    let fullNameError = false;
    let validationMessage = '';

    if (!nameRegex.test(fullName) && formData.fullName !== '') {
      fullNameError = true;
      validationMessage =
        ApplicationString.contactUs.validationMessages.formatMsgs.fullName;
    }
    if (fullName.trim() === '') {
      fullNameError = false;
      validationMessage = '';
    }
    setFormData((prevState) => ({
      ...prevState,
      fullName: e.target.value,
    }));
    setErrorsData((prevState) => ({
      ...prevState,
      fullName: {
        error: fullNameError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const email = e.target.value;
    const emailRegex = commmonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && formData.email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .invalidEmail;
    }
    if (email.trim() === '') {
      emailError = false;
      validationMessage = '';
    }
    setFormData((prevState) => ({
      ...prevState,
      email: email.trim(),
    }));

    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };
  const messageChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      message: e.target.value,
    }));
    setErrorsData({
      ...errorsData,
      message: { error: false, ValidationMessage: '' },
    });
  };
  const attachmentUploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
    let attachmentError = false;
    let validationMessage = '';
    const file = e.target.files && e.target.files[0];
    if (file) {
      const isRightFormat = contactUsAttachment.includes(file.type);

      if (!isRightFormat) {
        attachmentError = true;
        validationMessage =
          ApplicationString.contactUs.validationMessages.fileMsgs.format;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > uploadMaxFileSize) {
        attachmentError = true;
        validationMessage =
          ApplicationString.contactUs.validationMessages.fileMsgs.size;
      }
      // const img = new Image();
      // img.src = URL.createObjectURL(file);
    }
    setErrorsData({
      ...errorsData,
      attachment: {
        error: attachmentError,
        ValidationMessage: validationMessage,
      },
    });
    if (!attachmentError) {
      setFormData((prevState) => ({
        ...prevState,
        attachment: e.target.files && e.target.files[0],
      }));
    }
  };

  const clearForm = () => {
    setFormData((prevState) => ({
      ...prevState,
      fullName: '',
      email: '',
      message: '',
      attachment: null,
    }));
    setErrorsData({
      ...errorsData,

      fullName: {
        error: false,
        ValidationMessage: '',
      },
      email: {
        error: false,
        ValidationMessage: '',
      },
      message: {
        error: false,
        ValidationMessage: '',
      },
      attachment: {
        error: false,
        ValidationMessage: '',
      },
    });
  };

  const handlePostContactUs = async () => {
    // const file = formData.attachment;
    const payloadFormdata = new FormData();
    if (
      !formData.fullName.trim() ||
      !formData.email.trim() ||
      errorsData.email.error ||
      errorsData.fullName.error ||
      errorsData.attachment.error
    ) {
      return;
    }
    if (formData.attachment) {
      payloadFormdata.append('file', formData.attachment);
    }
    payloadFormdata.append('name', formData.fullName.trim());
    payloadFormdata.append('email', formData.email.trim());
    payloadFormdata.append('message', formData.message.trim());
    try {
      setIsSubmitting(true);
      const res = await ApiRequest.post(URLS.CONTACT_US, payloadFormdata);
      if (res?.status === HttpStatusCode.Created) {
        handleClick();
        clearForm();
        toast.success(ApplicationString.contactUs.successMessage);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
      // console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorsData((prevState) => ({
      ...prevState,
      fullName: {
        error: !formData.fullName.trim(),
        ValidationMessage: !formData.fullName.trim()
          ? ApplicationString.contactUs.validationMessages.requiredMsgs.fullName
          : '',
      },
      email: {
        error: !formData.email.trim(),
        ValidationMessage: !formData.email.trim()
          ? ApplicationString.contactUs.validationMessages.requiredMsgs.email
          : '',
      },
    }));
    const email = formData.email.trim();
    const emailRegex = commmonRegexs.email;

    let emailError = false;
    let validationMessage = '';

    if (!emailRegex.test(email) && formData.email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.loginWithEmail.validationMessages.invalidMsgs
          .invalidEmail;
      setErrorsData((prevState) => ({
        ...prevState,
        email: {
          error: emailError,
          ValidationMessage: validationMessage.trim(),
        },
      }));
    }
    try {
      await handlePostContactUs();
    } catch (error) {
      console.error('Contactus failed:', error);
    }
  };
  const deleteImage = () => {
    setFormData((prevState) => ({
      ...prevState,
      attachment: null,
    }));
    setErrorsData((prevState) => ({
      ...prevState,
      attachment: {
        error: false,
        ValidationMessage: '',
      },
    }));
  };
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {ApplicationString.contactUs.successMessage}
        </Alert>
      </Snackbar>
      <div className="w-full dark:border-dark_borderStroke dark:bg-dark_bg_primary">
        <div className="flex flex-col md:flex-row gap-[35px] mx-auto max-w-7xl px-6 pt-1 lg:px-8 2xl:px-0 lg:pt-5 ">
          <div className="w-full max-w-full md:w-1/2 md:max-w-[692px] mt-4">
            <div className="bg-white p-[24px] rounded-[20px] h-full dark:bg-dark_bg_secondary ">
              <div className="mb-3 font-bold text-xl sm:text-2xl 2xl:text-3xl text-left w-full dark:text-dark_primary_text">
                {ApplicationString.contactUs.lable}
              </div>
              <div className="text-[#6B7280] mb-[32px] dark:text-dark_secondary_text">
                <p>
                  {ApplicationString.contactUs.details.p1}
                  {ApplicationString.contactUs.details.p2}
                  {ApplicationString.contactUs.details.p3}
                </p>
              </div>
              <hr className="border-[1px] border-[#D1D5DB] dark:border-[#515151] mb-[32px]" />
              <form onSubmit={handleSubmit}>
                <FormControl
                  sx={{ width: '100%' }}
                  size="small"
                  data-testid="loginPhoneForm"
                >
                  <div className="flex flex-col sm:flex-row w-full gap-[12px] mb-[12px]">
                    <FormControl className="w-full">
                      <TextField
                        label={ApplicationString.contactUs.fields.nameLable}
                        className=" w-full rounded-4xl text-base font-medium "
                        InputProps={{ className: 'rounded-4xl px-2 ' }}
                        InputLabelProps={{
                          classes: {
                            root: 'left-[10px]', // Adjust left alignment and leading space
                            focused: `${browser === 'Safari' ? 'safari-form-label-focused' : 'chrome-form-label-focused'}`,
                          },
                        }}
                        value={formData.fullName}
                        error={errorsData.fullName.error}
                        onChange={fullNameChangeHandler}
                      />
                      {errorsData.fullName.error && (
                        <FormHelperText className="text-error">
                          {errorsData.fullName.ValidationMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl className="w-full">
                      <TextField
                        label={ApplicationString.contactUs.fields.emailLable}
                        className=" w-full rounded-4xl text-base font-medium "
                        InputProps={{ className: 'rounded-4xl px-2 ' }}
                        InputLabelProps={{
                          classes: {
                            root: 'left-[10px]', // Adjust left alignment and leading space
                            focused: `${browser === 'Safari' ? 'safari-form-label-focused' : 'chrome-form-label-focused'}`,
                          },
                        }}
                        value={formData.email}
                        error={errorsData.email.error}
                        onChange={emailChangeHandler}
                      />
                      {errorsData.email.error && (
                        <FormHelperText className="text-error">
                          {errorsData.email.ValidationMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div className="w-full mb-[12px] ">
                    <FormControl className="w-full ">
                      <TextField
                        label={ApplicationString.contactUs.fields.messageLable}
                        className="w-full h-[147px] rounded-[25px] text-base font-medium"
                        InputProps={{
                          className: 'rounded-[25px] h-[150px] px-6 py-4',
                        }}
                        InputLabelProps={{
                          classes: {
                            root: 'left-[10px]', // Adjust left alignment and leading space
                            focused: `${browser === 'Safari' ? 'safari-form-label-focused' : 'chrome-form-label-focused'}`,
                          },
                        }}
                        multiline
                        rows={4}
                        value={formData.message}
                        error={errorsData.message.error}
                        onChange={messageChangeHandler}
                      />

                      {errorsData.email.error && (
                        <FormHelperText className="text-error">
                          {errorsData.message.ValidationMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <FormControl className="mb-8">
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      className={`w-full flex justify-start h-[56px] pl-6 rounded-[50px] hover:border-black text-[#374151] py-4 px-4 normal-case bg-[#F7FBFF] dark:border-[#ffffff3b] dark:bg-[#333333] dark:text-dark_secondary_text ${errorsData.attachment.error ? 'border-error' : 'border-[#D1D5DB]'}`}
                      tabIndex={-1}
                      startIcon={<UploadSvgIcon />}
                    >
                      {ApplicationString.contactUs.fields.uploadAttachment}
                      <VisuallyHiddenInput
                        type="file"
                        accept="image/jpeg,image/png,image/jpg, application/pdf"
                        onChange={attachmentUploadHandler}
                      />
                    </Button>
                    <div className="px-2 py-1 text-sm text-secondary_text">
                      <p className="text-Gray-500">
                        {ApplicationString.contactUs.supportedFileMsg}
                      </p>
                    </div>
                    {errorsData.attachment.error && (
                      <FormHelperText className="text-error">
                        {errorsData.attachment.ValidationMessage}
                      </FormHelperText>
                    )}
                    {formData.attachment?.name && (
                      <div className="flex items-center gap-2 m-2 justify-between">
                        <div className="flex items-center gap-2">
                          <Box
                            component="img"
                            alt=""
                            src={uploadImage}
                            className="w-[20px]"
                          />
                          <span className="text-sm text-[#374151]">
                            {formData.attachment?.name}
                          </span>
                        </div>

                        <div className="flex cursor-pointer">
                          <Box
                            component="img"
                            alt=""
                            src={TrashIcon}
                            className="w-[20px]"
                            onClick={deleteImage}
                          />
                        </div>
                      </div>
                    )}
                  </FormControl>
                  <div className="flex flex-col sm:flex-row gap-[10px] sm:gap-[24px]">
                    <Button
                      variant="outlined"
                      className="w-full sm:w-1/2 h-[47px] capitalize border-[1px] border-[#292929] dark:border-[#FAFAFA] rounded-[50px] text-[16px] font-bold text-[#292929] dark:text-[#FAFAFA] bg-white dark:bg-dark_bg_secondary"
                      onClick={clearForm}
                    >
                      {ApplicationString.contactUs.buttons.clear}
                    </Button>
                    {isSubmitting ? (
                      <LoadingButton
                        buttonText={
                          ApplicationString.contactUs.buttons.submitting
                        }
                      />
                    ) : (
                      <Button
                        variant="contained"
                        className="w-full h-[47px] sm:w-1/2 rounded-[50px] text-[16px] font-bold capitalize dark:text-black"
                        type="submit"
                      >
                        {ApplicationString.contactUs.buttons.submit}
                      </Button>
                    )}
                  </div>
                </FormControl>
              </form>
            </div>
          </div>
          <div className="w-full max-w-full md:w-1/2 md:max-w-[692px] mt-4">
            <div className="flex flex-col gap-[16px] h-full justify-between">
              <div className="w-full rounded-xl">
                <img
                  className="w-full h-90 rounded-[20px] object-cover"
                  src={contactUs1Img}
                  alt=""
                />
              </div>
              <div className="w-full bg-white rounded-[20px] p-[16px]  dark:bg-dark_bg_secondary">
                <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB] dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <EmailSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC] ">
                      {ApplicationString.contactUs.contactUsDetails.emailLable}
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.email}
                    </div>
                  </div>
                </div>
                <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB]  dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <ContactSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC]">
                      {ApplicationString.contactUs.contactUsDetails.phoneLable}
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.phone}
                    </div>
                  </div>
                </div>
                <div className="flex gap-[12px] mb-[12px] border-[1px] rounded-[50px] px-[16px] py-[12px] border-[#E5E7EB]   dark:border-[#ffffff3b]">
                  <div className="h-[40px] w-[40px]">
                    <LocationSvgIcon />
                  </div>
                  <div className="flex-grow">
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-bold mt-[3px] mb-[7px] sm:mt-0 sm:mb-[4px] leading-none dark:text-[#4968AC] ">
                      {
                        ApplicationString.contactUs.contactUsDetails
                          .addressLable
                      }
                    </div>
                    <div className="text-[14px] md:text-[15px] lg:text-[18px] font-normal text-[#6B7280] leading-none dark:text-dark_secondary_text">
                      {ApplicationString.contactUs.contactUsDetails.address}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
