import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, Divider, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import Switch, { SwitchProps } from '@mui/material/Switch';
import ApplicationString from '../../../Constants/applicationString';
import DeleteAccountPopupComponent from '../../common/DeleteAccountPopup';

/* eslint-disable react/jsx-props-no-spreading */

// const IOSSwitch = styled((props: SwitchProps) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: 42,
//   height: 26,
//   padding: 0,
//   '& .MuiSwitch-switchBase': {
//     padding: 0,
//     margin: 2,
//     transitionDuration: '300ms',
//     '&.Mui-checked': {
//       transform: 'translateX(16px)',
//       color: '#fff',
//       '& + .MuiSwitch-track': {
//         backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
//         opacity: 1,
//         border: 0,
//       },
//       '&.Mui-disabled + .MuiSwitch-track': {
//         opacity: 0.5,
//       },
//     },
//     '&.Mui-focusVisible .MuiSwitch-thumb': {
//       color: '#33cf4d',
//       border: '6px solid #fff',
//     },
//     '&.Mui-disabled .MuiSwitch-thumb': {
//       color:
//         theme.palette.mode === 'light'
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     '&.Mui-disabled + .MuiSwitch-track': {
//       opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     boxSizing: 'border-box',
//     width: 22,
//     height: 22,
//   },
//   '& .MuiSwitch-track': {
//     borderRadius: 26 / 2,
//     backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
//     opacity: 1,
//     transition: theme.transitions.create(['background-color'], {
//       duration: 500,
//     }),
//   },
// }));
interface ProfileSettingsProps {
  // checked: boolean;
  goBack: () => void;
  // handleChange: (
  //   event: React.SyntheticEvent<Element, Event>,
  //   checked: boolean
  // ) => void;
}
const ProfileSettingsComponent: React.FC<ProfileSettingsProps> = ({
  // checked,
  goBack,
  // handleChange,
}) => {
  const [openDeleteAccountPopup, setOpenDeleteAccountPopup] =
    React.useState(false);
  return (
    <div className="w-full flex flex-col gap-12">
      <ChevronLeftIcon
        onClick={goBack}
        data-testid="my-account-back-button"
        className="md:hidden text-2xl mb-8"
      />
      <div>
        <Typography
          fontSize={28}
          fontWeight={400}
          className=" uppercase text-[#1F1F1F] dark:text-white text-lg lg:text-xl 2xl:text-2xl"
        >
          {ApplicationString.settingsScreenLabels.settings}
        </Typography>
        {/* <Divider className="mt-2" />
        <div className="mt-4 flex flex-col gap-y-6">
          <div className="flex flex-row items-center gap-x-25">
            <Typography className="text-secondary_text dark:text-dark_secondary_text text-nowrap sm:text-xl text-lg 2xl:text-2xl capitalize font-normal">
              {ApplicationString.settingsScreenLabels.darkMode}
            </Typography>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              label=""
              onChange={handleChange}
              data-testid="my-account-dark-mode-switch"
              checked={checked}
            />
          </div>
          <div className="flex flex-row items-center gap-x-21">
            <Typography className="text-secondary_text sm:text-xl text-lg 2xl:text-2xl  dark:text-dark_secondary_text text-nowrap capitalize font-normal">
              {ApplicationString.settingsScreenLabels.notifications}
            </Typography>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              label=""
              defaultChecked
            />
          </div>
        </div> */}
      </div>
      <div>
        <Typography
          fontSize={28}
          fontWeight={400}
          className=" uppercase text-[#1F1F1F] dark:text-white text-lg lg:text-xl 2xl:text-2xl"
        >
          Delete My Account
        </Typography>
        <Divider className="mt-2" />
        <div className="mt-5 flex flex-row items-center gap-x-21">
          <Button
            variant="contained"
            onClick={() => setOpenDeleteAccountPopup(true)}
            data-testid="my-account-delete-account-button"
            className=" sm:text-sm text-base text-white 2xl:text-lg dark:text-dark_secondary_text text-nowrap capitalize font-normal hover:bg-primary rounded-full px-6 py-2"
          >
            Delete Account
          </Button>
        </div>
        {openDeleteAccountPopup && (
          <DeleteAccountPopupComponent
            open={openDeleteAccountPopup}
            setOpen={setOpenDeleteAccountPopup}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileSettingsComponent;
