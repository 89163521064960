import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import SectionHeading from '../../common/SectionHeading';
import pinIcon from '../../../Assets/Location.png';
import aminites from '../../../Assets/autobrightness.png';
import starIcon from '../../../Assets/star.png';
import cssUtils from '../../../Utils/cssUtils';

import {
  checkIfArrayIsTruthyOrNot,
  getCoverPhoto,
  getLocalStorageAccountInfo,
  getSubcategoryNameById,
} from '../../../Utils/utils';
import { IVehiclesData } from '../../../Interfaces/common/SearchVehicle/SearchVehicle';
import {
  ISubCategory,
  IUserSettingsData,
  IVehicleCategory,
} from '../../../Interfaces/Settings/SettingsInterfaces';
import {
  categoryEnums,
  categoryNameEnums,
  localStorageEnums,
} from '../../../Utils/enums';
import ApplicationString from '../../../Constants/applicationString';
import NextPreviousComponent from '../../common/NextPreviousComopnent';
import InternalRoute from '../../../Utils/internalRoutes';
import usePageSize from '../../../Hooks/usePageSize';

interface PaginationState {
  [key: string]: number;
}
interface ListingComponentProps {
  loading: boolean;
  categorySelected: number;
  subcategoriesData: { [key: string]: IVehiclesData[] };
  subCategory: string;
}
const ExploreListingComponent: React.FC<ListingComponentProps> = ({
  loading,
  subcategoriesData,
  categorySelected,
  subCategory,
}) => {
  const [paginationState, setPaginationState] = useState<PaginationState>({});
  const navigate = useNavigate();
  const [currentData, setCurrentData] = useState<{
    [key: string]: IVehiclesData[];
  }>({});
  const pageSize = usePageSize();
  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;

  const categoriesData: IVehicleCategory[] = checkIfArrayIsTruthyOrNot<
    IVehicleCategory[]
  >(userSettingsData?.vehicleCategory);

  const allSubCategories = categoriesData.flatMap(
    (category) => category.subCategory
  );
  function getSubcategoryIdByName(subcategories: ISubCategory[], name: string) {
    const subcategoryId = subcategories.find(
      (subCat: ISubCategory) =>
        subCat.name.toLowerCase()?.replace(/ /g, '') === name
    );
    return subcategoryId ? subcategoryId.id : '';
  }

  function scrollToSubcategory(subcategoryId: number) {
    const subcategoryElement = document.getElementById(
      `subcategory-${subcategoryId}`
    );

    if (subcategoryElement) {
      subcategoryElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  useEffect(() => {
    const targetSubcategoryId = getSubcategoryIdByName(
      allSubCategories,
      subCategory
    );
    scrollToSubcategory(Number(targetSubcategoryId));
    const handleScroll = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // eslint-disable-next-line prefer-destructuring
          const id = entry.target.id;
          const url = new URL(window.location.href);
          const categoryValue =
            categorySelected === categoryEnums.watercraft
              ? categoryNameEnums.watercraft
              : categoryNameEnums.landVehicles;
          url.searchParams.set('category', categoryValue);
          url.searchParams.set('subcategory', id);
          window.history.replaceState({}, '', url);
        }
      });
    };

    const observer = new IntersectionObserver(handleScroll, {
      threshold: 0.5,
    });

    const sections = document.querySelectorAll('.subcategory-section');
    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubCategories, subCategory, subcategoriesData]);

  useEffect(() => {
    const initialPaginationState: PaginationState = {};
    Object.keys(subcategoriesData).forEach((key) => {
      initialPaginationState[key] = 1;
    });
    setPaginationState(initialPaginationState);
  }, [subcategoriesData]);

  useEffect(() => {
    const updatedData: { [key: string]: IVehiclesData[] } = {};
    Object.entries(subcategoriesData).forEach(([key, data]) => {
      const currentPage = paginationState[key];
      updatedData[key] = data.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );
    });
    setCurrentData(updatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategoriesData, paginationState, pageSize]);

  const handleNext = (subcategory: string) => {
    setPaginationState((prevState) => {
      const totalItems = subcategoriesData[subcategory].length;
      const newPage = prevState[subcategory] + 1;
      return {
        ...prevState,
        [subcategory]: newPage > Math.ceil(totalItems / pageSize) ? 1 : newPage,
      };
    });
  };

  const handlePrevious = (subcategory: string) => {
    setPaginationState((prevState) => {
      const totalItems = subcategoriesData[subcategory].length;
      const newPage = prevState[subcategory] - 1;
      return {
        ...prevState,
        [subcategory]: newPage < 1 ? Math.ceil(totalItems / pageSize) : newPage,
      };
    });
  };

  const renderItems = (subcategory: string, currentPage: number) => {
    const subcategoryData = subcategoriesData[subcategory];

    if (!subcategoryData || !Array.isArray(subcategoryData)) {
      return [];
    }
    const totalItems = subcategoryData?.length;
    if (totalItems <= pageSize) {
      return subcategoryData;
    }
    const startIdx = (currentPage - 1) * pageSize;
    const items = [];
    for (let i = 0; i < pageSize; i += 1) {
      items.push(subcategoryData[(startIdx + i) % totalItems]);
    }
    return items;
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center">
          <CircularProgress className="mt-4" />
        </div>
      ) : (
        <div>
          {Object.keys(currentData).length === 0 ? (
            <div className="min-h-[50vh] w-full flex justify-center items-center text-6xl text-secondary_text dark:text-dark_secondary_text">
              {ApplicationString.exploreScreen.exploreLabels.noVehiclesFound}
            </div>
          ) : (
            Object.entries(currentData).map(([subcategory, data], index) => {
              const idToSearch = subcategory;
              const result = getSubcategoryNameById(
                allSubCategories,
                Number(idToSearch)
              );
              const totalPagesNo = Math.ceil(
                subcategoriesData[subcategory] &&
                  subcategoriesData[subcategory].length / pageSize
              );
              const currentPage = paginationState[subcategory] || 1;
              const isPaginated =
                subcategoriesData[subcategory] &&
                subcategoriesData[subcategory].length > pageSize;
              return (
                <div
                  key={subcategory}
                  id={result.toLowerCase()?.replace(/ /g, '')}
                  data-testid={result.toLowerCase()?.replace(/ /g, '')}
                  className={`subcategory-section ${index % 2 === 0 ? 'bg-white dark:bg-dark_bg_secondary' : 'bg-odd_bg'} w-full`}
                >
                  <div className={cssUtils.layout.sectionLayout}>
                    <div
                      className={` w-full flex items-center flex-col sm:flex-row justify-between`}
                    >
                      <SectionHeading
                        title={result}
                        description={' '}
                        descTextColor={cssUtils.colors.slate}
                        darkTheme={index % 2 === 0}
                      />
                      {isPaginated && (
                        <NextPreviousComponent
                          currentPage={currentPage}
                          totalPages={totalPagesNo}
                          onNext={() => handleNext(subcategory)}
                          onPrevious={() => handlePrevious(subcategory)}
                        />
                      )}
                    </div>
                    <div className=" grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-4 justify-center mt-5">
                      {data &&
                        renderItems(subcategory, currentPage)?.map((item) => {
                          let bookingRate;
                          if (
                            item.bookingRateHourly &&
                            item.bookingSlotType === '24hourly'
                          ) {
                            bookingRate = `${item.bookingRate24Hourly} ${ApplicationString.exploreScreen.bookingValues.twentyFour_hourly}`;
                          } else if (
                            item.bookingRate12Hourly &&
                            item.bookingSlotType === '12hourly'
                          ) {
                            bookingRate = `${item.bookingRate12Hourly} ${ApplicationString.exploreScreen.bookingValues.twelve_hourly}`;
                          } else {
                            bookingRate = `${item.bookingRateHourly} ${ApplicationString.exploreScreen.bookingValues.hourly}`;
                          }
                          const [amount, ...rest] = bookingRate.split(' ');
                          const text = rest.join(' ');
                          const formattedRating = item.rating
                            ? item.rating.toFixed(1)
                            : '';
                          const coverPhoto = getCoverPhoto(item.images);
                          const amenitiesCount = item.metaData.amenities
                            ? item.metaData.amenities.length
                            : 0;
                          return (
                            <button
                              type="button"
                              key={item.id}
                              id={`subcategory-${item.subCategory}`}
                              data-testid={`subcategory-${item.subCategory}`}
                              onClick={() => {
                                const path =
                                  InternalRoute.Vehicle_details.replace(
                                    ':VehicleId',
                                    item.id.toString()
                                  );
                                navigate(path);
                              }}
                              className="border border-slate-200 dark:bg-dark_bg_surface bg-white shadow-lg rounded-2xl transition-transform duration-300 transform cursor-pointer hover:scale-105"
                            >
                              <div className="relative">
                                {coverPhoto ? (
                                  <img
                                    src={coverPhoto}
                                    alt={item.title}
                                    className="h-45 w-full min-w-30 2xl:min-30 rounded-2xl rounded-b-none object-cover"
                                  />
                                ) : (
                                  <div className="h-45 w-full min-w-30 bg-gray-300 rounded-2xl flex justify-center items-center text-2xl">
                                    {
                                      ApplicationString.commonStrings
                                        .noImageFound
                                    }
                                  </div>
                                )}
                              </div>

                              <div className="flex flex-col gap-2 sm:gap-4 p-4 sm:py-4">
                                <p className="font-semibold flex justify-start text-xl leading-tight text-title_text dark:text-dark_titleText">
                                  {item.title}
                                </p>
                                <div className="flex gap-1 items-center">
                                  {/* TODO: Add proper alt here */}
                                  <img className="" src={pinIcon} alt="" />
                                  <p className="font-sans font-normal text-secondary_text dark:text-dark_text_sub_primary text-base leading-tight">
                                    {item.city}
                                  </p>
                                </div>
                                <div className="flex flex-row gap-1 items-center justify-start">
                                  {/* TODO: Add proper alt here */}
                                  <img className="" src={aminites} alt="" />
                                  <p className="font-sans font-normal text-secondary_text dark:text-dark_text_sub_primary text-base leading-tight">
                                    {amenitiesCount > 10
                                      ? `10+ ${ApplicationString.exploreScreen.exploreLabels.amenitiesCount}`
                                      : `${amenitiesCount} ${ApplicationString.exploreScreen.exploreLabels.amenitiesCount}`}
                                  </p>
                                </div>
                                <div className="flex justify-between">
                                  {item.rating && (
                                    <div className="flex gap-1 items-center">
                                      <img className="" src={starIcon} alt="" />
                                      <p className="font-sans font-normal text-secondary_text dark:text-dark_text_sub_primary text-base leading-tight">
                                        {`${formattedRating}/5.0`}
                                      </p>
                                    </div>
                                  )}

                                  <div className="flex flex-row items-center justify-items-start">
                                    <Typography
                                      variant="body2"
                                      color="text.primary"
                                      className="dark:text-dark_titleText text-title_text font-bold text-base text-nowrap"
                                    >
                                      ${amount}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      fontSize={16}
                                      fontWeight={510}
                                      className=" text-secondary_text dark:text-dark_text_sub_primary text-nowrap ml-1 leading-5"
                                    >
                                      {text}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </button>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default ExploreListingComponent;
// w-full bg-blue-50
