// src/components/Card.tsx
import React, { useEffect, useState } from 'react';

interface CardProps {
  // eslint-disable-next-line react/require-default-props
  cardExtraClasses?: string;
  title: string;
  imgSrc: string;
  description: string;
  // eslint-disable-next-line react/require-default-props
  description2?: string;
  mobileImgSrc: string;
}

const Card: React.FC<CardProps> = ({
  cardExtraClasses,
  title,
  imgSrc,
  description,
  description2,
  mobileImgSrc,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(imgSrc || '');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 1024) {
      setImageSrc(mobileImgSrc);
    } else {
      setImageSrc(imgSrc);
    }
  }, [windowWidth]);

  return (
    <li
      className={`sticky top-28 rounded-4xl bg-card_bg dark:bg-dark_bg_primary transform-origin-top shadow-lg sm:h-[440px] lg:h-[400px] 2xl:h-[500px] max-h-min stack-cards__item ${cardExtraClasses}`}
    >
      <div className="flex h-full flex-col lg:flex-row">
        <div className="flex order-2 h-full lg:order-1 flex-col gap-3 lg:gap-5 flex-1 justify-start items-start p-6 md:py-2 lg:py-8 2xl:py-10 lg:px-12">
          <span className="text-primary_text dark:text-dark_primary_text text-[20px] sm:text-[22px] lg:text-[26px] xl:text-[28px] 2xl:text-[30px] font-bold">
            {title}
          </span>
          <div className="flex flex-col gap-2.5">
            <span className="text-secondary_text dark:text-dark_secondary_text text-[14px]  md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] font-normal">
              {description}
            </span>
            {description2 && (
              <span className="text-secondary_text dark:text-dark_secondary_text text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] font-normal">
                {description2}
              </span>
            )}
          </div>
        </div>
        <div className="flex-1 order-1 lg:order-2 max-h-50 lg:max-h-min lg:h-full p-2.5 lg:py-2.5 lg:pr-2.5">
          <img
            src={imageSrc}
            alt={title}
            className="h-full w-full rounded-4xl rounded-b-none lg:rounded-r-4xl lg:rounded-l-none"
          />
        </div>
      </div>
    </li>
  );
};

export default Card;
