import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionHeading from '../../common/SectionHeading';
import starIcon from '../../../Assets/star.png';
import boatIcon from '../../../Assets/ph_boat.png';
import ApplicationString from '../../../Constants/applicationString';
import cssUtils from '../../../Utils/cssUtils';
import InternalRoute from '../../../Utils/internalRoutes';
import { getLatLongFromAddress } from '../../../Services/location.service';
import { capitalizeAndInsertSpace } from '../../../Utils/utils';

const TopRecommend: React.FC = () => {
  const { destinationData, heading, unitData } = ApplicationString.destinations;
  const Navigate = useNavigate();
  const [isXL, setIsXL] = useState(window.innerWidth > 1535);

  const handleNavigate = async (destination: string) => {
    if (destination) {
      await getLatLongFromAddress(destination, 'searchInput').then(
        (details) => {
          if (typeof details === 'string') {
            const trimmedData = {
              category: 'allVehicles',
              locationName: capitalizeAndInsertSpace(destination),
              latlong: details,
              startDate: '',
              endDate: '',
            };
            const queryParams = new URLSearchParams(trimmedData).toString();
            Navigate(
              InternalRoute.searchParamUrl.replace('#queryParams', queryParams)
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsXL(window.innerWidth > 1535);
    };

    window.addEventListener('resize', handleResize);

    // Check the width initially
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="w-full dark:bg-dark_bg_surface bg-slate-100">
      <div className="mx-auto py-8 lg:px-8 2xl:px-20 lg:py-15 px-8">
        <div className="flex 2xl:justify-center">
          <div className=" 2xl:w-150">
            <SectionHeading
              title={heading.title}
              descTextColor={cssUtils.colors.slate}
              description={heading.description}
            />
          </div>
        </div>
        {isXL ? (
          <div className="grid grid-cols-1 2xl:items-center gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 justify-center ">
            <div className="2xl:grid gap-6">
              {destinationData.slice(0, 3).map((destination) =>
                destination?.title ? (
                  <div
                    key={`${destination.title}-${destination.vehicleCount}`}
                    className="h-auto flex items-center  shadow-lg rounded-2xl bg-white dark:bg-dark_bg_secondary"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40 rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4">
                            <p className="font-semibold text-xl 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={boatIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={starIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handleNavigate(
                                  destination?.searchText as string
                                )
                              }
                              data-testid={`button-${destination.searchText}`}
                              className="w-fit font-semibold text-md sm:text-xl leading-tight text-blue-950 dark:text-dart_web_accent"
                            >
                              {destination.linkText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`${destination?.rating}-${destination?.vehicleCount}`}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    {/* TODO: add proper alt here */}
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
            <div className="2xl:grid gap-6">
              {destinationData.slice(3, 5).map((destination) =>
                destination?.title ? (
                  <div
                    key={`${destination.title}-${destination.vehicleCount}`}
                    className=" h-fit flex items-center  shadow-lg rounded-xl bg-white dark:bg-dark_bg_secondary"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={boatIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary   text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={starIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary   text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handleNavigate(destination.searchText as string)
                              }
                              data-testid={`button-${destination.searchText}`}
                              className="w-fit font-semibold text-md sm:text-xl leading-tight text-blue-950 dark:text-dart_web_accent"
                            >
                              {destination.linkText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`${destination?.rating}-${destination?.vehicleCount}`}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    {/* TODO: add proper alt here */}
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
            <div className="2xl:grid gap-6">
              {destinationData.slice(5, 7).map((destination) =>
                destination?.title ? (
                  <div
                    key={`${destination?.title}-${destination?.vehicleCount}`}
                    className="h-fit flex items-center  shadow-lg rounded-2xl bg-white dark:bg-dark_bg_secondary"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={boatIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary  text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={starIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handleNavigate(destination.searchText as string)
                              }
                              data-testid={`button-${destination.searchText}`}
                              className="w-fit font-semibold text-md sm:text-xl leading-tight text-blue-950 dark:text-dart_web_accent"
                            >
                              {destination.linkText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`${destination?.rating}-${destination?.vehicleCount}`}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    {/* TODO: add proper alt here */}
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
            <div className="2xl:grid gap-6">
              {destinationData.slice(7, 10).map((destination) =>
                destination?.title ? (
                  <div
                    key={`${destination?.title}-${destination?.vehicleCount}`}
                    className="h-auto flex items-center  shadow-lg rounded-2xl bg-white dark:bg-dark_bg_secondary"
                  >
                    <div className="py-2 sm:py-2 sm:mx-2">
                      <div className="flex h-fit w-full sm:flex-row gap-4 items-center">
                        <img
                          className="h-50 min-w-40 max-w-40  rounded-2xl object-cover"
                          src={destination.imageUrl}
                          alt={destination.title}
                        />
                        {destination.title && (
                          <div className="flex flex-col gap-2 sm:gap-4 ">
                            <p className="font-semibold text-xl 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                              {destination.title}
                            </p>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={boatIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary    text-base leading-tight">
                                {destination.vehicleCount} {unitData.Vehicles}
                              </p>
                            </div>
                            <div className="flex gap-1 items-center">
                              {/* TODO: add proper alt here */}
                              <img className="" src={starIcon} alt="" />
                              <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary  text-base leading-tight">
                                {destination.rating}/{unitData.maxRating}
                              </p>
                            </div>
                            <button
                              type="button"
                              onClick={() =>
                                handleNavigate(destination.searchText as string)
                              }
                              data-testid={`button-${destination.searchText}`}
                              className="w-fit font-semibold text-md sm:text-xl leading-tight text-blue-950 dark:text-dart_web_accent"
                            >
                              {destination.linkText}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={`${destination?.rating}-${destination?.vehicleCount}`}
                    className="flex w-full sm:flex-row flex-col gap-4 sm:items-center"
                  >
                    {/* TODO: add proper alt here */}
                    <img
                      className="h-40 w-full rounded-2xl object-cover"
                      src={destination.imageUrl}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 2xl:items-center gap-6 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 22xl:grid-cols-4 justify-center ">
            {destinationData.slice(0, 9).map((destination) =>
              destination?.title ? (
                <div
                  key={`${destination?.title}-${destination?.vehicleCount}`}
                  className="h-auto flex items-center  shadow-lg rounded-2xl bg-white dark:bg-dark_bg_secondary"
                >
                  <div className="py-2 3xsm:w-full sm:py-2 mx-2 sm:mx-2">
                    <div className="flex 3xsm:flex-col 2xsm:flex-row h-fit w-full sm:flex-row 3xsm:gap-1 2xsm:gap-4 3xsm:items-start 2xsm:items-center">
                      <img
                        className="2xsm:h-50 3xsm:min-h-40 3xsm:h-30 3xsm:min-w-full 3xsm:w-full 2xsm:w-auto 3xsm:max-w-full 2xsm:max-w-50 2xsm:min-w-40 min-w-40 min-h-50 max-h-50 w-auto rounded-2xl object-cover"
                        src={destination.imageUrl}
                        alt={destination.title}
                      />
                      {destination.title && (
                        <div className="flex flex-col gap-2 sm:gap-4 3xsm:p-2 2xsm:p-0">
                          <p className="font-semibold text-xl 2xl:text-2xl leading-tight text-blue-950 dark:text-dart_web_accent">
                            {destination.title}
                          </p>
                          <div className="flex gap-1 items-center">
                            <img className="" src={boatIcon} alt="vehicles" />
                            <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary text-base leading-tight">
                              {destination.vehicleCount} {unitData.Vehicles}
                            </p>
                          </div>
                          <div className="flex gap-1 items-center">
                            <img className="" src={starIcon} alt="rating" />
                            <p className="font-sans font-normal text-slate-600 dark:text-dark_text_sub_primary text-base leading-tight">
                              {destination.rating}/{unitData.maxRating}
                            </p>
                          </div>
                          <button
                            type="button"
                            onClick={() =>
                              handleNavigate(destination.searchText as string)
                            }
                            data-testid={`button-${destination.searchText}`}
                            className="w-fit font-semibold text-md sm:text-xl leading-tight text-blue-950 dark:text-dart_web_accent"
                          >
                            {destination.linkText}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  key={`${destination?.rating}-${destination?.vehicleCount}`}
                  className="flex hidden w-full sm:flex-row flex-col gap-4 sm:items-center"
                >
                  <img
                    className="h-40 w-full rounded-2xl object-cover"
                    src={destination.imageUrl}
                    alt=""
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopRecommend;
