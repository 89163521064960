import React, { ChangeEvent, useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { SelectChangeEvent } from '@mui/material/Select';
import { useSearchParams } from 'react-router-dom';
import MyProfileComponent from '../../Components/MyProfile';
import {
  getDetailsFromLocalStorage,
  getLocalStorageAccountInfo,
  handleApiError,
  showApiError,
} from '../../Utils/utils';

import {
  EditProfileFormEnums,
  localStorageEnums,
  updateProfilePhotoFilesFormats,
  uploadMaxFileSize,
  verificationTypeEnums,
} from '../../Utils/enums';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/LoginInterfaces';
import { ApiRequest } from '../../Api/api';
import URLS from '../../Constants/urls';
import { IProfile } from '../../Interfaces/common/Profile';
import { IUserSettingsData } from '../../Interfaces/Settings/SettingsInterfaces';
import { getSettingsData } from '../../Components/common/UserHeader';
import {
  IApiErrorResponse,
  IDisableFields,
  IerrorsData,
} from '../../Interfaces/interfaces';
import ApplicationString from '../../Constants/applicationString';
import commmonRegexs from '../../Utils/commonRegex';
import { useToast } from '../../Services/ToastService';

interface IProfileFormData {
  name: string;
  mobile: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  countryCode: string;
  avatarPath: string;
}
interface IErrorsData {
  countryCode: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  address: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  name: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  city: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  state: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  country: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  zip: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  email: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
  mobile: {
    error: boolean;
    onlyErrorMsg?: boolean;
    ValidationMessage: string;
  };
}
interface IApiPayload {
  verificationOf: string;
  verificationType: string;
}

const MyProfileContainer: React.FC = () => {
  const [profileData, setProfileData] = useState<IProfile>({} as IProfile);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [countryCodeOptions, setCountryCodeOptions] = React.useState<string[]>(
    []
  );
  const [isFieldDisabled, setIsFieldDisabled] = useState<IDisableFields>({
    isMobileChanged: false,
    isEmailChanged: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<string>('');
  const toast = useToast();
  const [imagePath, setImagePath] = useState<string>('');
  const [isUploadingProfilePicture, setIsUploadingProfilePicture] =
    useState<boolean>(false);
  const [isEmailPhoneChanged, setIsEmailPhoneChanged] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = React.useState<IProfileFormData>({
    name: '',
    mobile: '',
    email: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    avatarPath: '',
    country: '',
    zip: '',
    countryCode: '',
  });
  const [formData, setFormData] = React.useState<IProfileFormData>({
    name: '',
    mobile: '',
    email: '',
    address: '',
    address2: '',
    avatarPath: '',
    city: '',
    state: '',
    country: '',
    zip: '',
    countryCode: '',
  });
  const [isVerify, setIsVerify] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [otp, setOtp] = React.useState<string>('');
  const [verificationId, setVerificationId] = React.useState<string>('');
  const [canResendOtp, setCanResetOtp] = useState<boolean>(false);
  const [countDownTimeNumber, setCountDownTimeNumber] = useState<number>(20);
  const [verificationerror, setVerificationerror] = React.useState<IerrorsData>(
    {
      otp: {
        error: false,
        validationMessage: '',
      },
    }
  );
  const [params, setParams] = useSearchParams({});
  const [isVerifyingOtp, setIsVerifyingOtp] = React.useState<boolean>(false);
  const getEmail = atob(params.get('email') || '');
  const initialError: IErrorsData = {
    countryCode: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    mobile: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    name: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    email: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },

    address: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    city: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    state: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    country: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
    zip: {
      error: false,
      onlyErrorMsg: false,
      ValidationMessage: '',
    },
  };
  const [errorsData, setErrorsData] = React.useState<IErrorsData>(initialError);
  const showValidationErrors = (err: AxiosError<IApiErrorResponse>) => {
    if (
      (err?.response?.status === HttpStatusCode.BadRequest ||
        err?.response?.status === HttpStatusCode.NotFound) &&
      formData.name?.trim() &&
      formData.mobile?.trim() &&
      formData.address?.trim() &&
      formData.email?.trim() &&
      formData.zip?.trim() &&
      formData.city?.trim() &&
      formData.state?.trim() &&
      formData.country?.trim() &&
      formData.countryCode?.trim()
    ) {
      setErrorsData((prevState) => ({
        ...prevState,
        countryCode: {
          error: false,
          ValidationMessage: '',
        },
        name: {
          error: false,
          ValidationMessage: '',
        },
        mobile: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        address: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        email: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        zip: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        city: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        state: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
        country: {
          error: false,
          onlyErrorMsg: true,
          ValidationMessage: '',
        },
      }));
    }
  };
  const accountId = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.accountId;

  const startCountingDownTime = (): void => {
    setCountDownTimeNumber(20);
    setVerificationerror({
      otp: {
        error: false,
        validationMessage: '',
      },
    });
    setCanResetOtp(false);
    const timerRef = setInterval(() => {
      setCountDownTimeNumber((prevState) => {
        if (prevState <= 1) {
          clearTimeout(timerRef);
          setCanResetOtp(true);
          return 0;
        }
        return prevState - 1;
      });
    }, 1000);
  };
  useEffect(() => {
    startCountingDownTime();
  }, [isModalOpen]);

  const getVerificationId = async (payload: IApiPayload) => {
    try {
      setIsModalOpen(true);
      const res = await ApiRequest.post(
        `${URLS.USER_GET_VERIFICATION_ID}`,
        payload
      );
      const cryptedVerificationId = res.data.verificationId;
      setVerificationId(res?.data?.verificationId);
      const cryptedEmail = window.btoa(getEmail);
      setParams({
        verificationId: cryptedVerificationId,
        email: cryptedEmail,
      });
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
      setVerificationerror({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.invalidMsgs
              .wrongCode,
        },
      });
    }
  };

  const resendOtp = (): void => {
    getVerificationId({
      verificationOf: `+91-${formData.mobile}`,
      verificationType: verificationTypeEnums.phone,
    });
    startCountingDownTime();
  };

  const getMyProfileData = async () => {
    try {
      setIsDataLoading(true);
      const response = await ApiRequest.get(
        `${URLS.ACCOUNT_DETAILS_BY_ID.replace('#{accountId}', accountId ?? '')}`
      );
      if (response?.status === HttpStatusCode.Ok) {
        setProfileData(response.data);
        setInitialValues(response.data);
        const [countryCodeForOtp, mobileWithoutCountryCode] = (
          response?.data?.mobile ?? ''
        )
          .toString()
          .split('-');
        setFormData(response?.data);
        setFormData((prevState) => ({
          ...prevState,
          countryCode: countryCodeForOtp,
          mobile: mobileWithoutCountryCode,
        }));
        setIsDataLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getMyProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const handleNameChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const name = e.target.value;
    let nameError = false;
    let validationMessage = '';
    if (name.length > EditProfileFormEnums.maxEmailLength) {
      nameError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.nameLength;
    }
    setErrorsData((prevState) => ({
      ...prevState,
      name: {
        error: nameError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
    setFormData((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));
  };
  const handleCityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      city: e.target.value,
    }));
  };
  const handleAddressChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const address = e.target.value;
    let addressError = false;
    let validationMessage = '';
    if (address.length > EditProfileFormEnums.maxEmailLength) {
      addressError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.addressLength;
    }
    setErrorsData((prevState) => ({
      ...prevState,
      address: {
        error: addressError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
    setFormData((prevState) => ({
      ...prevState,
      address: e.target.value,
    }));
  };
  const handleAddress2Change = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const address2 = e.target.value;
    let addressError = false;
    let validationMessage = '';
    if (address2.length > EditProfileFormEnums.maxEmailLength) {
      addressError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.addressLength;
    }
    setErrorsData((prevState) => ({
      ...prevState,
      address: {
        error: addressError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
    setFormData((prevState) => ({
      ...prevState,
      address2: e.target.value,
    }));
  };
  const handleReset = () => {
    const [countryCodeForOtp, mobileWithoutCountryCode] = (
      initialValues.mobile ?? ''
    )
      .toString()
      .split('-');
    setFormData(initialValues);
    setFormData((prevState) => ({
      ...prevState,
      countryCode: countryCodeForOtp,
      mobile: mobileWithoutCountryCode,
    }));
  };
  const resetError = () => {
    setErrorsData(initialError);
  };
  const handleEmailChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: true });
    const email = e.target.value;

    if (email === profileData.email) {
      setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
    }
    let emailError = false;
    let validationMessage = '';
    if (!commmonRegexs.email.test(email) && email !== '') {
      emailError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.invalidEmail;
    }
    if (email.length > EditProfileFormEnums.maxEmailLength) {
      emailError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.emailLength;
    }
    if (email === '') {
      emailError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.emailRequired;
    }
    setErrorsData((prevState) => ({
      ...prevState,
      email: {
        error: emailError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
    if (email.length > 0 || email === '') {
      setIsEmailPhoneChanged(true);
    } else {
      setIsVerify(false);
      setIsEmailPhoneChanged(false);
    }
    setFormData((prevState) => ({
      ...prevState,
      email: e.target.value,
    }));
  };
  const handleStateChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      state: e.target.value,
    }));
  };
  const handleCountryChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      country: e.target.value,
    }));
  };
  const handlePhoneNumberChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setIsEmailPhoneChanged(true);
    let countryCodeError = false;
    let countryCodeValidationMessage = '';
    setIsFieldDisabled({ isMobileChanged: true, isEmailChanged: false });
    const mobile = e.target.value;
    const mobileWithoutCountryCode = (profileData?.mobile ?? '')
      .toString()
      .split('-');
    if (mobile === mobileWithoutCountryCode[1]) {
      setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
    }
    let mobileError = false;
    let validationMessage = '';
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        mobile: e.target.value.trim(),
      }));
      if (mobile.length > EditProfileFormEnums.maxPhoneNumberLength) {
        mobileError = true;
        validationMessage =
          ApplicationString.EditProfile.validationMsgs.phoneNumberLength;
      }
      if (mobile.length < EditProfileFormEnums.maxPhoneNumberLength) {
        mobileError = true;
        validationMessage =
          ApplicationString.EditProfile.validationMsgs.minPhoneNumberLength;
      }
      if (mobile.length === 0) {
        mobileError = true;
        validationMessage =
          ApplicationString.EditProfile.validationMsgs.PhoneNumberRequired;
      }
      if (
        formData.countryCode === '' &&
        mobile.length === EditProfileFormEnums.maxPhoneNumberLength
      ) {
        countryCodeError = true;
        countryCodeValidationMessage =
          ApplicationString.EditProfile.validationMsgs.emptyCountryCode;
      }
      setErrorsData((prevState) => ({
        ...prevState,
        mobile: {
          error: mobileError,
          ValidationMessage: validationMessage.trim(),
        },
        countryCode: {
          error: countryCodeError,
          ValidationMessage: countryCodeValidationMessage.trim(),
        },
      }));
    }
  };

  const handleZipCodeChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const zipCode = e.target.value;
    let zipCodeError = false;
    let validationMessage = '';
    setFormData((prevState) => ({
      ...prevState,
      zip: e.target.value,
    }));
    if (zipCode.length > EditProfileFormEnums.maxZipCodeLength) {
      zipCodeError = true;
      validationMessage =
        ApplicationString.EditProfile.validationMsgs.zipCodeLength;
    }
    setErrorsData((prevState) => ({
      ...prevState,
      zip: {
        error: zipCodeError,
        ValidationMessage: validationMessage.trim(),
      },
    }));
  };

  const handleCountryCodeChange = (e: SelectChangeEvent<string>) => {
    setIsEmailPhoneChanged(true);
    setIsFieldDisabled({ isMobileChanged: true, isEmailChanged: false });
    const countryCode = e.target.value;
    const mobileWithoutCountryCode = (profileData?.mobile ?? '')
      .toString()
      .split('-');
    if (countryCode === mobileWithoutCountryCode[0]) {
      setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
    }

    setFormData((prevState) => ({
      ...prevState,
      countryCode,
    }));
    if (countryCode !== '') {
      setErrorsData((prevState) => ({
        ...prevState,
        countryCode: {
          error: false,
          ValidationMessage: '',
        },
      }));
    }
  };
  const onAccountDetailsSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setErrorsData((prevState) => ({
      ...prevState,
      name: {
        error: !formData?.name?.trim(),
        ValidationMessage: !formData?.name?.trim()
          ? ApplicationString.EditProfileForm.validationMessages
              .requiredMessages.name
          : '',
      },
      address: {
        error: !formData?.address?.trim(),
        ValidationMessage: !formData?.address?.trim()
          ? ApplicationString.EditProfileForm.validationMessages
              .requiredMessages.address
          : '',
      },
    }));
    if (
      formData?.email?.trim() !== '' &&
      !commmonRegexs.email.test(formData?.email) &&
      formData?.name?.trim() === ''
    )
      return;

    if (!formData.name?.trim() || !formData.address?.trim()) return;
    const payload = {
      name: formData?.name,
      address: formData?.address,
      address2: formData?.address2,
      email: formData.email,
      avatarPath: formData?.avatarPath,
      mobile: formData?.mobile,
      city: formData?.city,
      state: formData?.state,
      country: formData?.country,
      zip: formData?.zip,
      countryCode: formData?.countryCode,
      verificationId: verificationId || null,
    };
    try {
      setIsLoading(true);
      const res = await ApiRequest.put(
        `${URLS.UPDATE_PROFILE.replace('#{accountId}', accountId ?? '')}`,
        payload
      );
      if (res?.status === HttpStatusCode.NoContent) {
        toast?.success(ApplicationString.ProfileScreenLables.profileUpdated);
        setIsEditing(false);
        getMyProfileData();
        setIsVerify(false);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      showValidationErrors(axiosError);
      handleApiError(axiosError);
      showApiError(axiosError, toast);
    } finally {
      setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
      setIsLoading(false);
    }
  };
  const uploadProfilePicture = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      const file = event.target.files && event.target.files[0];
      const formdata = new FormData();
      if (!file) return;
      const isRightFormat = updateProfilePhotoFilesFormats.includes(file.type);
      if (!isRightFormat) {
        setFileUploadError(
          ApplicationString.ProfileScreenLables.errorMsgs.fileUpload
            .fileTypeError
        );
        return;
      }

      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > uploadMaxFileSize) {
        setFileUploadError(
          ApplicationString.ProfileScreenLables.errorMsgs.fileUpload
            .fileSizeExceeds
        );
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        formdata.append('file', file);
        formdata.append('mediaUploadedFor', 'profilePicture');
        try {
          setIsUploadingProfilePicture(true);
          ApiRequest.post(URLS.UPLOAD_PROFILE_PHOTO, formdata)
            .then((res) => {
              if (res?.status === HttpStatusCode.Created) {
                setImagePath(res.data.path);
                setFormData((prevState) => ({
                  ...prevState,
                  avatarPath: res.data.path,
                }));
                setFileUploadError('');
                resolve(res.data);
              }
            })
            .catch((error) => {
              setIsUploadingProfilePicture(true);
              const axiosError = error as AxiosError<IApiErrorResponse>;
              handleApiError(axiosError);
              reject(error);
            });
        } catch (error) {
          const axiosError = error as AxiosError<IApiErrorResponse>;
          handleApiError(axiosError);
          reject(error);
        }
      };
    });
  };

  useEffect(() => {
    const userSettings = getDetailsFromLocalStorage<IUserSettingsData>(
      localStorageEnums.settings
    );
    if (userSettings) {
      setCountryCodeOptions(
        userSettings.setting.country.map((item) => item.countryCode)
      );
    } else {
      getSettingsData(setCountryCodeOptions);
    }
  }, []);

  const checkVerificationAlreadyExist = async (payload: IApiPayload) => {
    try {
      const res = await ApiRequest.post(`${URLS.USER_EXISTS}`, payload);
      if (res?.status === HttpStatusCode.Ok) {
        if (res?.data && JSON.stringify(res?.data) !== '{}') {
          if (!res.data.isExists) {
            getVerificationId(payload);
          } else if (payload.verificationType === 'email') {
            setErrorsData((prevState) => ({
              ...prevState,
              email: {
                error: true,
                ValidationMessage:
                  ApplicationString.signUpWithEmail.validationMessages
                    .invalidMsgs.alreadyExistEmail,
              },
            }));
          } else if (payload.verificationType === 'mobile') {
            setErrorsData((prevState) => ({
              ...prevState,
              mobile: {
                error: true,
                ValidationMessage:
                  ApplicationString.signUpWithEmail.validationMessages
                    .invalidMsgs.alreadyExistMobile,
              },
            }));
          }
        }
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      handleApiError(axiosError);
    }
  };
  const handleVerification = () => {
    const { email, mobile, countryCode } = formData || {};
    const { email: profileEmail, mobile: profileMobile } = profileData || {};

    const isInvalidEmail = !commmonRegexs.email.test(email);
    const isNonEmptyEmail = email?.trim() !== '';
    const hasMobileError = errorsData.mobile.error;

    if (
      (isInvalidEmail && isFieldDisabled.isEmailChanged && isNonEmptyEmail) ||
      hasMobileError
    ) {
      return;
    }
    if (!isEmailPhoneChanged) return;
    const hasEmailInForm = email !== '';
    const hasMobileInForm = mobile !== '';
    const emailChanged = email !== profileEmail;
    const mobileChanged = mobile !== String(profileMobile);

    const createApiData = (
      verificationOf: string,
      verificationType: string
    ) => ({
      verificationOf,
      verificationType,
    });

    if (emailChanged) {
      checkVerificationAlreadyExist(createApiData(email, 'email'));
    } else if (mobileChanged) {
      checkVerificationAlreadyExist(
        createApiData(`${countryCode}-${mobile}`, 'mobile')
      );
    } else if (!hasEmailInForm || !hasMobileInForm) {
      setIsVerify(false);
    }
  };

  const verifyTheOtp = async (): Promise<void> => {
    if (!verificationId) {
      return;
    }
    if (otp === '') {
      setVerificationerror({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.requiredMsgs.code,
        },
      });
      return;
    }
    if (otp.length !== 6) {
      setVerificationerror({
        otp: {
          error: true,
          validationMessage:
            ApplicationString.otpScreen.validationMessages.invalidMsgs
              .minOtpLength,
        },
      });
      return;
    }
    try {
      const payload = {
        token: otp,
      };
      setIsVerifyingOtp(true);
      const res = await ApiRequest.put(
        `${URLS.USER_VERIFY_OTP.replace('#{id}', verificationId)}`,
        payload
      );
      if (res?.status === HttpStatusCode.NoContent) {
        setVerificationerror({
          otp: {
            error: false,
            validationMessage: '',
          },
        });
        setIsModalOpen(false);
        setIsVerify(false);
        setOtp('');
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError<IApiErrorResponse>;
        handleApiError(axiosError);
        setVerificationerror({
          otp: {
            error: true,
            validationMessage:
              ApplicationString.otpScreen.validationMessages.invalidMsgs
                .wrongCode,
          },
        });
      }
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  const onCloseModal = () => {
    setIsFieldDisabled({ isMobileChanged: false, isEmailChanged: false });
    setOtp('');
    setIsModalOpen(false);
    setVerificationerror({
      otp: {
        error: false,
        validationMessage: '',
      },
    });
  };
  useEffect(() => {
    if (isEmailPhoneChanged) {
      const { email: profileEmail, mobile: profileMobile } = profileData || {};
      const { email: formEmail, mobile: formMobile } = formData || {};
      const [countryCodeForOtp, mobileWithoutCountryCode] = (
        profileMobile ?? ''
      )
        .toString()
        .split('-');
      const emailChanged = formEmail !== profileEmail;
      const mobileChanged =
        formData?.mobile !== undefined &&
        formMobile !== String(mobileWithoutCountryCode);
      const countryCodeChanged =
        formData.countryCode !== countryCodeForOtp && !!formData?.mobile;
      const shouldVerify = emailChanged || mobileChanged || countryCodeChanged;

      setIsVerify(shouldVerify);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.email, formData.mobile, formData.countryCode]);

  return (
    <MyProfileComponent
      profileData={profileData}
      isLoading={isLoading}
      isEditing={isEditing}
      handleReset={handleReset}
      resetError={resetError}
      setIsEditing={setIsEditing}
      imagePath={imagePath}
      isDataLoading={isDataLoading}
      fileUploadError={fileUploadError}
      isUploadingProfilePicture={isUploadingProfilePicture}
      setIsUploadingProfilePicture={setIsUploadingProfilePicture}
      countryCodeOptions={countryCodeOptions}
      onAccountDetailsSubmit={onAccountDetailsSubmit}
      formData={formData}
      canResendOtp={canResendOtp}
      countDownTimeNumber={countDownTimeNumber}
      errorsData={errorsData}
      handleNameChange={handleNameChange}
      handleEmailChange={handleEmailChange}
      handlePhoneNumberChange={handlePhoneNumberChange}
      handleAddressChange={handleAddressChange}
      handleAddress2Change={handleAddress2Change}
      handleCityChange={handleCityChange}
      handleStateChange={handleStateChange}
      handleCountryChange={handleCountryChange}
      handleZipCodeChange={handleZipCodeChange}
      uploadProfilePicture={uploadProfilePicture}
      handleCountryCodeChange={handleCountryCodeChange}
      handleVerification={handleVerification}
      handleClose={onCloseModal}
      verifyTheOtp={verifyTheOtp}
      isVerify={isVerify}
      resendOtp={resendOtp}
      setIsVerify={setIsVerify}
      isVerifyingOtp={isVerifyingOtp}
      verificationerror={verificationerror}
      isModalOpen={isModalOpen}
      otp={otp}
      setOtp={setOtp}
      isFieldDisabled={isFieldDisabled}
      setIsFieldDisabled={setIsFieldDisabled}
    />
  );
};
export default MyProfileContainer;
