import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PassengerDropdown from '../../BookingDetails/personCountComponent';
import TimeDropdown from '../../BookingDetails/timeDropdown';
import {
  ChildComponentProps,
  BookingParams,
  DateRange,
  BookingDetails,
} from '../../BookingDetails/bookingInterface';
import calenderIcon from '../../../Assets/Calendar.png';
import CommonAccordion from '../../common/CommonAccordian';
import {
  UsdCurrency,
  availabilitySlots1hr,
  bookingCalculation,
  calculateHours,
  convertTo24HourFormat,
  convertToLabeledTimeSlots,
  createTimeSlots,
  getBookingRate,
  getBookingRateTypeDisplay,
  getLocalStorageAccountInfo,
  getLocalStorageSettingData,
  isMultipleDay,
  isSingleDay,
  slots12hr,
} from '../../../Utils/utils';
import { TaxAndDepositData } from '../../../Interfaces/LocalStorageSettings';
import cssUtils from '../../../Utils/cssUtils';
import TimeSlotDropdown from '../TimeSlotComponent.tsx';
import PaymentDetails from '../vehiclePaymentDetailsComponent';
import ApplicationString from '../../../Constants/applicationString';
import BookingDatePicker from '../../common/BookingDatePicker';
import {
  ITermsAndCondition,
  IUserSettingsData,
} from '../../../Interfaces/Settings/SettingsInterfaces';
import { localStorageEnums } from '../../../Utils/enums';
import './style.css';
import ColorModeContext from '../../../Utils/ColorModeContext';

interface TimeSlot {
  time: string;
  disabled: boolean;
}
const VehicleBookingComponent: React.FC<ChildComponentProps> = ({
  vehicleDetails,
  disabledDateRange,
  disabledSlots,
  onCreateBooking,
  useForVehicleDetails = true,
  editBookingDetails,
  setChange,
  disabledMultipleDayStartSlots,
  disabledMultipleDayEndSlots,
  monthLimit,
}) => {
  const [selection, setSelection] = useState<'single' | 'multi'>(
    editBookingDetails?.bookingType || 'single'
  );
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: editBookingDetails?.startFormattedDate || null,
    endDate: editBookingDetails?.endFormattedDate || null,
  });
  const [passengerCount, setPassengerCount] = useState<number>(
    editBookingDetails?.numberOfPerson || 1
  );
  const [pickUpTime, setPickUpTime] = useState<string | null>(
    editBookingDetails?.startTimeLabel || ''
  );
  const [timeSlot, setTimeSlot] = useState(editBookingDetails?.timeSlot || '');
  const [dropOffTime, setDropOffTime] = useState<string | null>(
    editBookingDetails?.endTimeLabel || ''
  );
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(
    editBookingDetails?.startTime || null
  );
  const [selectedEndTime, setSelectedEndTime] = useState<string | null>(
    editBookingDetails?.endTime || null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [isSingleDayForBooking, setIsSingleDayFor] = useState<boolean>(true);
  const [isMultipleDayForBooking, setIsMultipleDayFor] =
    useState<boolean>(true);
  const [bookingDetailsforPayment, setBookingDetails] =
    useState<BookingDetails | null>(null);
  const [dateError, setDateError] = useState<boolean>(false);
  const [timeError, setTimeError] = useState<boolean>(false);
  const [timeSlotError, setSlotTimeError] = useState<boolean>(false);
  const [TermsAndConditions, setTermsAndConditions] = React.useState<
    ITermsAndCondition[]
  >([]);
  const [availabilitySlots12hr, setAvailabilitySlots12hr] =
    useState<TimeSlot[]>();
  const { currentTheme } = useContext(ColorModeContext);
  const settings = getLocalStorageSettingData();
  const timeFrames =
    settings?.setting['12hourlyTimeFrame']?.map((item) => item.value) || [];

  const taxAndDepositData: TaxAndDepositData =
    getLocalStorageSettingData()?.setting?.taxAndDepositData;

  useEffect(() => {
    if (vehicleDetails) {
      setAvailabilitySlots12hr(
        createTimeSlots(
          convertToLabeledTimeSlots(
            vehicleDetails?.timeFrameFor12Hourly || timeFrames
          )
        )
      );
      const isSingle = isSingleDay(vehicleDetails);
      const isMultiple = isMultipleDay(vehicleDetails);

      setIsSingleDayFor(!isSingle);
      setIsMultipleDayFor(!isMultiple);

      if (isSingle) {
        setSelection('single');
      } else if (isMultiple) {
        setSelection('multi');
      }
      if (editBookingDetails?.vehicleId === vehicleDetails?.id) {
        setSelection(editBookingDetails?.bookingType || 'single');
      }
    } else {
      setAvailabilitySlots12hr(createTimeSlots(slots12hr));
    }
  }, []);

  const handleSetPassengerCount = (count: number) => {
    setPassengerCount(count);
  };
  const [timeSlotBooking, setTimeSlotBooking] = useState(false);

  const convertToString = (date: Date | null): string | null => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return null;
  };

  const isRangeDisabled = (start: Date | string, end: Date | string) => {
    for (
      let rangeIndex = 0;
      rangeIndex < disabledDateRange.length;
      rangeIndex += 1
    ) {
      const range = disabledDateRange[rangeIndex];
      const startingDate = new Date(start);
      for (
        let date = startingDate;
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        if (range.startDate && range.endDate) {
          if (convertToString(date) === range.startDate) return true;
        }
      }
    }
    return false;
  };

  const handleDateChange = (newValue: {
    startDate: string | null;
    endDate: string | null;
  }) => {
    setDateRange(newValue);
    setDateError(false);
    if (setChange && newValue) {
      setChange(newValue);
    }
    if (!(newValue.startDate && newValue.endDate)) {
      setDateError(true);
    }
  };

  const handleSelectTime = (
    startTime: string | null,
    endTime: string | null
  ) => {
    if (startTime && endTime) {
      setSelectedStartTime(convertTo24HourFormat(startTime));
      setSelectedEndTime(convertTo24HourFormat(endTime));
      setTimeError(false);
    }
    if (editBookingDetails?.timeSlot) {
      setTimeSlotBooking(false);
    } else {
      setTimeSlotBooking(true);
      setSlotTimeError(false);
    }
  };
  const resetValues = () => {
    setDateRange({
      startDate: null,
      endDate: null,
    });
    setPassengerCount(1);
    setTimeSlot('');
    setPickUpTime(null);
    setDropOffTime(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setBookingDetails(null);
    setDateError(false);
    setTimeError(false);
  };

  const resetValuesForSingleSelection = () => {
    setDateRange({
      startDate: null,
      endDate: null,
    });
    setDateError(false);
    setPassengerCount(1);
    setTimeSlot('');
  };
  const resetValuesForMultiSelection = () => {
    setDateRange({
      startDate: null,
      endDate: null,
    });
    setDateError(false);
    setPassengerCount(1);
    setPickUpTime(null);
    setDropOffTime(null);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
  };

  const checkDateRangeError = () => {
    if (selection === 'multi') {
      if (
        dateRange.startDate &&
        dateRange.endDate &&
        dateRange.startDate === dateRange.endDate
      ) {
        setErrorMessage(ApplicationString.bookingSameDateErrorMessage);
        setDateError(true);
        return true;
      }

      if (
        dateRange.startDate &&
        dateRange.endDate &&
        isRangeDisabled(dateRange.startDate, dateRange.endDate)
      ) {
        setErrorMessage(
          ApplicationString.bookingIncludesDisabledDateErrorMessage
        );
        setDateError(true);
        return true;
      }
    }

    if (dateRange.startDate && dateRange.endDate && pickUpTime && dropOffTime) {
      const hours = calculateHours(
        dateRange.startDate,
        dateRange.endDate,
        pickUpTime,
        dropOffTime
      );

      if (vehicleDetails?.maxDaysFor24Hourly) {
        const maxHours = vehicleDetails.maxDaysFor24Hourly * 24;
        if (hours > maxHours) {
          setErrorMessage(
            ApplicationString.bookingMaxDaysError.replace(
              '$maxDays',
              String(vehicleDetails.maxDaysFor24Hourly)
            )
          );
          setDateError(true);
          return true;
        }
      }
    }

    setDateError(false);
    setErrorMessage('');
    return false;
  };

  const handleConfirmAndPay = () => {
    let hasError = false;

    if (checkDateRangeError()) {
      setDateError(true);
      hasError = true;
    }
    if (!dateRange.startDate && !dateRange.endDate) {
      setDateError(true);
      setErrorMessage(ApplicationString.dateErrorMessage);
      hasError = true;
    }
    if (
      (!selectedStartTime || !selectedEndTime) &&
      editBookingDetails?.bookingType !== selection
    ) {
      setTimeError(true);
      hasError = true;
    }
    if (editBookingDetails) {
      if (
        (!selectedStartTime || !selectedEndTime) &&
        (pickUpTime || dropOffTime) &&
        editBookingDetails?.bookingType !== selection
      ) {
        setTimeError(true);
        hasError = true;
      }
      if (
        !editBookingDetails?.timeSlot &&
        !timeSlotBooking &&
        editBookingDetails?.bookingType !== selection
      ) {
        setSlotTimeError(true);
        hasError = true;
      }
    }

    if (hasError) return;

    // Ensure all necessary details are selected before proceeding
    if (
      dateRange.startDate &&
      dateRange.endDate &&
      selectedStartTime &&
      selectedEndTime &&
      bookingDetailsforPayment
    ) {
      const isoStartDate = new Date(dateRange.startDate)
        .toISOString()
        .split('T')[0];
      const isoEndDate = new Date(dateRange.endDate)
        .toISOString()
        .split('T')[0];

      // Format ISO strings with selected times
      const startDateTime = new Date(
        `${isoStartDate}T${selectedStartTime}:00Z`
      );
      const endDateTime = new Date(`${isoEndDate}T${selectedEndTime}:00Z`);

      const bookingData = {
        // pickupTime: pickUpTime,
        // dropoffTime: dropOffTime,
        bookingType: selection,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
        startDate: startDateTime.toISOString(),
        endDate: endDateTime.toISOString(),
        numberOfPerson: passengerCount,
        rate: bookingDetailsforPayment?.vehicleRate || 0,
        tax: bookingDetailsforPayment?.taxAmount,
        deposit: bookingDetailsforPayment?.depositRate,
        total: bookingDetailsforPayment?.totalCost,
        vehicleId: Number(vehicleDetails?.id),
      };
      onCreateBooking(bookingData);
    } else {
      // Handle error or show a message indicating missing details
      console.error(
        ApplicationString.vehicleBookingStaticData.errors.selectDateAndTime
      );
    }
  };

  const handleSwitchSelection = (selected: string) => {
    if (editBookingDetails?.timeSlot) {
      setTimeError(false);
    } else {
      setSlotTimeError(false);
    }
    if (editBookingDetails?.bookingType === 'single' && selected === 'multi') {
      setTimeSlot(editBookingDetails?.timeSlot || '');
      resetValuesForMultiSelection();
    }
    if (editBookingDetails?.bookingType === 'multi' && selected === 'single') {
      resetValuesForSingleSelection();
    }
    if (selected === 'single') {
      setSelection('single');
    } else {
      setSelection('multi');
    }
    if (useForVehicleDetails) {
      resetValues();
    }
  };

  const handlePickUpTimeChange = (time: string) => {
    setPickUpTime(time);
    setDropOffTime(null);
    setSelectedEndTime(null);
    setTimeError(false);
  };

  const handleDropOffTimeChange = (time: string) => {
    setDropOffTime(time);
    setTimeError(false);
  };

  useEffect(() => {
    checkDateRangeError();
  }, [dropOffTime, dateRange.startDate, dateRange.endDate]);

  useEffect(() => {
    if (selection === 'multi') {
      if (setChange) {
        setChange({ selectionType: true, ...dateRange });
      }
    } else if (setChange) {
      setChange({ selectionType: false, ...dateRange });
    }
  }, [selection, dateRange]);

  useEffect(() => {
    if (selection === 'multi' && pickUpTime && dropOffTime) {
      setSelectedStartTime(convertTo24HourFormat(pickUpTime));
      setSelectedEndTime(convertTo24HourFormat(dropOffTime));
    }
    // Ensure all necessary details are selected before proceeding
    if (
      dateRange.startDate &&
      dateRange.endDate &&
      selectedStartTime &&
      selectedEndTime
    ) {
      const isoStartDate = new Date(dateRange.startDate)
        .toISOString()
        .split('T')[0];
      const isoEndDate = new Date(dateRange.endDate)
        .toISOString()
        .split('T')[0];

      // Format ISO strings with selected times
      const startDateTime = new Date(
        `${isoStartDate}T${selectedStartTime}:00Z`
      );
      const endDateTime = new Date(`${isoEndDate}T${selectedEndTime}:00Z`);
      const params: BookingParams = {
        dateRangeCal: {
          startDate: startDateTime.toISOString(),
          endDate: endDateTime.toISOString(),
        },
        pickupTime: pickUpTime,
        dropoffTime: dropOffTime,
        hrRate: vehicleDetails?.bookingRateHourly || 0,
        hr12Rate: vehicleDetails?.bookingRate12Hourly || 0,
        hr24Rate: vehicleDetails?.bookingRate24Hourly || 0,
        depositRate: taxAndDepositData?.minimumDeposit || 0,
        taxRatePercentage: taxAndDepositData?.taxPercentage || 0,
        depositPercentage: taxAndDepositData?.depositPercentage || 0,
      };
      const bookingDetails: BookingDetails = bookingCalculation(params);
      setBookingDetails(bookingDetails);
    }
  }, [
    selectedStartTime,
    dropOffTime,
    pickUpTime,
    selectedEndTime,
    selection,
    dateRange,
  ]);

  useEffect(() => {
    if (selection === 'single') {
      if (editBookingDetails?.timeSlot) {
        const [startTime, endTime] = editBookingDetails.timeSlot
          .split(' to ')
          .map((t) => t.trim());
        setSelectedStartTime(convertTo24HourFormat(startTime));
        setSelectedEndTime(convertTo24HourFormat(endTime));
      }
    } else {
      setSelectedStartTime(null);
      setSelectedEndTime(null);
    }
  }, [selection]);

  useEffect(() => {
    if (editBookingDetails) {
      if (editBookingDetails?.bookingType === selection) {
        setDateRange({
          startDate: editBookingDetails.startDate,
          endDate: editBookingDetails.endDate,
        });
        setDateError(false);
      }
    }
  }, [selection]);

  useEffect(() => {
    if (setChange && editBookingDetails?.startDate) {
      const newDateRange: DateRange = {
        startDate: editBookingDetails.startDate,
        endDate: editBookingDetails.endDate,
      };
      setChange(newDateRange);
    }
  }, [editBookingDetails]);

  useEffect(() => {
    const settingsData = getLocalStorageAccountInfo<IUserSettingsData>(
      localStorageEnums.settings
    )?.setting;
    if (settingsData) {
      const { securityDeposit, termsAndCondition } = settingsData;

      // Define titles for "Regular Bookings" and "Short Notice Bookings"
      const regularBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.RegularBookings;
      const shortNoticeBookingsTitle =
        ApplicationString.MyBookingDetailsComponent.ShortNoticeBookings;

      // Extract details for "Regular Bookings"
      const regularBookingsDetails =
        termsAndCondition.find((term) => term.title === regularBookingsTitle)
          ?.details || [];

      // Extract details for "Short Notice Bookings"
      const shortNoticeBookingsDetails =
        termsAndCondition.find(
          (term) => term.title === shortNoticeBookingsTitle
        )?.details || [];

      // Ensure securityDeposit is an array
      const securityDepositDetails = Array.isArray(securityDeposit)
        ? securityDeposit
        : [securityDeposit];

      // Combine details, ensuring they are flat arrays
      const combinedDetails = [
        regularBookingsTitle,
        ...regularBookingsDetails,
        shortNoticeBookingsTitle,
        ...shortNoticeBookingsDetails,
      ];

      // Update terms and conditions
      const updatedTermsAndConditions: ITermsAndCondition[] =
        termsAndCondition.map((term) => {
          if (term.title === regularBookingsTitle) {
            return {
              ...term,
              details: securityDepositDetails,
            };
          }
          if (term.title === shortNoticeBookingsTitle) {
            return {
              ...term,
              details: combinedDetails.flat(), // Flatten the combined details array if it's nested
            };
          }
          return term;
        });

      setTermsAndConditions(updatedTermsAndConditions);
    } else {
      setTermsAndConditions([]);
    }
  }, []);

  const updatedAccordianTitles = [
    {
      id: 1,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies.securityDeposit,
    },
    {
      id: 2,
      title:
        ApplicationString.OwnerBookingDetails.bookingPolicies
          .cancellationPolicy,
    },
  ];

  const readOnly =
    isSingleDayForBooking && isMultipleDayForBooking ? 'bookingRead-only' : '';

  return (
    <div className={`flex flex-col gap-4 p-6 ${readOnly}`}>
      {vehicleDetails && (
        <div className="text-2xl font-bold text-blue-600">
          {UsdCurrency(getBookingRate(vehicleDetails, selection))}
          <span className="text-gray-500 font-normal text-lg">
            {getBookingRateTypeDisplay(vehicleDetails, selection)}
          </span>
        </div>
      )}
      <div
        className={`${currentTheme === 'dark' ? 'dark-booking-selection' : 'booking-selection'} mt-4 flex-col flex gap-2 2xsm:gap-0 2xsm:space-x-4 2xsm:flex-row`}
      >
        {!isSingleDayForBooking && (
          <label
            htmlFor="single"
            className={`flex items-center ${!isSingleDayForBooking ? 'w-2/5' : 'w-full'}  p-3 rounded-full border dark:bg-dark_bg_secondary dark:border-dark_borderStroke cursor-pointer ${
              selection === 'single' ? 'selected' : 'bg-white border-gray-300'
            }`}
          >
            <input
              type="radio"
              data-testid="singleDay-input"
              id="single"
              name="duration"
              value="single"
              // disabled={isSingleDayForBooking}
              checked={selection === 'single'}
              onChange={() => handleSwitchSelection('single')}
              className=""
            />
            <span
              className={`text-base font-medium mr-2 ${isSingleDayForBooking ? 'text-slate-400 dark:text-slate-600' : ''} ${
                selection === 'single'
                  ? 'text-blue-600'
                  : 'text-gray-600 dark:text-slate-200'
              }`}
            >
              {
                ApplicationString.vehicleBookingStaticData.bookingDuration
                  .singleDay
              }
            </span>
          </label>
        )}
        {!isMultipleDayForBooking && (
          <label
            htmlFor="multi"
            className={`flex items-center ${!isMultipleDayForBooking ? ' w-2/5' : 'w-full'} p-3 rounded-full dark:bg-dark_bg_secondary border dark:border-dark_borderStroke cursor-pointer  ${
              selection === 'multi' ? 'selected' : 'bg-white border-gray-300'
            }`}
          >
            <input
              type="radio"
              data-testid="multiDay-input"
              id="multi"
              name="duration"
              value="multi"
              // disabled={isMultipleDayForBooking}
              checked={selection === 'multi'}
              onChange={() => handleSwitchSelection('multi')}
              className=""
            />
            <span
              className={`text-base font-medium   ${isMultipleDayForBooking ? 'text-slate-400 dark:text-slate-600' : ''} ${
                selection === 'multi'
                  ? 'text-blue-600'
                  : 'text-gray-600 dark:text-slate-200'
              }`}
            >
              {
                ApplicationString.vehicleBookingStaticData.bookingDuration
                  .multiDay
              }
            </span>
          </label>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <div className="w-full dark:border-dark_borderStroke rounded-xl p-4 bg-white dark:bg-dark_bg_secondary border border-gray-300">
          <div className="flex gap-2 items-center justify-between">
            <div className="flex gap-2 items-center">
              <img
                src={calenderIcon}
                className="w-4 h-4"
                alt={
                  ApplicationString.vehicleBookingStaticData.placeholders
                    .calendarIconAlt
                }
              />
              <span className="text-secondary_text dark:text-dark_text_sub_primary">
                {selection === 'single'
                  ? ApplicationString.vehicleBookingStaticData
                      .bookingDetailsData.date
                  : ApplicationString.vehicleBookingStaticData
                      .bookingDetailsData.dates}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-gray-800 dark:text-dark_text_sub_primary text-sm"
              />
            </div>
          </div>
          <BookingDatePicker
            useForVehicleDetails={useForVehicleDetails}
            isDateSingle={selection === 'single'}
            disabledDateRange={disabledDateRange}
            initialStartDate={dateRange.startDate}
            initialEndDate={dateRange.endDate}
            onDateChange={handleDateChange}
            monthLimit={monthLimit}
          />
        </div>
        {dateError && (
          <div className="text-red-500 text-sm">{errorMessage}</div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        {selection === 'single' ? (
          <TimeSlotDropdown
            initialSlotTime={timeSlot}
            dateRange={dateRange}
            borderRadious="rounded-xl"
            slots1hr={availabilitySlots1hr}
            slots12hr={availabilitySlots12hr || []}
            vehicleDetails={vehicleDetails}
            disabledSlots={disabledSlots || []}
            onSelectTime={handleSelectTime}
          />
        ) : (
          <TimeDropdown
            onDropOffTimeChange={handleDropOffTimeChange}
            onPickUpTimeChange={handlePickUpTimeChange}
            initialPickUpTime={pickUpTime}
            initialDropOffTime={dropOffTime}
            disabledPickUpTimes={disabledMultipleDayStartSlots}
            disabledDropOffTimes={disabledMultipleDayEndSlots}
            borderRadious="rounded-xl"
          />
        )}
        {(timeSlotError || timeError) && (
          <div className="text-red-500 text-sm">
            {ApplicationString.vehicleBookingStaticData.error.time}
          </div>
        )}
      </div>

      <PassengerDropdown
        borderRadious="rounded-xl"
        passengerCapacity={vehicleDetails?.capacity || 1}
        setPassengerCount={handleSetPassengerCount}
        passengerCount={passengerCount}
      />
      <button
        data-testid="booking-save-button"
        type="button"
        className={`w-full ${cssUtils.button.secondary}`}
        onClick={handleConfirmAndPay}
      >
        {useForVehicleDetails
          ? ApplicationString.vehicleBookingStaticData.bookingDetailsData
              .bookNow
          : ApplicationString.vehicleBookingStaticData.bookingDetailsData.save}
      </button>
      {bookingDetailsforPayment &&
        (dropOffTime || selectedEndTime) &&
        !dateError &&
        !timeError &&
        useForVehicleDetails && (
          <PaymentDetails
            securityDeposit={bookingDetailsforPayment.depositRate}
            taxes={bookingDetailsforPayment.taxAmount}
            total={bookingDetailsforPayment.totalCost}
            calculatedRate={bookingDetailsforPayment.vehicleRate}
          />
        )}
      {useForVehicleDetails && (
        <CommonAccordion
          title={updatedAccordianTitles}
          TermsAndConditions={TermsAndConditions}
        />
      )}
    </div>
  );
};

export default VehicleBookingComponent;
