/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { addressComponentsEnums, PlaceApiCountries } from '../Utils/enums';

interface AddressComponent {
  types: string[];
  long_name: string;
}
function getAddressComponents(components: AddressComponent[], type: string) {
  const component = components.find((comp) => comp.types.includes(type));
  return component ? component.long_name : '';
}

export const getLatLongFromAddress = (
  address: string,
  section: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const service = new (window as any).google.maps.places.PlacesService(
      document.createElement('div')
    );
    service.textSearch({ query: address }, (results: any[], status: any) => {
      if (
        status === (window as any).google.maps.places.PlacesServiceStatus.OK &&
        results.length > 0
      ) {
        const data = results[0].geometry.location;
        if (section === 'stepperInput') {
          const details = {
            latLong: `${data.lat()},${data.lng()}`,
            dropLatLong: `${data.lat()},${data.lng()}`,
          };

          resolve(details);
        }
        if (section === 'searchInput') {
          const latLong = `${data.lat()},${data.lng()}`;
          resolve(latLong);
        }
      } else {
        console.error('PlacesService status:', status);
        resolve(null);
      }
      reject(new Error('Failed to find location'));
    });
  });
};

export const loadGooglePlaces = (inputValue: string): Promise<string[]> => {
  const autocomplete = new (
    window as any
  ).google.maps.places.AutocompleteService();
  return autocomplete.getPlacePredictions(
    {
      input: inputValue,
      componentRestrictions: {
        country: [PlaceApiCountries.USA, PlaceApiCountries.India],
      },
    },
    (predictions: string[], status: string) => {
      return status ===
        (window as any).google.maps.places.PlacesServiceStatus.OK
        ? predictions
        : [];
    }
  );
};

export const getPlaceDetailsById = (
  placeId: string,
  fieldType: string
): Promise<any> => {
  const service = new (window as any).google.maps.places.PlacesService(
    document.createElement('div')
  );
  return new Promise((resolve, reject) => {
    service.getDetails({ placeId }, (placeDetails: any, status: any) => {
      if (
        status === (window as any).google.maps.places.PlacesServiceStatus.OK
      ) {
        if (fieldType === 'geometry') {
          const latLong = `${placeDetails.geometry.location.lat()},${placeDetails.geometry.location.lng()}`;

          resolve(latLong);
        }
        if (fieldType === 'address_component,geometry') {
          const addressComponents = placeDetails.address_components;
          const details = {
            latLon: `${placeDetails.geometry.location.lat()},${placeDetails.geometry.location.lng()}`,
            city: getAddressComponents(
              addressComponents,
              addressComponentsEnums.locality
            ),
            country: getAddressComponents(
              addressComponents,
              addressComponentsEnums.country
            ),
            zipCode: getAddressComponents(
              addressComponents,
              addressComponentsEnums.zipCode
            ),
            state: getAddressComponents(
              addressComponents,
              addressComponentsEnums.state
            ),
          };
          resolve(details);
        } else {
          resolve(null);
        }
      } else {
        reject(new Error(`PlacesService Error: ${status}`));
      }
    });
  });
};
