import React from 'react';
import NotAuthorized from '../Screen/ErrorPages/notAuthorize/notAuthorized';
import HomeScreen from '../Screen/Home';
import LoginWithEmailScreen from '../Screen/LoginPages/LoginWithEmailScreen';
import LoginWithPhoneScreen from '../Screen/LoginPages/LoginWithPhoneScreen';
import { userRoleEnums } from '../Utils/utils';
import CommingSoon from '../Components/common/CountdownComonent';
import Contact from '../Components/Contact';
import SignUpWithEmailScreen from '../Screen/SignupPages/SignUpWithEmailScreen';
import SignUpWithPhoneScreen from '../Screen/SignupPages/SignUpWithPhoneScreen';
import SignUpOtpEmailScreen from '../Screen/SignupPages/SignUpOtpEmailScreen';
import SignUpVerifiedScreen from '../Screen/SignupPages/SignUpVerifiedScreen';
import SignUpNameAndPasswordScreen from '../Screen/SignupPages/SignUpNameAndPasswordScreen';
import SignUpOtpPhoneScreen from '../Screen/SignupPages/SignUpOtpPhoneScreen';
import AccountCreatedScreen from '../Screen/SignupPages/AccountCreatedScreen';
import SearchScreen from '../Screen/Search';
import ForgotPasswordEmailScreen from '../Screen/ForgotPasswordScreen/ForgotPasswordEmailScreen';
import ForgotPasswordFormScreen from '../Screen/ForgotPasswordScreen/ForgotPasswordFormScreen';
import ForgotPasswordChangedSuccessfullyScreen from '../Screen/ForgotPasswordScreen/ForgotPasswordChangedSuccessfullyScreen';
import ForgotPasswordPhoneScreen from '../Screen/ForgotPasswordScreen/ForgotPasswordPhoneScreen';
import ForgotPasswordOtpScreen from '../Screen/ForgotPasswordScreen/ForgotPasswordOtpScreen';
import BookingDetailsScreen from '../Screen/BookingDetails';
import ExploreScreen from '../Screen/Explore';
import VehicleDetailsScreen from '../Screen/VehicleDetails';
import MybookingScreen from '../Screen/MyBookingScreen/MybookingScreen';
import MyBookingDetailsScreen from '../Screen/MyBookingScreen/MyBookingDetailsScreen';
import AboutUsScreen from '../Screen/AboutUs/AboutUsScreen';
import MyProfileScreen from '../Screen/MyProfile';
import FaqsScreen from '../Screen/FaqsScreen';
import OwnerVehicleDetailsScreen from '../Screen/Owner/OwnerVehicleDetails';
import InternalRoute from '../Utils/internalRoutes';
import VehicleStepperScreen from '../Screen/VehicleStepper';
import OwnerBookingDetailsScreen from '../Screen/OwnerBookingDetails';
import MyListingsOwnerScreen from '../Screen/MyListings';
import OwnerDashboardScreen from '../Screen/OwnerDashboardScreen';
import OwnerMyCalendarComponent from '../Components/OwnerMyCalendar';
import VehicleEditStepperScreen from '../Screen/VehicleEditStepper';
import BeforeLoginScreen from '../Screen/BeforeLoginScreen';
import Notfound from '../Screen/ErrorPages/notFound/notFound';
import PrivacyPolicy from '../Screen/PrivacyPolicy';

export type RouteConfigType = {
  path: string;
  element: React.ReactElement;
  isProtectedRoute: boolean;
  allowedLayout: string;
  allowedRoles: string[];
};

const RouteConfig: RouteConfigType[] = [
  {
    path: InternalRoute.Home,
    element: <HomeScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Owner,
    element: <HomeScreen />,
    isProtectedRoute: true,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.search,
    element: <SearchScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.explore,
    element: <ExploreScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Vehicle_details,
    element: <VehicleDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Vehicle_photo_gallery,
    element: <VehicleDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.myAccount,
    element: <MyProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: 'user',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.settings,
    element: <MyProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: 'user',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.changePassword,
    element: <MyProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: 'user',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.payment,
    element: <MyProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: 'user',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.myAccountOwner,
    element: <MyProfileScreen />,
    isProtectedRoute: true,
    allowedLayout: 'owner',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.Forbidden,
    element: <NotAuthorized />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.NotFound,
    element: <Notfound />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  // {
  //   path: InternalRoute.vehicle_booking,
  //   element: ,
  //   isProtectedRoute: false,
  //   allowedLayout: 'user',
  //   allowedRoles: [userRoleEnums.USER],
  // },
  {
    path: InternalRoute.PhoneLogin,
    element: <LoginWithPhoneScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.EmailLogin,
    element: <LoginWithEmailScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.EmailSignup,
    element: <SignUpWithEmailScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.PhoneSignup,
    element: <SignUpWithPhoneScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.EmailVerificationCode,
    element: <SignUpOtpEmailScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.PhoneVerificationCode,
    element: <SignUpOtpPhoneScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.SignupEmailVerified,
    element: <SignUpVerifiedScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.SignupPhoneVerified,
    element: <SignUpVerifiedScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.SignUpNameAndPassword,
    element: <SignUpNameAndPasswordScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.AccountCreated,
    element: <AccountCreatedScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.EmailForgotPassword,
    element: <ForgotPasswordEmailScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.ForgotPasswordEmailVerificationCode,
    element: <ForgotPasswordOtpScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.ForgotPasswordPhoneVerificationCode,
    element: <ForgotPasswordOtpScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.ForgotPasswordCreateNewPassword,
    element: <ForgotPasswordFormScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.ForgotPasswordSuccess,
    element: <ForgotPasswordChangedSuccessfullyScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.PhoneForgotPassword,
    element: <ForgotPasswordPhoneScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.bookingDetails,
    element: <BookingDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.myBookings,
    element: <MybookingScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.AboutUs,
    element: <AboutUsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.MyBookingsDetails,
    element: <MyBookingDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: '*',
    element: <CommingSoon />,
    isProtectedRoute: false,
    allowedLayout: '',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.Contact,
    element: <Contact />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.FAQ,
    element: <FaqsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.OwnerContact,
    element: <Contact />,
    isProtectedRoute: false,
    allowedLayout: 'owner',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.ownerFAQ,
    element: <FaqsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'owner',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.owner_vehicle_details,
    element: <OwnerVehicleDetailsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'owner',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.owner_vehicle_photo_gallery,
    element: <OwnerVehicleDetailsScreen />,
    isProtectedRoute: true,
    allowedLayout: 'owner',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.vehicleCreate,
    element: <VehicleStepperScreen />,
    isProtectedRoute: true,
    allowedLayout: '',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.OwnerBookingDetails,
    element: <OwnerBookingDetailsScreen />,
    isProtectedRoute: false,
    allowedLayout: 'owner',
    allowedRoles: [userRoleEnums.USER],
  },
  {
    path: InternalRoute.my_listings,
    element: <MyListingsOwnerScreen />,
    isProtectedRoute: true,
    allowedLayout: 'owner',
    allowedRoles: [
      userRoleEnums.OWNER,
      userRoleEnums.USER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.ownerDashboard,
    element: <OwnerDashboardScreen />,
    isProtectedRoute: false,
    allowedLayout: userRoleEnums.OWNER,
    allowedRoles: [userRoleEnums.OWNER],
  },
  {
    path: InternalRoute.statusCompleted,
    element: <OwnerDashboardScreen />,
    isProtectedRoute: false,
    allowedLayout: userRoleEnums.OWNER,
    allowedRoles: [userRoleEnums.OWNER],
  },
  {
    path: InternalRoute.statusRejected,
    element: <OwnerDashboardScreen />,
    isProtectedRoute: false,
    allowedLayout: userRoleEnums.OWNER,
    allowedRoles: [userRoleEnums.OWNER],
  },
  {
    path: InternalRoute.statusCancelled,
    element: <OwnerDashboardScreen />,
    isProtectedRoute: false,
    allowedLayout: userRoleEnums.OWNER,
    allowedRoles: [userRoleEnums.OWNER],
  },
  {
    path: InternalRoute.statusConfirmed,
    element: <OwnerDashboardScreen />,
    isProtectedRoute: false,
    allowedLayout: userRoleEnums.OWNER,
    allowedRoles: [userRoleEnums.OWNER],
  },
  {
    path: InternalRoute.OwnerMyCalendar,
    element: <OwnerMyCalendarComponent />,
    isProtectedRoute: true,
    allowedLayout: 'owner',
    allowedRoles: [
      userRoleEnums.OWNER,
      userRoleEnums.USER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.vehicleEdit,
    element: <VehicleEditStepperScreen />,
    isProtectedRoute: true,
    allowedLayout: '',
    allowedRoles: [
      userRoleEnums.USER,
      userRoleEnums.OWNER,
      userRoleEnums.ADMIN,
    ],
  },
  {
    path: InternalRoute.splashScreen,
    element: <BeforeLoginScreen />,
    isProtectedRoute: false,
    allowedLayout: 'owner_without_login',
    allowedRoles: [userRoleEnums.OWNER_WITHOUT_LOGIN],
  },
  {
    path: InternalRoute.privacyPolicy,
    element: <PrivacyPolicy />,
    isProtectedRoute: false,
    allowedLayout: 'user',
    allowedRoles: [userRoleEnums.USER],
  },
  // {
  //   path: InternalRoute.termsAndCondition,
  //   element: <CommingSoon />,
  //   isProtectedRoute: false,
  //   allowedLayout: 'user',
  //   allowedRoles: [userRoleEnums.USER],
  // },
];

export default RouteConfig;
