/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { AxiosError, HttpStatusCode } from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ApplicationString from '../../Constants/applicationString';
import SectionHeading from '../common/SectionHeading';
import cssUtils from '../../Utils/cssUtils';
import NextPreviousComponent from '../common/NextPreviousComopnent';
import usePageSizeForVehicleListing from './pageSizeForVehicleListing';
import { getLocalStorageAccountInfo } from '../../Utils/utils';
import { LoggedInUserInfoDataType } from '../../Interfaces/Login/LoginInterfaces';
import { localStorageEnums } from '../../Utils/enums';
import { IVehiclesData } from '../../Interfaces/common/SearchVehicle/SearchVehicle';
import URLS from '../../Constants/urls';
import { ApiRequest } from '../../Api/api';
import { IApiErrorResponse } from '../../Interfaces/interfaces';
import InternalRoute from '../../Utils/internalRoutes';

interface IProps {
  setMonthLimit: React.Dispatch<React.SetStateAction<number>>;
}
const OwnerVehicleListComponent: React.FC<IProps> = ({ setMonthLimit }) => {
  const pageSize = usePageSizeForVehicleListing();
  const [sortedListingData, setSortedListingData] = useState<IVehiclesData[]>(
    []
  );
  const isPaginated = sortedListingData && sortedListingData.length > pageSize;
  const [selectedVehicleId, setSelectedVehicleId] = useState<number>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const accountId = getLocalStorageAccountInfo<LoggedInUserInfoDataType>(
    localStorageEnums.userInfo
  )?.accountId;

  const handleNext = () => {
    setSortedListingData((prevData) => {
      const newData = [...prevData];
      const firstItem = newData.shift();
      if (firstItem) {
        newData.push(firstItem);
      }
      return newData;
    });
  };

  const handlePrevious = () => {
    setSortedListingData((prevData) => {
      const newData = [...prevData];
      const lastItem = newData.pop();
      if (lastItem) {
        newData.unshift(lastItem);
      }
      return newData;
    });
  };

  const getVehicleListing = async () => {
    try {
      const res = await ApiRequest.get(
        URLS.VEHICLE_LISTING_PAGE.replace(
          '#{accountId}',
          String(accountId)
        ).replace('#{pageSize}', '90')
      );

      if (res?.status === HttpStatusCode.Ok) {
        const reorderedData = [...res.data.data];
        // const selectedVehicleIndex = reorderedData.findIndex(
        //   (vehicle) => String(vehicle.id) === searchParams.get('vehicleId')
        // );
        const selectedVehicleIndex = reorderedData.findIndex((vehicle) => {
          if (String(vehicle.id) === searchParams.get('vehicleId')) {
            setMonthLimit((vehicle?.monthLimit as number) || 12);
            return true; // this stops the loop once the vehicle is found
          }
          return false;
        });
        if (selectedVehicleIndex === -1 && searchParams.get('vehicleId'))
          navigate(InternalRoute.Home);
        if (selectedVehicleIndex && selectedVehicleIndex > -1) {
          const [selectedVehicle] = reorderedData.splice(
            selectedVehicleIndex,
            1
          );
          reorderedData.unshift(selectedVehicle);
        }
        setSortedListingData(reorderedData);
      }
    } catch (error) {
      const axiosError = error as AxiosError<IApiErrorResponse>;
      console.error(axiosError.message);
    }
  };

  useEffect(() => {
    getVehicleListing();
    if (searchParams.get('vehicleId')) {
      setSelectedVehicleId(Number(searchParams.get('vehicleId')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleOnCardClick = (vehicleId: number, vehicleMonthLimit: number) => {
    setSearchParams({ vehicleId: String(vehicleId) });
    setSelectedVehicleId(vehicleId);
    if (vehicleMonthLimit !== null) setMonthLimit(vehicleMonthLimit);
  };
  const getCurrentCards = () => {
    const cardsPerPage = pageSize;
    return sortedListingData.slice(0, cardsPerPage);
  };
  return (
    <div className="w-full dark:bg-dark_bg_surface bg-blue-50 dark:bg-dark_bg_gray_primary">
      <div className={`!pb-0 w-full ${cssUtils.layout.sectionLayout}`}>
        <div className="flex items-center sm:flex-row justify-between mb-4 md:mb-0">
          <SectionHeading
            title={ApplicationString.OwnerCalendar.OwnerCalendarTitle}
            description={' '}
            darkTheme={false}
            descTextColor={cssUtils.colors.slate}
          />
          {isPaginated && (
            <NextPreviousComponent
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 xl:grid-cols-3 justify-center  sm:mt-4 2xl:mt-5 ">
          {getCurrentCards().map((listing) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div
              className={`border dark:bg-dark_bg_surface bg-white border-slate-50 rounded-2xl transition-transform duration-300 transform cursor-pointer hover:scale-105 ${
                selectedVehicleId === listing?.id
                  ? 'scale-105 border-2 !border-[#1A56DB]'
                  : ''
              }`}
              key={listing?.id}
              data-testid={`owner-calender-vehicle-${listing?.title}`}
              onClick={() =>
                handleOnCardClick(listing?.id, listing?.monthLimit as number)
              }
            >
              {listing?.images.find((image) => image?.isCover) && (
                <div className="relative">
                  <img
                    className="h-45 w-full p-2 min-w-30 xl:min-30 rounded-2xl object-cover justify-center"
                    src={listing?.images.find((image) => image?.isCover)?.path}
                    alt={listing?.title}
                  />
                </div>
              )}

              <div className="flex flex-col gap-2 sm:gap-4 p-4 sm:py-4">
                <p className="font-semibold text-xl leading-tight text-blue-950 dark:text-dart_web_accent">
                  {listing?.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OwnerVehicleListComponent;
