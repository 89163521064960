/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  CheckedBoxIcon,
  CheckedRadioIcon,
  InformationSvgIcon,
} from '../../../Assets/Svgs';
import ApplicationString from '../../../Constants/applicationString';
import useSelectOpenClose from '../../../Hooks/useSelectOpenClose';
import {
  getDetailsFromLocalStorage,
  setLocalStorageInfo,
} from '../../../Utils/utils';
import {
  localStorageEnums,
  gapHoursValues,
  timeFrameHourEnum,
} from '../../../Utils/enums';
import { IUserSettingsData } from '../../../Interfaces/Settings/SettingsInterfaces';
import { ICommonStepsProps } from '../../../Interfaces/Stepper';
import commonRegexs from '../../../Utils/commonRegex';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BookingDetailsStep: React.FC<ICommonStepsProps> = ({
  formData,
  setFormData,
  errorsData,
  setErrorsData,
}) => {
  const selectProps = useSelectOpenClose(true);
  const multipleSelectProps = useSelectOpenClose(true);
  const [isFocusState, setIsFocusState] = useState(false);

  const handleIsHourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        isHourly: e.target.checked,
      },
    }));
    const slotError =
      e.target.checked ||
      formData.step5.is12Hourly ||
      formData.step5.is24Hourly;

    setLocalStorageInfo(!slotError, localStorageEnums.isSlotError);
    if (!slotError) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: ApplicationString.stepperErrorsData.step5.slot,
            error: true,
          },
        },
      }));
    }

    if (e.target.checked) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
  };

  const handleRateHourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value && !commonRegexs.floatValues.test(e.target.value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rateHourly: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step5.numberRate,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rateHourly: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rateHourly: e.target.value ? Number(e.target.value) : null,
        },
      }));
    }
  };
  const handleIs12HourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        is12Hourly: e.target.checked,
      },
    }));
    const slotError =
      e.target.checked || formData.step5.is24Hourly || formData.step5.isHourly;

    setLocalStorageInfo(!slotError, localStorageEnums.isSlotError);
    if (!slotError) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: ApplicationString.stepperErrorsData.step5.slot,
            error: true,
          },
        },
      }));
    }
    if (e.target.checked) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
  };

  const handleRate12HourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value && !commonRegexs.floatValues.test(e.target.value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate12Hourly: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step5.numberRate,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate12Hourly: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate12Hourly: e.target.value ? Number(e.target.value) : null,
        },
      }));
    }
  };

  const handleTimeFrameChange = (event: SelectChangeEvent<string[]>) => {
    setFormData({
      ...formData,
      step5: {
        ...formData.step5,
        timeFrame: event.target.value as string[],
      },
    });
  };
  const handleIs24HourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        is24Hourly: e.target.checked,
      },
    }));
    const slotError =
      e.target.checked || formData.step5.is12Hourly || formData.step5.isHourly;

    setLocalStorageInfo(!slotError, localStorageEnums.isSlotError);
    if (!slotError) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: ApplicationString.stepperErrorsData.step5.slot,
            error: true,
          },
        },
      }));
    }
    if (e.target.checked) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          slot: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
  };
  const handleRate24HourlyChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value && !commonRegexs.floatValues.test(e.target.value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate24Hourly: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step5.numberRate,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate24Hourly: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }

    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          rate24Hourly: e.target.value ? Number(e.target.value) : null,
        },
      }));
    }
  };
  const handleMaxDaysFor24HourlyChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value && !commonRegexs.floatValues.test(e.target.value)) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          maxDaysFor24Hourly: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step5.numberInput,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          maxDaysFor24Hourly: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          maxDaysFor24Hourly: e.target.value ? Number(e.target.value) : null,
        },
      }));
    }
  };
  const handleIsMultipleBookingOnSameDayChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          isMultipleBookingOnSameDay: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        isMultipleBookingOnSameDay:
          e.target.value !== null ? e.target.value === 'yes' : null,
      },
    }));
  };

  const handleGapHourChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          gapHour: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        gapHour: e.target.value !== null ? Number(e.target.value) : null,
      },
    }));
  };
  const handleShortNoticePeriodChange = (
    e: SelectChangeEvent<number>
  ): void => {
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        shortNoticePeriod: Number(e.target.value),
      },
    }));
  };
  const handleMonthLimitChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== '' && Number(e.target.value) > 120) {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          monthLimit: {
            ValidationMessage:
              ApplicationString.stepperErrorsData.step5.monthLimit,
            error: true,
          },
        },
      }));
    } else {
      setErrorsData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          monthLimit: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }

    if (!Number.isNaN(Number(e.target.value))) {
      setFormData((prevState) => ({
        ...prevState,
        step5: {
          ...prevState.step5,
          monthLimit: e.target.value ? Number(e.target.value) : null,
        },
      }));
    }
  };
  const handleDeleteTimeFrame = (timeFrame: string): void => {
    setFormData((prevState) => ({
      ...prevState,
      step5: {
        ...prevState.step5,
        timeFrame: prevState.step5.timeFrame
          ? prevState.step5.timeFrame.filter((item) => item !== timeFrame)
          : [],
      },
    }));
  };
  const settings = getDetailsFromLocalStorage<IUserSettingsData>(
    localStorageEnums.settings
  );
  const timeFrames = settings?.setting['12hourlyTimeFrame'] || [];
  const shortNoticePeriodLabels =
    ApplicationString.vehicleStepper.step5Details.shortNoticePeriod;

  const handleSelectedValue = () => {
    let displayCount;
    if (window.innerWidth < 475) {
      displayCount = 0;
    } else if (window.innerWidth < 786) {
      displayCount = 1;
    } else {
      displayCount = 2;
    }
    const selectedValues = formData.step5.timeFrame || [];
    const displayValues = selectedValues.slice(0, displayCount);
    const tooltipValues = selectedValues.slice(displayCount);
    const moreCount = selectedValues.length - displayCount;

    return (
      <Stack gap={1} direction="row" flexWrap="wrap">
        {displayValues.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => {
              handleDeleteTimeFrame(value);
            }}
            deleteIcon={
              <CancelIcon onMouseDown={(event) => event.stopPropagation()} />
            }
          />
        ))}
        {moreCount > 0 ? (
          <Tooltip title={tooltipValues?.join(',')} placement="top">
            <Chip label={`+${moreCount} more...`} />
          </Tooltip>
        ) : null}
      </Stack>
    );
  };

  return (
    <div className="w-full">
      <div className="dark:border-dark_borderStroke ">
        <div className=" flex flex-col lg:flex-row  gap-[28px] ">
          <div className=" flex flex-col gap-[24px] md:w-[706px]">
            <div className="w-full">
              <div className="md:w-[358px] mb-[8px]">
                <p className="font-[#1F1F1F] font-bold text-sm leading-4 dark:text-dark_primary_text">
                  {
                    ApplicationString.vehicleStepper.step5Details.title
                      .slotTypes
                  }
                </p>
              </div>
              <div className="flex flex-col gap-4 text-secondary_text">
                <label
                  className="flex items-center rounded-[50px] border-[#D1D5DB] bg-[#F9FAFB] border  dark:bg-dark_bg_primary dark:border-none"
                  htmlFor="isHourly"
                >
                  <Checkbox
                    className="ml-3 h-[68px]"
                    icon={<CheckedBoxIcon />}
                    checkedIcon={<CheckedRadioIcon />}
                    checked={formData.step5.isHourly}
                    onChange={handleIsHourlyChange}
                    id="isHourly"
                    data-testid="isHourly-booking-checkbox"
                  />
                  <span className=" text-secondary_text dark:text-dark_secondary_text">
                    {ApplicationString.vehicleStepper.step5Details.hourly}
                  </span>
                </label>
                {formData.step5.isHourly && (
                  <>
                    <div className="flex flex-col items-center justify-start border-0 dark:border-none rounded-[50px] ">
                      <TextField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#D1D5DB',
                            },
                          },
                        }}
                        data-testid="input-addRate-Hourly"
                        label={
                          ApplicationString.vehicleStepper.step5Details.label
                            .setPrice
                        }
                        className="w-full rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        error={errorsData.step5.rateHourly.error}
                        InputProps={{
                          className: 'rounded-[50px] pl-5 h-15',
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              className="mr-[2px] ml-1 "
                            >
                              $
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          classes: {
                            root: 'left-1',
                          },
                        }}
                        value={
                          formData.step5.rateHourly !== null
                            ? formData.step5.rateHourly.toString()
                            : ''
                        }
                        onChange={handleRateHourlyChange}
                      />
                      {errorsData.step5.rateHourly.error && (
                        <FormHelperText
                          className="text-error"
                          sx={{ padding: '0px 10px', width: '100%' }}
                        >
                          {errorsData.step5.rateHourly.ValidationMessage}
                        </FormHelperText>
                      )}
                    </div>
                    <div className="flex gap-[8px]">
                      <InformationSvgIcon />

                      <p className="text-secondary_text text-sm leading-4 font-normal	dark:text-dark_secondary_text">
                        {
                          ApplicationString.vehicleStepper.step5Details.info
                            .hourly
                        }
                      </p>
                    </div>
                  </>
                )}
                <label
                  className="flex items-center rounded-[50px] bg-[#F9FAFB] border border-[#D1D5DB] dark:bg-dark_bg_primary dark:border-none"
                  htmlFor="is12Hourly"
                >
                  <Checkbox
                    className="ml-3 h-[68px]"
                    icon={<CheckedBoxIcon />}
                    checkedIcon={<CheckedRadioIcon />}
                    checked={formData.step5.is12Hourly}
                    onChange={handleIs12HourlyChange}
                    id="is12Hourly"
                    data-testid="is12Hourly-booking-checkbox"
                  />
                  <span className=" text-secondary_text dark:text-dark_secondary_text">
                    {ApplicationString.vehicleStepper.step5Details.twelveHourly}
                  </span>
                </label>
                {formData.step5.is12Hourly && (
                  <>
                    <FormControl>
                      <div className="flex items-center border-0 dark:border-none rounded-[50px]">
                        <TextField
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#D1D5DB',
                              },
                            },
                          }}
                          label={
                            ApplicationString.vehicleStepper.step5Details.label
                              .setPrice
                          }
                          className="w-full rounded-[50px] bg-[#F9FAFB] border-[#D1D5DB] dark:bg-dark_bg_primary dark:border-none"
                          error={errorsData.step5.rate12Hourly.error}
                          InputProps={{
                            className: 'rounded-[50px] pl-5 h-15',
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className="mr-[2px] ml-1 "
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: 'left-1',
                            },
                          }}
                          data-testid="input-addRate-12Hourly"
                          value={
                            formData.step5.rate12Hourly !== null
                              ? formData.step5.rate12Hourly.toString()
                              : ''
                          }
                          onChange={handleRate12HourlyChange}
                        />
                      </div>
                      {errorsData.step5.rate12Hourly.error && (
                        <FormHelperText className="text-error">
                          {errorsData.step5.rate12Hourly.ValidationMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <div>
                      <FormControl
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#D1D5DB',
                            },
                            '& .MuiSvgIcon-root': {
                              right: '11px',
                              fontSize: '2rem',
                            },
                          },
                        }}
                        fullWidth
                        variant="outlined"
                        className="rounded-[50px] h-[59px] bg-white border dark:bg-dark_bg_primary dark:border-none"
                      >
                        <InputLabel className=" ml-0 xl:ml-2 leading-normal text-secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details.label
                              .timeFrame
                          }
                        </InputLabel>
                        <Select
                          multiple
                          value={formData.step5.timeFrame || []}
                          onChange={handleTimeFrameChange}
                          open={multipleSelectProps.open}
                          onOpen={multipleSelectProps.onOpen}
                          onClose={multipleSelectProps.onClose}
                          MenuProps={{
                            disableScrollLock: true,
                            marginThreshold: 20,
                            PaperProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                          data-testid="12Hourly-timeFrame-select"
                          label={
                            ApplicationString.vehicleStepper.step5Details.label
                              .timeFrame
                          }
                          defaultValue={[
                            ApplicationString.vehicleStepper.step5Details.label
                              .timeFrame,
                          ]}
                          className="rounded-[50px] pl-1 h-[59px] bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-dark_secondary_text"
                          renderValue={handleSelectedValue}
                        >
                          {timeFrames.map((item) => (
                            <MenuItem value={item.label} key={item.label}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="flex gap-[8px]">
                      <InformationSvgIcon />

                      <p className="text-secondary_text text-sm leading-4 font-normal	dark:text-dark_secondary_text">
                        {
                          ApplicationString.vehicleStepper.step5Details.info
                            .twelveHourly
                        }
                      </p>
                    </div>
                  </>
                )}
                <label
                  className="flex items-center rounded-[50px] bg-[#F9FAFB]  border-[#D1D5DB] border dark:bg-dark_bg_primary dark:border-none"
                  htmlFor="is24Hourly"
                >
                  <Checkbox
                    className="ml-3 h-[68px]"
                    icon={<CheckedBoxIcon />}
                    checkedIcon={<CheckedRadioIcon />}
                    checked={formData.step5.is24Hourly}
                    onChange={handleIs24HourlyChange}
                    id="is24Hourly"
                    data-testid="is24Hourly-booking-checkbox"
                  />
                  <span className=" text-secondary_text dark:text-dark_secondary_text">
                    {ApplicationString.vehicleStepper.step5Details.daily}
                  </span>
                </label>
                {formData.step5.is24Hourly && (
                  <>
                    <FormControl>
                      <div className="flex items-center border dark:border-none rounded-[50px]">
                        <TextField
                          label={
                            ApplicationString.vehicleStepper.step5Details.label
                              .setPrice
                          }
                          className="w-full rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                          error={errorsData.step5.rate24Hourly.error}
                          InputProps={{
                            className: 'rounded-[50px] h-17',
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className="mr-[2px] ml-1 "
                              >
                                $
                              </InputAdornment>
                            ),
                          }}
                          data-testid="input-addRate-24Hourly"
                          InputLabelProps={{
                            classes: {
                              root: 'left-1',
                            },
                          }}
                          value={
                            formData.step5.rate24Hourly !== null
                              ? formData.step5.rate24Hourly.toString()
                              : ''
                          }
                          onChange={handleRate24HourlyChange}
                        />
                      </div>
                      {errorsData.step5.rate24Hourly.error && (
                        <FormHelperText className="text-error">
                          {errorsData.step5.rate24Hourly.ValidationMessage}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <div className="flex items-center border dark:border-none rounded-[50px]">
                      <TextField
                        label={
                          ApplicationString.vehicleStepper.step5Details.label
                            .noOfDays
                        }
                        className="w-full rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        InputProps={{
                          className: 'rounded-[50px] h-[60px]',
                        }}
                        InputLabelProps={{
                          classes: {
                            root: 'left-1',
                          },
                        }}
                        data-testid="input-add-maxDaysFor24Hourly"
                        error={errorsData.step5.maxDaysFor24Hourly.error}
                        value={
                          formData.step5.maxDaysFor24Hourly !== null
                            ? formData.step5.maxDaysFor24Hourly.toString()
                            : ''
                        }
                        onChange={handleMaxDaysFor24HourlyChange}
                      />
                    </div>
                    {errorsData.step5.maxDaysFor24Hourly.error && (
                      <FormHelperText className="text-error">
                        {errorsData.step5.maxDaysFor24Hourly.ValidationMessage}
                      </FormHelperText>
                    )}
                  </>
                )}
              </div>
              {errorsData.step5.slot.error && (
                <FormHelperText className="text-error">
                  {errorsData.step5.slot.ValidationMessage}
                </FormHelperText>
              )}
            </div>
            <div>
              <div className="flex h-[17px] mb-[8px] ">
                <p className="font-[#1F1F1F] font-bold text-sm dark:text-dark_primary_text leading-4">
                  {
                    ApplicationString.vehicleStepper.step5Details.title
                      .multipleBooking
                  }
                  <span className="text-[#FF0000]">*</span>
                </p>
              </div>
              <div>
                <RadioGroup
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    formData.step5.isMultipleBookingOnSameDay !== null
                      ? formData.step5.isMultipleBookingOnSameDay
                        ? 'yes'
                        : 'no'
                      : ''
                  }
                  className="flex flex-row justify-between mb-2 mt-6 xsm:mt-0"
                >
                  <label
                    className="flex items-center w-[49%] rounded-[50px] h-[68px] bg-[#F9FAFB] border-[#D1D5DB] border dark:bg-dark_bg_primary dark:border-none"
                    htmlFor="yes"
                  >
                    <Radio
                      className="ml-3"
                      icon={<CheckedBoxIcon />}
                      checkedIcon={<CheckedRadioIcon />}
                      onChange={handleIsMultipleBookingOnSameDayChange}
                      value="yes"
                      id="yes"
                      data-testid="isMultipleBookingOnSameDay-yes"
                    />

                    <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                      {ApplicationString.vehicleStepper.step5Details.yes}
                    </span>
                  </label>
                  <label
                    className="flex items-center w-[49%] rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                    htmlFor="no"
                  >
                    <Radio
                      className="ml-3"
                      icon={<CheckedBoxIcon />}
                      checkedIcon={<CheckedRadioIcon />}
                      onChange={handleIsMultipleBookingOnSameDayChange}
                      value="no"
                      id="no"
                      data-testid="isMultipleBookingOnSameDay-no"
                    />

                    <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                      {ApplicationString.vehicleStepper.step5Details.no}
                    </span>
                  </label>
                  {errorsData.step5.isMultipleBookingOnSameDay.error && (
                    <FormHelperText className="text-error">
                      {
                        errorsData.step5.isMultipleBookingOnSameDay
                          .ValidationMessage
                      }
                    </FormHelperText>
                  )}
                </RadioGroup>
              </div>
              <div className="flex gap-[8px]">
                <div>
                  <InformationSvgIcon />
                </div>

                <p className="text-secondary_text text-sm leading-4 font-normal	dark:text-dark_secondary_text">
                  {
                    ApplicationString.vehicleStepper.step5Details.info
                      .multipleBooking
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-3 md:gap-[20px] md:w-[708px]">
            <div className="flex flex-col gap-[8px] mb-0 xsm:mb-0">
              <div className=" h-[17px] ">
                <p className="font-[#1F1F1F] font-bold text-sm leading-4 dark:text-dark_primary_text">
                  {ApplicationString.vehicleStepper.step5Details.title.gap}
                  <span className="text-[#FF0000]">*</span>
                </p>
              </div>
              <FormControl>
                <div>
                  <RadioGroup
                    className="flex flex-col gap-[16px] justify-between mb-1 "
                    value={
                      // eslint-disable-next-line no-nested-ternary
                      formData.step5.gapHour !== null
                        ? formData.step5.gapHour
                        : ''
                    }
                  >
                    <div className="flex flex-col xsm:flex-row gap-4 xsm:gap-7">
                      <label
                        className="flex  items-center mt-4 xsm:mt-0 xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.oneHour}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.oneHour || ''}
                          id={timeFrameHourEnum.oneHour}
                          data-testid="gapHour1-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.one
                          }
                        </span>
                      </label>
                      <label
                        className="flex items-center xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.twoHours}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.twoHours || ''}
                          id={timeFrameHourEnum.twoHours}
                          data-testid="gapHour2-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.two
                          }
                        </span>
                      </label>
                    </div>
                    <div className="flex flex-col xsm:flex-row gap-4 xsm:gap-7">
                      <label
                        className="flex items-center xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.fourHours}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.fourHours || ''}
                          id={timeFrameHourEnum.fourHours}
                          data-testid="gapHour4-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.four
                          }
                        </span>
                      </label>
                      <label
                        className="flex items-center xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.eightHours}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.eightHours || ''}
                          id={timeFrameHourEnum.eightHours}
                          data-testid="gapHour8-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.eight
                          }
                        </span>
                      </label>
                    </div>
                    <div className="flex flex-col xsm:flex-row gap-4 xsm:gap-7 ">
                      <label
                        className="flex items-center xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.twelveHours}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.twelveHours || ''}
                          id={timeFrameHourEnum.twelveHours}
                          data-testid="gapHour12-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.twelve
                          }
                        </span>
                      </label>
                      <label
                        className="flex items-center xsm:w-1/2 rounded-[50px] h-[68px] border-[#D1D5DB] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                        htmlFor={timeFrameHourEnum.twentyFourHours}
                      >
                        <Radio
                          className="ml-3"
                          icon={<CheckedBoxIcon />}
                          checkedIcon={<CheckedRadioIcon />}
                          onChange={handleGapHourChange}
                          value={gapHoursValues.twentyFourHours || ''}
                          id={timeFrameHourEnum.twentyFourHours}
                          data-testid="gapHour24-booking-radio"
                        />
                        <span className="ml-0  text-secondary_text dark:text-dark_secondary_text">
                          {
                            ApplicationString.vehicleStepper.step5Details
                              .gapHours.twentyFour
                          }
                        </span>
                      </label>
                    </div>
                  </RadioGroup>
                </div>
                {errorsData.step5.gapHour.error && (
                  <FormHelperText className="text-error">
                    {errorsData.step5.gapHour.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div className="flex gap-[8px]">
                <div>
                  <InformationSvgIcon />
                </div>
                <div className="h-full">
                  <p className="text-sm text-secondary_text leading-4 font-normal	dark:text-dark_secondary_text">
                    {ApplicationString.vehicleStepper.step5Details.info.gap}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-[8px] sm:gap-2">
              <div>
                <FormControl
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#D1D5DB',
                      },
                      '& .MuiSvgIcon-root': {
                        right: '11px',
                        fontSize: '2rem',
                      },
                    },
                  }}
                  fullWidth
                  id="shortNoticePeriod"
                  data-testid="shortNoticePeriod-booking-formControl"
                  className="rounded-[50px] h-[59px] bg-white border border-gray-300 "
                >
                  <InputLabel className="ml-1  leading-normal text-secondary_text">
                    {ApplicationString.vehicleStepper.step5Details.noticePeriod}
                  </InputLabel>
                  <Select<number>
                    data-testid="shortNoticePeriod-booking-select"
                    id="shortNoticePeriod"
                    labelId="shortNoticePeriod"
                    value={
                      formData.step5.shortNoticePeriod !== null
                        ? formData.step5.shortNoticePeriod
                        : ''
                    }
                    onChange={handleShortNoticePeriodChange}
                    label={
                      ApplicationString.vehicleStepper.step5Details.label
                        .shortNoticePeriod
                    }
                    open={selectProps.open}
                    onOpen={selectProps.onOpen}
                    onClose={selectProps.onClose}
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-[50px] pl-2 h-[59px] bg-[#F9FAFB] dark:bg-dark_bg_primary dark:border-none text-gray-700 dark:text-dark_secondary_text"
                  >
                    {shortNoticePeriodLabels.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="flex gap-[8px] mb-2">
                <div>
                  <InformationSvgIcon />
                </div>
                <p className="text-secondary_text text-sm leading-4 font-normal	dark:text-dark_secondary_text">
                  {
                    ApplicationString.vehicleStepper.step5Details.info
                      .shortNoticePerios
                  }
                </p>
              </div>
              <FormControl>
                <div className="flex flex-col items-center justify-start border-0 dark:border-none rounded-[50px] ">
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    label={
                      ApplicationString.vehicleStepper.step5Details.label
                        .monthLimit
                    }
                    className="w-full h-15 rounded-[50px] bg-[#F9FAFB] border dark:bg-dark_bg_primary dark:border-none"
                    InputProps={{
                      className: 'rounded-[50px] h-15',
                    }}
                    InputLabelProps={{
                      shrink: !!formData.step5.monthLimit || isFocusState,
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    data-testid="input-booking-monthLimit"
                    error={errorsData.step5.monthLimit.error}
                    value={
                      formData.step5.monthLimit !== null
                        ? formData.step5.monthLimit.toString()
                        : ''
                    }
                    onFocus={() => setIsFocusState(true)}
                    onBlur={() => setIsFocusState(false)}
                    onChange={handleMonthLimitChange}
                  />
                </div>
                {errorsData.step5.monthLimit.error && (
                  <FormHelperText className="text-error">
                    {errorsData.step5.monthLimit.ValidationMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div className="flex gap-[8px] mb-2">
                <div>
                  <InformationSvgIcon />
                </div>
                <p className="text-secondary_text text-sm leading-4 font-normal	dark:text-dark_secondary_text">
                  {
                    ApplicationString.vehicleStepper.step5Details.info
                      .monthLimit
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsStep;
