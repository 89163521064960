import dayjs, { Dayjs } from 'dayjs';
import {
  Booking,
  BookingsDetails,
  DateRange,
  Slot12Hour,
} from '../Components/BookingDetails/bookingInterface';
import {
  addHoursFromDate,
  addOneDay,
  compareISODay,
  extractHour,
  formatDate,
  getDifferenceInDays,
  subtractHoursFromDate,
} from './utils';
import { TimeFrameFor12Hourly } from '../Interfaces/Stepper';
import { dayRange } from '../Constants/commonConst';

interface BlockedDates {
  ownerBlockedDate: Dayjs[];
  userBlockedDate: Dayjs[];
}

interface BlockedDateSlotMap {
  [date: string]: string[];
}
export const compareISOStartTime = (
  isoDate1: string,
  hourlyBookingStartHour: number
): boolean => {
  const date1 = new Date(isoDate1);

  const hours1 = date1.getUTCHours();
  const minutes1 = date1.getUTCMinutes();
  const seconds1 = date1.getUTCSeconds();

  return (
    hours1 < hourlyBookingStartHour ||
    (hours1 === hourlyBookingStartHour && minutes1 === 0 && seconds1 === 0)
  );
};
export const compareISOEndTime = (
  isoDate1: string,
  hourlyBookingEndHour: number
): boolean => {
  const date1 = new Date(isoDate1);
  const hours1 = date1.getUTCHours();
  return hours1 >= hourlyBookingEndHour;
};

const timeRangeToNumber = (timeRange: string): [number, number] => {
  return timeRange.split('-').map(Number) as [number, number];
};

const isIncludeRange = (
  timeRanges: string[],
  startRange: number,
  endRange: number
): boolean => {
  const intervals = timeRanges
    .map(timeRangeToNumber)
    .sort((a, b) => a[0] - b[0]);

  let currentEnd = startRange;

  // eslint-disable-next-line no-restricted-syntax
  for (const [start, end] of intervals) {
    if (start > currentEnd) {
      return false;
    }
    currentEnd = Math.max(currentEnd, end);
    if (currentEnd >= endRange) {
      return true;
    }
  }

  return currentEnd >= endRange;
};

const getBlockedDatesFromSlots = (
  data: BlockedDateSlotMap,
  startRange: number,
  endRange: number,
  blockedDateMap: Map<string, DateRange>
): void => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const times = data[key];
      if (isIncludeRange(times, startRange, endRange)) {
        blockedDateMap.set(key, {
          startDate: key,
          endDate: key,
        });
      }
    }
  }
};

export const formatSlots = (hour: number): string => {
  let slotHour = '';
  if (hour > 12) {
    slotHour = `${hour - 12 < 10 ? `0${hour - 12}` : hour - 12}:00 PM`;
  } else if (hour === 12) {
    slotHour = `${hour < 10 ? `0${hour}` : hour}:00 PM`;
  } else if (hour === 0) {
    slotHour = '12:00 AM';
  } else {
    slotHour = `${hour < 10 ? `0${hour}` : hour}:00 AM`;
  }
  return slotHour;
};
export const calculateSlots = (
  startDate: string,
  endDate: string,
  blockedSlots: Set<string>
): Set<string> => {
  // const blockedSlots:Set<string> = new Set(); // slot:

  let i;
  const startHour = extractHour(startDate);
  const endHour = extractHour(endDate);
  // console.log(startHour, endHour, 'Start and End Hour');
  i = startHour;
  // Add slots from start hour to end hour
  while (i < endHour) {
    const slotStartHour = formatSlots(i);
    const slotEndHour = formatSlots(i + 1);

    const blockedSlot = `${slotStartHour} to ${slotEndHour}`;
    blockedSlots.add(blockedSlot);

    i += 1;
  }
  return blockedSlots;
};
const calculate12HourBlockedSlots = (
  startDate: string,
  endDate: string,
  blockedSlots: Set<string>
): Set<string> => {
  // const blockedSlots:Set<string> = new Set(); // slot:

  let i;
  const startHour = extractHour(startDate);
  const endHour = extractHour(endDate);
  i = startHour;
  while (i <= endHour) {
    let hour = '';
    if (i > 12) {
      hour = i - 12 < 10 ? `0${i - 12}:00 PM` : `${i - 12}:00 PM`;
    } else if (i === 12) {
      hour = `${i}:00 PM`;
    } else if (i === 0) {
      hour = '12:00 AM';
    } else {
      hour = i < 10 ? `0${i}:00 AM` : `${i}:00 AM`;
    }
    blockedSlots.add(hour);
    i += 1;
  }
  return blockedSlots;
};
export const formatEndDate = (date: Date, endHour: number): string => {
  const year = date.getFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}T${String(endHour).padStart(2, '0')}:00:00.000Z`;
};

export const formatStartDate = (date: Date, startHour: number): string => {
  const year = date.getFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}T${String(startHour).padStart(2, '0')}:00:00.000Z`;
};

export const getDateWithoutTime = (isoString: string): Date => {
  const date = new Date(isoString);
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth();
  const day = date.getUTCDate();

  return new Date(Date.UTC(year, month, day));
};
// calculate hourly blocked slots
export const getBlockedSlots = (
  data: Booking[],
  date: string,
  hourlyBookingStartHour: number,
  hourlyBookingEndHour: number,
  bookingGap: number
): string[] => {
  const blockedSlots: Set<string> = new Set(); // slot:
  data.forEach((booking: Booking) => {
    if (booking.startDate && booking.endDate) {
      const bookingStartDate = subtractHoursFromDate(
        booking.startDate,
        bookingGap
      );
      const bookingEndDate = addHoursFromDate(booking.endDate, bookingGap);
      const startDate = getDateWithoutTime(bookingStartDate);
      const endDate = getDateWithoutTime(bookingEndDate);
      // check blocked slots is required for start date
      if (compareISODay(date, bookingStartDate)) {
        // if start date and end date are same
        if (compareISODay(bookingStartDate, bookingEndDate)) {
          calculateSlots(bookingStartDate, bookingEndDate, blockedSlots);
        }
        // if start date and end date are not same
        else {
          const newEndDate = formatEndDate(startDate, hourlyBookingEndHour);
          calculateSlots(bookingStartDate, newEndDate, blockedSlots);
        }
      }
      // check blocked slots is required for end date
      else if (compareISODay(date, bookingEndDate)) {
        const newStartDate = formatStartDate(endDate, hourlyBookingStartHour);
        calculateSlots(newStartDate, bookingEndDate, blockedSlots);
      }
    }
  });
  const currentDate = new Date();
  if (compareISODay(date, currentDate.toISOString())) {
    const currentStartDate = formatStartDate(currentDate, 0);
    const hour = currentDate.getHours();
    const currentEndDate = formatEndDate(currentDate, hour + 1);
    calculateSlots(currentStartDate, currentEndDate, blockedSlots);
  }
  return Array.from(blockedSlots);
};

export const createTimeLabelString = (timeLabel: Slot12Hour): string => {
  const { from, to } = timeLabel.value;
  const formattedFrom = formatSlots(from);
  const formattedTo = formatSlots(to);
  return `${formattedFrom} to ${formattedTo}`;
};
export const calculate12HourlySlots = (
  startDate: string,
  endDate: string,
  slots: Slot12Hour[],
  blocked12HourlySlots: Set<string>
): Set<string> => {
  slots.forEach((slot: Slot12Hour) => {
    const startHour = extractHour(startDate);
    const endHour = extractHour(endDate);
    // check if any booking slot or start time or end time is in setting slot's range.
    // check if setting slot is in booking slot range
    if (
      !(startHour === slot.value.to || endHour === slot.value.from) &&
      ((startHour <= slot.value.to && endHour >= slot.value.to) ||
        (startHour <= slot.value.from && endHour >= slot.value.from) ||
        (slot.value.from <= startHour && slot.value.to >= endHour))
    ) {
      const blockedSlot = createTimeLabelString(slot);
      blocked12HourlySlots.add(blockedSlot);
    }
  });
  return blocked12HourlySlots;
};

// calculate 12 hourly blocked slots
export const get12HourlyBlockedSlots = (
  slots: Slot12Hour[],
  bookingList: Booking[],
  date: string,
  hourlyBookingStartHour: number,
  hourlyBookingEndHour: number,
  bookingGap: number
): string[] => {
  const blocked12HourlySlots: Set<string> = new Set();
  bookingList.forEach((booking: Booking) => {
    if (booking.startDate && booking.endDate) {
      const bookingStartDate = subtractHoursFromDate(
        booking.startDate,
        bookingGap
      );
      const bookingEndDate = addHoursFromDate(booking.endDate, bookingGap);
      const startDate = getDateWithoutTime(bookingStartDate);
      const endDate = getDateWithoutTime(bookingEndDate);
      // check blocked slots is required for start date
      if (compareISODay(date, bookingStartDate)) {
        // if start date and end date are same
        if (compareISODay(bookingStartDate, bookingEndDate)) {
          calculate12HourlySlots(
            bookingStartDate,
            bookingEndDate,
            slots,
            blocked12HourlySlots
          );
        }
        // if start date and end date are not same
        else {
          const newEndDate = formatEndDate(startDate, hourlyBookingEndHour);
          calculate12HourlySlots(
            bookingStartDate,
            newEndDate,
            slots,
            blocked12HourlySlots
          );
        }
      }

      // check blocked slots is required for end date
      else if (compareISODay(date, bookingEndDate)) {
        const newStartDate = formatEndDate(endDate, hourlyBookingStartHour);
        calculate12HourlySlots(
          newStartDate,
          bookingEndDate,
          slots,
          blocked12HourlySlots
        );
      }
    }
  });
  const currentDate = new Date();
  if (compareISODay(date, currentDate.toISOString())) {
    const currentStartDate = formatStartDate(currentDate, 0);
    const hour = currentDate.getHours();
    const currentEndDate = formatEndDate(currentDate, hour + 1);
    calculate12HourlySlots(
      currentStartDate,
      currentEndDate,
      slots,
      blocked12HourlySlots
    );
  }
  return Array.from(blocked12HourlySlots);
};

function parseTimeTo24HourFormat(time: string): number {
  const [timePart, period] = time.split(' ');
  let [hours] = timePart.split(':').map(Number);

  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  return hours;
}

function parseRange(range: string): { from: number; to: number } {
  const [start, end] = range.split(' to ');
  return {
    from: parseTimeTo24HourFormat(start),
    to: parseTimeTo24HourFormat(end),
  };
}
function isSlotsIncludeInTimeFrame(
  slots: string[],
  timeFrames: { from: number; to: number }[]
): boolean {
  const parsedSlotList = slots.map(parseRange);

  // eslint-disable-next-line no-restricted-syntax
  for (const timeFrame of timeFrames) {
    let isCovered = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const slot of parsedSlotList) {
      if (slot.from <= timeFrame.from && slot.to >= timeFrame.to) {
        isCovered = true;
        break;
      }
    }

    if (!isCovered) {
      return false;
    }
  }

  return true;
}

// calculate blocked dates
export const getBlockedDates = (
  data: Booking[],
  hourlyBookingStartHour: number,
  hourlyBookingEndHour: number,
  gap: number,
  isMultipleBookingSameDay: boolean,
  slots: Slot12Hour[],
  rateHourly: number | null,
  timeFrameFor12Hourly: TimeFrameFor12Hourly[],
  isDateRangeBooking?: boolean
): DateRange[] => {
  const blockedDateMap = new Map<string, DateRange>();
  const blockedDateSlotMap: BlockedDateSlotMap = {};
  const datesFor12HourlySlots: Set<string> = new Set(); // slot:
  // const datesFor12HourlySlots: string[] = [];
  data.forEach((booking: Booking) => {
    if (booking.startDate && booking.endDate) {
      const startDate = subtractHoursFromDate(booking.startDate, gap);
      const endDate = addHoursFromDate(booking.endDate, gap);
      datesFor12HourlySlots.add(startDate);
      datesFor12HourlySlots.add(endDate);
      // check if booking is single day
      if (compareISODay(startDate, endDate)) {
        const formatedStartDate = formatDate(startDate);
        if (blockedDateSlotMap[formatedStartDate]) {
          blockedDateSlotMap[formatedStartDate].push(
            `${new Date(startDate).getUTCHours()}-${new Date(endDate).getUTCHours()}`
          );
        } else {
          blockedDateSlotMap[formatedStartDate] = [
            `${new Date(startDate).getUTCHours()}-${new Date(endDate).getUTCHours()}`,
          ];
        }
        // consider as blocked date if whole day is booked within the time range
        if (
          (compareISOStartTime(startDate, hourlyBookingStartHour) &&
            compareISOEndTime(endDate, hourlyBookingEndHour)) ||
          !isMultipleBookingSameDay ||
          (isDateRangeBooking && new Date(startDate).getUTCHours() !== 0)
        ) {
          blockedDateMap.set(formatDate(startDate), {
            startDate: formatDate(startDate),
            endDate: formatDate(startDate),
          });
        }
      }
      // check if booking is for multiple days
      else {
        const diffInDays = getDifferenceInDays(startDate, endDate);
        // check start date of multiple days booking is blocked or not
        if (
          compareISOStartTime(startDate, hourlyBookingStartHour) ||
          !isMultipleBookingSameDay ||
          (isDateRangeBooking && new Date(startDate).getUTCHours() !== 0)
        ) {
          blockedDateMap.set(formatDate(startDate), {
            startDate: formatDate(startDate),
            endDate: formatDate(startDate),
          });
        }
        // check end date of multiple days booking is blocked or not
        if (
          compareISOEndTime(endDate, hourlyBookingEndHour) ||
          !isMultipleBookingSameDay ||
          (isDateRangeBooking && new Date(endDate).getUTCHours() !== 0)
        ) {
          blockedDateMap.set(formatDate(endDate), {
            startDate: formatDate(endDate),
            endDate: formatDate(endDate),
          });
        }
        // added range between start date and end date as blocked date
        for (let i = 1; i < diffInDays; i += 1) {
          const date = addOneDay(startDate, i);
          const key = date;

          const val = {
            startDate: formatDate(date),
            endDate: formatDate(date),
          };

          if (!blockedDateMap.has(formatDate(date))) {
            blockedDateMap.set(key, val);
          }
        }
      }
    }
  });
  if (rateHourly)
    getBlockedDatesFromSlots(
      blockedDateSlotMap,
      hourlyBookingStartHour,
      hourlyBookingEndHour,
      blockedDateMap
    );
  else {
    // eslint-disable-next-line no-restricted-syntax
    for (const item of Array.from(datesFor12HourlySlots.values())) {
      const slotFor12Hourly = get12HourlyBlockedSlots(
        slots,
        data,
        item,
        dayRange.startHour,
        dayRange.endHour,
        gap
      );

      if (isSlotsIncludeInTimeFrame(slotFor12Hourly, timeFrameFor12Hourly))
        blockedDateMap.set(formatDate(item), {
          startDate: formatDate(item),
          endDate: formatDate(item),
        });
    }
  }
  return Array.from(blockedDateMap.values());
};

export const getSlotForMultipleDay = (
  data: Booking[],
  date: string,
  hourlyBookingStartHour: number,
  hourlyBookingEndHour: number,
  bookingGap: number
): string[] => {
  const blockedSlots: Set<string> = new Set(); // slot:
  data.forEach((booking: Booking) => {
    if (booking.startDate && booking.endDate) {
      const startDate = new Date(booking.startDate);
      const endDate = new Date(booking.endDate);
      const bookingStartDate = subtractHoursFromDate(
        booking.startDate,
        bookingGap
      );
      const bookingEndDate = addHoursFromDate(booking.endDate, bookingGap);
      if (compareISODay(date, bookingStartDate)) {
        if (compareISODay(bookingStartDate, bookingEndDate)) {
          calculate12HourBlockedSlots(
            bookingStartDate,
            bookingEndDate,
            blockedSlots
          );
        } else {
          // const newEndDate =`${startDate.getFullYear()}-0${startDate.getUTCMonth()+1}-${startDate.getDate()}T${hourlyBookingEndHour}:00:00.000Z`;
          const newEndDate = formatEndDate(startDate, hourlyBookingEndHour);
          calculate12HourBlockedSlots(
            bookingStartDate,
            newEndDate,
            blockedSlots
          );
        }
      } else if (compareISODay(date, bookingEndDate)) {
        // const newStartDate =`${endDate.getFullYear()}-0${endDate.getMonth()+1}-${endDate.getDate()}T0${hourlyBookingStartHour}:00:00.000Z`;
        const newStartDate = formatStartDate(endDate, hourlyBookingStartHour);
        calculate12HourBlockedSlots(newStartDate, bookingEndDate, blockedSlots);
      }
    }
  });
  const currentDate = new Date();
  if (compareISODay(date, currentDate.toISOString())) {
    const currentStartDate = formatStartDate(currentDate, 0);
    const hour = currentDate.getHours();
    const currentEndDate = formatEndDate(currentDate, hour);
    calculate12HourBlockedSlots(currentStartDate, currentEndDate, blockedSlots);
  }
  return Array.from(blockedSlots.values());
};

export const getBlockedDatesForOwnerCalendar = (
  data: BookingsDetails[]
): BlockedDates => {
  const blockedDate: BlockedDates = {
    ownerBlockedDate: [],
    userBlockedDate: [],
  };
  const ownerBlockedDateSet: Set<Dayjs> = new Set();
  const userBlockedDateSet: Set<Dayjs> = new Set();
  data.forEach((booking: BookingsDetails) => {
    if (booking.startDate && booking.endDate) {
      if (compareISODay(booking.startDate, booking.endDate)) {
        if (
          booking.renterDetails.id === booking.ownerDetails.id &&
          booking.total === 0
        ) {
          ownerBlockedDateSet.add(dayjs(booking.startDate));
        } else {
          const dateString = booking.startDate;
          const localDateString = dateString.replace('Z', '');
          const bookingDate = dayjs(localDateString);
          userBlockedDateSet.add(bookingDate);
        }
      } else if (
        booking.renterDetails.id === booking.ownerDetails.id &&
        booking?.total === 0
      ) {
        ownerBlockedDateSet.add(dayjs(booking.startDate));
        ownerBlockedDateSet.add(dayjs(booking.endDate));
      } else {
        userBlockedDateSet.add(dayjs(booking.startDate));
        userBlockedDateSet.add(dayjs(booking.endDate));
      }
      const diffInDays = getDifferenceInDays(
        booking.startDate,
        booking.endDate
      );
      for (let i = 1; i < diffInDays; i += 1) {
        const date = addOneDay(booking.startDate, i);
        if (
          booking.renterDetails.id === booking.ownerDetails.id &&
          booking.total === 0
        ) {
          ownerBlockedDateSet.add(dayjs(date));
        } else {
          userBlockedDateSet.add(dayjs(date));
        }
      }
    }
  });
  blockedDate.ownerBlockedDate = Array.from(ownerBlockedDateSet.values());
  blockedDate.userBlockedDate = Array.from(userBlockedDateSet.values());
  return blockedDate;
};
