/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { localStorageEnums } from '../../../Utils/enums';

import {
  checkIfArrayIsTruthyOrNot,
  createSelectOptions,
  debounce,
  getLocalStorageAccountInfo,
  getMaxCharacterRegex,
} from '../../../Utils/utils';
import ApplicationString from '../../../Constants/applicationString';
import { ICommonStepsProps } from '../../../Interfaces/Stepper';
import ColorModeContext from '../../../Utils/ColorModeContext';
import { CheckedBoxIcon, CheckedRadioIcon } from '../../../Assets/Svgs';
import { RequiredSelectLabel, RequiredTextFieldLabel } from '../label';
import {
  ICountry,
  IState,
  IUserSettingsData,
} from '../../../Interfaces/Settings/SettingsInterfaces';
import useSelectOpenClose from '../../../Hooks/useSelectOpenClose';
import {
  getLatLongFromAddress,
  getPlaceDetailsById,
  loadGooglePlaces,
} from '../../../Services/location.service';

interface FormValues {
  step2: {
    country: string;
    pickUp: {
      latLong: string;
      address1: string;
      address2: string;
      state: string;
      zipCode: string;
      city: string;
    };
    dropOff: {
      dropLatLong: string;
      address1: string;
      address2: string;
      state: string;
      zipCode: string;
      city: string;
    };
    isSameAddress: boolean | undefined;
  };
}

interface IAddressDetails {
  country: string;
  latLon: string;
  city: string;
  state: string;
  zipCode: string;
}
const LocationDetailsStepperComponent: React.FC<ICommonStepsProps> = ({
  formData,
  setFormData,
  errorsData,
  setErrorsData,
}) => {
  const initialFormValues: FormValues = {
    step2: {
      country: '',
      pickUp: {
        latLong: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      dropOff: {
        dropLatLong: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
      },
      isSameAddress: false,
    },
  };
  const [sameAsPickUp, setSameAsPickUp] = useState<boolean>(
    formData.step2.isSameAddress || false
  );
  const [pickUpSuggestions, setPickUpSuggestions] = React.useState<string[]>(
    []
  );
  const [dropOffSuggestions, setDropOffSuggestions] = React.useState<string[]>(
    []
  );
  const [pickUpInputValue, setPickUpInputValue] = React.useState('');
  const [dropOffInputValue, setDropOffInputValue] = React.useState('');
  const selectCountriesProps = useSelectOpenClose(true);
  const selectStatePickupProps = useSelectOpenClose(true);
  const selectStateDropOffProps = useSelectOpenClose(true);
  const [autoFilledFields, setAutoFilledFields] = useState<{
    country: boolean;
    pickUp: {
      city: boolean;
      state: boolean;
      zipCode: boolean;
    };
    dropOff: {
      city: boolean;
      state: boolean;
      zipCode: boolean;
    };
  }>({
    country: false,
    pickUp: {
      city: false,
      state: false,
      zipCode: false,
    },
    dropOff: {
      city: false,
      state: false,
      zipCode: false,
    },
  });

  const userSettingsData = getLocalStorageAccountInfo<IUserSettingsData>(
    localStorageEnums.settings
  )?.setting;
  const countriesData: ICountry[] = checkIfArrayIsTruthyOrNot<ICountry[]>(
    userSettingsData?.country
  );
  const countriesDataOptions = createSelectOptions<
    ICountry,
    ICountry[],
    'code',
    'name'
  >(countriesData, 'code', 'name');

  const selectedCountryData = countriesData.filter(
    (country) => country.name === formData.step2.country
  );
  const statesData = selectedCountryData.flatMap((country) =>
    checkIfArrayIsTruthyOrNot<IState[]>(country.state)
  );
  const statesDataOptions = createSelectOptions<
    IState,
    IState[],
    'code',
    'name'
  >(statesData, 'code', 'name');

  const { currentTheme } = useContext(ColorModeContext);
  const maxStringLimit = 255;
  const stringRegex = getMaxCharacterRegex(maxStringLimit);

  const handleCountryChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value) {
      setErrorsData((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          country: {
            ValidationMessage: '',
            error: false,
          },
        },
      }));
    }
    setFormData((prevValues) => ({
      ...prevValues,
      step2: {
        ...prevValues.step2,
        country: event.target.value,
      },
    }));
  };
  const handleSelectChange =
    (
      field:
        | keyof FormValues['step2']['pickUp']
        | keyof FormValues['step2']['dropOff'],
      section: 'pickUp' | 'dropOff'
    ) =>
    (event: SelectChangeEvent<string>) => {
      const value = event.target.value as string;

      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          [section]: {
            ...prevValues.step2[section],
            [field]: value,
          },
          ...(sameAsPickUp && section === 'pickUp'
            ? { dropOff: { ...prevValues.step2.pickUp, [field]: value } }
            : {}),
        },
      }));
      setErrorsData((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          [section]: {
            ...prevState.step2[section],
            [field]: {
              ValidationMessage: '',
              error: false,
            },
          },
        },
      }));
      if (formData.step2.isSameAddress) {
        setErrorsData((prevState) => ({
          ...prevState,
          step2: {
            ...prevState.step2,
            dropOff: {
              ...prevState.step2.dropOff,
              [field]: {
                ValidationMessage: '',
                error: false,
              },
            },
          },
        }));
      }
    };

  const handleStateClick = (section: 'pickUp' | 'dropOff') => {
    if (formData.step2.country === '') {
      setErrorsData((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          [section]: {
            ...prevState.step2[section],
            state: {
              ValidationMessage:
                ApplicationString.stepperErrorsData.step2.countryValidation,
              error: true,
            },
          },
        },
      }));
    }
  };
  const handleInputChange =
    (
      field:
        | keyof FormValues['step2']['pickUp']
        | keyof FormValues['step2']['dropOff'],
      section: 'pickUp' | 'dropOff'
    ) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          [section]: {
            ...prevValues.step2[section],
            [field]: value,
          },
          ...(sameAsPickUp && section === 'pickUp'
            ? { dropOff: { ...prevValues.step2.pickUp, [field]: value } }
            : {}),
        },
      }));
      const isLimit =
        value && value.length > maxStringLimit && !stringRegex.test(value);
      if (isLimit) {
        setErrorsData((prevState) => ({
          ...prevState,
          step2: {
            ...prevState.step2,
            [section]: {
              ...prevState.step2[section],
              [field]: {
                ValidationMessage:
                  ApplicationString.stepperErrorsData.step1.maxCharValidation,
                error: true,
              },
            },
          },
        }));
        if (formData.step2.isSameAddress) {
          setErrorsData((prevState) => ({
            ...prevState,
            step2: {
              ...prevState.step2,
              dropOff: {
                ...prevState.step2.dropOff,
                [field]: {
                  ValidationMessage:
                    ApplicationString.stepperErrorsData.step1.maxCharValidation,
                  error: true,
                },
              },
            },
          }));
        }
      } else {
        setErrorsData((prevState) => ({
          ...prevState,
          step2: {
            ...prevState.step2,
            [section]: {
              ...prevState.step2[section],
              [field]: {
                ValidationMessage: '',
                error: false,
              },
            },
          },
        }));
      }
      if (formData.step2.isSameAddress && !isLimit) {
        setErrorsData((prevState) => ({
          ...prevState,
          step2: {
            ...prevState.step2,
            dropOff: {
              ...prevState.step2.dropOff,
              [field]: {
                ValidationMessage: '',
                error: false,
              },
            },
          },
        }));
      }
    };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSameAsPickUp(event.target.checked);
    setFormData((prevValues) => ({
      ...prevValues,
      step2: {
        ...prevValues.step2,
        isSameAddress: event.target.checked,
      },
    }));
    if (event.target.checked) {
      setErrorsData((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          dropOff: {
            ...prevState.step2.dropOff,
            address1: {
              ValidationMessage: prevState.step2.dropOff.address1
                ? ''
                : errorsData.step2.dropOff.address1.ValidationMessage,
              error: prevState.step2.dropOff.address1
                ? false
                : errorsData.step2.dropOff.address1.error,
            },
            address2: {
              ValidationMessage: prevState.step2.dropOff.address2
                ? ''
                : errorsData.step2.dropOff.address2.ValidationMessage,
              error: prevState.step2.dropOff.address2
                ? false
                : errorsData.step2.dropOff.address2.error,
            },
            city: {
              ValidationMessage: prevState.step2.dropOff.city
                ? ''
                : errorsData.step2.dropOff.city.ValidationMessage,
              error: prevState.step2.dropOff.city
                ? false
                : errorsData.step2.dropOff.city.error,
            },
            state: {
              ValidationMessage: prevState.step2.dropOff.state
                ? ''
                : errorsData.step2.dropOff.state.ValidationMessage,
              error: prevState.step2.dropOff.state
                ? false
                : errorsData.step2.dropOff.state.error,
            },
            zipCode: {
              ValidationMessage: prevState.step2.dropOff.zipCode
                ? ''
                : errorsData.step2.dropOff.zipCode.ValidationMessage,
              error: prevState.step2.dropOff.zipCode
                ? false
                : errorsData.step2.dropOff.zipCode.error,
            },
          },
        },
      }));
      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          dropOff: { ...prevValues.step2.pickUp },
        },
      }));
    } else {
      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          dropOff: initialFormValues.step2.dropOff,
        },
      }));
    }

    if (!event.target.checked) {
      setDropOffInputValue('');
      setDropOffSuggestions([]);
    } else {
      setDropOffInputValue(formData.step2.pickUp.address1);
    }
  };

  const handleSuggestionSelect = async (
    event: React.SyntheticEvent,
    newValue: any,
    section: 'pickUp' | 'dropOff'
  ) => {
    if (newValue && newValue.place_id) {
      const details = await getPlaceDetailsById(
        newValue.place_id,
        'address_component,geometry'
      );
      if (typeof details === 'object' && details !== null) {
        const detailsObject: IAddressDetails = details;

        setFormData((prevValues) => ({
          ...prevValues,
          step2: {
            ...prevValues.step2,
            country: detailsObject?.country || prevValues.step2.country,
            [section]: {
              ...prevValues.step2[section],
              address1: newValue.description.replace(/\s+/g, ' '),
              city: detailsObject?.city || prevValues.step2[section].city,
              state: detailsObject?.state || prevValues.step2[section].state,
              zipCode:
                detailsObject?.zipCode || prevValues.step2[section].zipCode,
              latLong:
                detailsObject?.latLon || prevValues.step2[section].latLong,
              dropLatLong:
                detailsObject?.latLon || prevValues.step2[section].latLong,
            },
          },
        }));

        setAutoFilledFields((prev) => ({
          ...prev,
          country: !!detailsObject?.country,
          [section]: {
            city: !!detailsObject?.city,
            state: !!detailsObject?.state,
            zipCode: !!detailsObject?.zipCode,
          },
        }));
      }

      if (section === 'pickUp') {
        setPickUpInputValue(newValue.description);
      } else {
        setDropOffInputValue(newValue.description);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestions = useCallback(
    debounce(async (value: string, section: 'pickUp' | 'dropOff') => {
      if (value) {
        const suggestions: any = await loadGooglePlaces(value);
        const predictedPlaces = suggestions.predictions;
        if (suggestions) {
          if (section === 'pickUp') {
            setPickUpSuggestions(predictedPlaces);
          } else {
            setDropOffSuggestions(predictedPlaces);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (section === 'pickUp') {
          setPickUpSuggestions([]);
        } else {
          setDropOffSuggestions([]);
        }
      }
    }, 300),
    []
  );

  const handlePickupSearchInputChange = async (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (reason === 'input') {
      setPickUpInputValue(value);
      debouncedFetchSuggestions(value, 'pickUp');
      setErrorsData((prevState) => ({
        ...prevState,
        step2: {
          ...prevState.step2,
          country: {
            ValidationMessage: '',
            error: false,
          },
          pickUp: {
            ...prevState.step2.pickUp,
            address1: {
              ValidationMessage: '',
              error: false,
            },
            city: {
              ValidationMessage: '',
              error: false,
            },
            state: {
              ValidationMessage: '',
              error: false,
            },
            zipCode: {
              ValidationMessage: '',
              error: false,
            },
          },
        },
      }));
      setSameAsPickUp(false);
    }
    if (reason === 'clear' || value === '') {
      setPickUpInputValue('');
      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          country: sameAsPickUp
            ? initialFormValues.step2.country
            : prevValues.step2.country,
          pickUp: initialFormValues.step2.pickUp,
          dropOff: sameAsPickUp
            ? initialFormValues.step2.dropOff
            : prevValues.step2.dropOff,
          isSameAddress: initialFormValues.step2.isSameAddress,
        },
      }));
      setPickUpSuggestions([]);
      setAutoFilledFields((prev) => ({
        ...prev,
        country: false,
        pickUp: {
          city: false,
          state: false,
          zipCode: false,
        },
      }));
    }
  };

  const handleDropOffSearchInputChange = async (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    setDropOffInputValue(value);
    if (reason === 'input') {
      handleSuggestionSelect(event, value, 'dropOff');
      if (reason === 'input') {
        setDropOffInputValue(value);
        debouncedFetchSuggestions(value, 'dropOff');
      }
    }
    if (reason === 'clear' || value === '') {
      setDropOffInputValue('');
      setDropOffSuggestions([]);
      setFormData((prevValues) => ({
        ...prevValues,
        step2: {
          ...prevValues.step2,
          country: sameAsPickUp
            ? initialFormValues.step2.country
            : prevValues.step2.country,
          dropOff: initialFormValues.step2.dropOff,
        },
      }));

      setAutoFilledFields((prev) => ({
        ...prev,
        country: false,
        dropOff: {
          city: false,
          state: false,
          zipCode: false,
        },
      }));
    }
  };
  useEffect(() => {
    setPickUpInputValue(formData.step2.pickUp.address1);
    setDropOffInputValue(formData.step2.dropOff.address1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.step2.pickUp.address1]);

  const pickUpTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (pickUpTimeoutRef.current) {
      clearTimeout(pickUpTimeoutRef.current);
    }

    pickUpTimeoutRef.current = setTimeout(() => {
      if (
        pickUpInputValue &&
        (pickUpSuggestions.length === 0 ||
          !pickUpSuggestions.some((suggestion: any) =>
            suggestion.description
              .toLowerCase()
              .includes(pickUpInputValue.toLowerCase())
          ))
      ) {
        getLatLongFromAddress(pickUpInputValue.trim(), 'stepperInput').then(
          (details) => {
            if (typeof details === 'object' && details !== null) {
              setFormData((prevValues) => ({
                ...prevValues,
                step2: {
                  ...prevValues.step2,
                  pickUp: {
                    ...prevValues.step2.pickUp,
                    address1: pickUpInputValue.trim(),
                    latLong:
                      details?.latLong || prevValues.step2.pickUp.latLong,
                    dropLatLong:
                      details?.latLong || prevValues.step2.pickUp.latLong,
                  },
                },
              }));
            }
          }
        );
      }
    }, 500);

    return () => {
      if (pickUpTimeoutRef.current) {
        clearTimeout(pickUpTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickUpInputValue, pickUpSuggestions]);

  const dropOffTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (dropOffTimeoutRef.current) {
      clearTimeout(dropOffTimeoutRef.current);
    }

    dropOffTimeoutRef.current = setTimeout(() => {
      if (
        dropOffInputValue &&
        (dropOffSuggestions.length === 0 ||
          !dropOffSuggestions.some((suggestion: any) =>
            suggestion.description
              .toLowerCase()
              .includes(dropOffInputValue.toLowerCase())
          ))
      ) {
        getLatLongFromAddress(dropOffInputValue.trim(), 'stepperInput').then(
          (details) => {
            if (typeof details === 'object' && details !== null) {
              setFormData((prevValues) => ({
                ...prevValues,
                step2: {
                  ...prevValues.step2,
                  dropOff: {
                    ...prevValues.step2.dropOff,
                    address1: dropOffInputValue.trim(),
                    latLong:
                      details?.latLong || prevValues.step2.dropOff.latLong,
                    dropLatLong:
                      details?.latLong || prevValues.step2.pickUp.latLong,
                  },
                },
              }));
            }
          }
        );
      }
    }, 500);

    return () => {
      if (dropOffTimeoutRef.current) {
        clearTimeout(dropOffTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropOffInputValue, dropOffSuggestions]);

  return (
    <div className="w-full">
      <div className="w-full flex flex-col justify-center gap-y-4">
        <div className="flex flex-col md:flex-row gap-8 justify-between">
          <div className="md:w-1/2 flex flex-col gap-3">
            <Typography className="text-sm font-bold py-[4px]">
              {
                ApplicationString.LocationDetailsStepperComponent.titleTexts
                  .pickUp
              }
            </Typography>
            <div className="flex flex-col w-full gap-y-5">
              <div className="w-full">
                <FormControl className="w-full">
                  <Autocomplete
                    freeSolo
                    sx={{ width: '100%', border: 'transparent' }}
                    options={pickUpSuggestions}
                    clearOnEscape
                    inputValue={pickUpInputValue}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    getOptionLabel={(option: any) => option.description || ''}
                    onInputChange={handlePickupSearchInputChange}
                    onChange={(event, newValue) =>
                      handleSuggestionSelect(event, newValue, 'pickUp')
                    }
                    renderInput={(params) => (
                      <TextField
                        label={
                          <RequiredTextFieldLabel
                            text={
                              ApplicationString.LocationDetailsStepperComponent
                                .fieldLabels.address1
                            }
                          />
                        }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        data-testid="input-address1-pickUp"
                        variant="outlined"
                        sx={{
                          '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#D1D5DB',
                            },
                          },
                        }}
                        className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg dark:text-white text-secondary_text font-base"
                        InputProps={{
                          ...params.InputProps,
                          className: 'rounded-[50px] pl-1 h-15',
                        }}
                        InputLabelProps={{
                          className: 'leading-normal text-secondary_text',
                          classes: {
                            root: 'left-1',
                          },
                        }}
                        error={errorsData.step2.pickUp.address1.error}
                        value={pickUpInputValue}
                      />
                    )}
                  />
                  {errorsData.step2.pickUp.address1.error && (
                    <FormHelperText
                      className="text-error"
                      sx={{ margin: '0px 14px' }}
                    >
                      {errorsData.step2.pickUp.address1.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl className="w-full">
                  <TextField
                    label={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.address2
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    data-testid="input-address2-pickUp"
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    value={formData.step2.pickUp.address2}
                    onChange={handleInputChange('address2', 'pickUp')}
                  />
                </FormControl>
                {errorsData.step2.pickUp.address2.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.pickUp.address2.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl className="w-full">
                  <TextField
                    label={
                      <RequiredTextFieldLabel
                        text={
                          ApplicationString.LocationDetailsStepperComponent
                            .fieldLabels.city
                        }
                      />
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    data-testid="input-city-pickUp"
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    disabled={autoFilledFields.pickUp.city}
                    error={errorsData.step2.pickUp.city.error}
                    value={formData.step2.pickUp.city}
                    onChange={handleInputChange('city', 'pickUp')}
                  />
                </FormControl>
                {errorsData.step2.pickUp.city.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.pickUp.city.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl
                  error={errorsData.step2.pickUp.state.error}
                  className="w-full"
                >
                  <RequiredSelectLabel
                    className="ml-1 leading-normal text-secondary_text"
                    id="demo-simple-select-label"
                    text={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.state
                    }
                  />

                  <Select
                    data-testid="select-pickUp-dropOff"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.state
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                    }}
                    open={selectStatePickupProps.open}
                    onOpen={selectStatePickupProps.onOpen}
                    onClose={selectStatePickupProps.onClose}
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    className="rounded-full pl-1 bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    disabled={
                      formData.step2.country === '' ||
                      autoFilledFields.pickUp.state
                    }
                    onClick={() => {
                      handleStateClick('pickUp');
                    }}
                    value={formData.step2.pickUp.state}
                    onChange={handleSelectChange('state', 'pickUp')}
                  >
                    {statesDataOptions &&
                      statesDataOptions.map((state) => {
                        return (
                          <MenuItem key={state.value} value={state.label}>
                            {state.label}
                          </MenuItem>
                        );
                      })}
                    {formData.step2.pickUp.state &&
                      formData.step2.pickUp.state !== '' &&
                      !statesDataOptions.some(
                        (option) => option.label === formData.step2.pickUp.state
                      ) && (
                        <MenuItem
                          key={formData.step2.pickUp.state}
                          value={formData.step2.pickUp.state}
                        >
                          {formData.step2.pickUp.state}
                        </MenuItem>
                      )}
                  </Select>
                  {errorsData.step2.pickUp.state.error && (
                    <FormHelperText
                      className="text-error"
                      sx={{ margin: '0px 14px' }}
                    >
                      {errorsData.step2.pickUp.state.ValidationMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="w-full">
                <FormControl className="w-full">
                  <TextField
                    label={
                      <RequiredTextFieldLabel
                        text={
                          ApplicationString.LocationDetailsStepperComponent
                            .fieldLabels.zipCode
                        }
                      />
                    }
                    data-testid="input-zipCode-pickUp"
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    disabled={autoFilledFields.pickUp.zipCode}
                    error={errorsData.step2.pickUp.zipCode.error}
                    value={formData.step2.pickUp.zipCode}
                    onChange={handleInputChange('zipCode', 'pickUp')}
                  />
                </FormControl>
                {errorsData.step2.pickUp.zipCode.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {' '}
                    {errorsData.step2.pickUp.zipCode.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
          <div className="md:w-1/2 flex flex-col gap-3">
            <FormControlLabel
              control={
                <Checkbox
                  className="py-0 rounded-lg"
                  icon={<CheckedBoxIcon />}
                  checkedIcon={<CheckedRadioIcon />}
                  sx={{ color: '#1A56DB' }}
                  inputProps={{
                    className: 'rounded',
                  }}
                  checked={formData.step2.isSameAddress}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              }
              label={
                <Typography className="text-sm font-bold">
                  {
                    ApplicationString.LocationDetailsStepperComponent.titleTexts
                      .dropOff
                  }
                </Typography>
              }
            />
            <div className="flex flex-col w-full gap-y-5">
              <div className="w-full">
                <FormControl
                  className="w-full"
                  error={errorsData.step2.dropOff.address1.error}
                >
                  <Autocomplete
                    freeSolo
                    sx={{ width: '100%', border: 'transparent' }}
                    options={dropOffSuggestions}
                    clearOnEscape
                    inputValue={dropOffInputValue}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    getOptionLabel={(option: any) => option.description || ''}
                    onInputChange={(event, newInputValue, reason) => {
                      if (!formData.step2.isSameAddress) {
                        handleDropOffSearchInputChange(
                          event,
                          newInputValue,
                          reason
                        );
                      }
                    }}
                    onChange={(event, newValue) =>
                      handleSuggestionSelect(event, newValue, 'dropOff')
                    }
                    renderInput={(params) => (
                      <TextField
                        label={
                          <RequiredTextFieldLabel
                            text={
                              ApplicationString.LocationDetailsStepperComponent
                                .fieldLabels.address1
                            }
                          />
                        }
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        data-testid="input-address1-dropOff"
                        variant="outlined"
                        disabled={formData.step2.isSameAddress}
                        sx={{
                          '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#D1D5DB',
                            },
                          },
                        }}
                        className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg dark:text-white text-secondary_text font-base"
                        InputProps={{
                          ...params.InputProps,
                          className: 'rounded-[50px] pl-1 h-15',
                          readOnly: formData.step2.isSameAddress,
                          endAdornment: formData.step2.isSameAddress
                            ? null
                            : params.InputProps.endAdornment,
                        }}
                        InputLabelProps={{
                          className: 'leading-normal text-secondary_text',
                          classes: {
                            root: 'left-1',
                          },
                        }}
                        error={errorsData.step2.dropOff.address1.error}
                        value={dropOffInputValue}
                      />
                    )}
                  />
                </FormControl>
                {errorsData.step2.dropOff.address1.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.dropOff.address1.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl className="w-full">
                  <TextField
                    label={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.address2
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    data-testid="input-address2-dropOff"
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    value={formData.step2.dropOff.address2}
                    disabled={formData.step2.isSameAddress}
                    onChange={handleInputChange('address2', 'dropOff')}
                  />
                </FormControl>
                {errorsData.step2.dropOff.address2.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.dropOff.address2.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl
                  className="w-full"
                  // error={errorsData.step2.dropOff.city.error}
                >
                  <TextField
                    label={
                      <RequiredTextFieldLabel
                        text={
                          ApplicationString.LocationDetailsStepperComponent
                            .fieldLabels.city
                        }
                        color={
                          errorsData.step2.dropOff.city.error
                            ? '#F30C0C'
                            : '#6B7280'
                        }
                      />
                    }
                    data-testid="input-city-dropOff"
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    error={errorsData.step2.dropOff.city.error}
                    value={formData.step2.dropOff.city}
                    disabled={
                      formData.step2.isSameAddress ||
                      autoFilledFields.dropOff.city
                    }
                    onChange={handleInputChange('city', 'dropOff')}
                  />
                </FormControl>
                {errorsData.step2.dropOff.city.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.dropOff.city.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl
                  className="w-full"
                  error={errorsData.step2.dropOff.state.error}
                >
                  <RequiredSelectLabel
                    className="ml-1 leading-normal text-secondary_text"
                    id="demo-simple-select-label"
                    text={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.state
                    }
                  />
                  <Select
                    data-testid="select-state-dropOff"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={
                      ApplicationString.LocationDetailsStepperComponent
                        .fieldLabels.state
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                    }}
                    open={selectStateDropOffProps.open}
                    onOpen={selectStateDropOffProps.onOpen}
                    onClose={selectStateDropOffProps.onClose}
                    MenuProps={{
                      disableScrollLock: true,
                      marginThreshold: 20,
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                        },
                      },
                    }}
                    disabled={
                      !formData.step2.country || formData.step2.isSameAddress
                    }
                    onClick={() => {
                      handleStateClick('dropOff');
                    }}
                    className="rounded-full pl-1 bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    value={formData.step2.dropOff.state}
                    onChange={handleSelectChange('state', 'dropOff')}
                  >
                    {statesDataOptions &&
                      statesDataOptions.map((state) => {
                        return (
                          <MenuItem key={state.value} value={state.label}>
                            {state.label}
                          </MenuItem>
                        );
                      })}
                    {formData.step2.dropOff.state &&
                      formData.step2.dropOff.state !== '' &&
                      !statesDataOptions.some(
                        (option) =>
                          option.label === formData.step2.dropOff.state
                      ) && (
                        <MenuItem
                          key={formData.step2.dropOff.state}
                          value={formData.step2.dropOff.state}
                        >
                          {formData.step2.dropOff.state}
                        </MenuItem>
                      )}
                  </Select>
                </FormControl>
                {errorsData.step2.dropOff.state.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.dropOff.state.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
              <div className="w-full">
                <FormControl
                  className="w-full"
                  error={errorsData.step2.dropOff.zipCode.error}
                >
                  <TextField
                    label={
                      <RequiredTextFieldLabel
                        text={
                          ApplicationString.LocationDetailsStepperComponent
                            .fieldLabels.zipCode
                        }
                        color={
                          errorsData.step2.dropOff.zipCode.error
                            ? '#F30C0C'
                            : '#6B7280'
                        }
                      />
                    }
                    sx={{
                      '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#D1D5DB',
                        },
                      },
                    }}
                    className="rounded-full bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
                    InputProps={{
                      className: 'rounded-[50px] pl-1 h-15',
                    }}
                    InputLabelProps={{
                      className: 'leading-normal text-secondary_text',
                      classes: {
                        root: 'left-1',
                      },
                    }}
                    data-testid="input-zipCode-dropOff"
                    error={errorsData.step2.dropOff.zipCode.error}
                    disabled={
                      formData.step2.isSameAddress ||
                      autoFilledFields.dropOff.zipCode
                    }
                    value={formData.step2.dropOff.zipCode}
                    onChange={handleInputChange('zipCode', 'dropOff')}
                  />
                </FormControl>
                {errorsData.step2.dropOff.zipCode.error && (
                  <FormHelperText
                    className="text-error"
                    sx={{ margin: '0px 14px' }}
                  >
                    {errorsData.step2.dropOff.zipCode.ValidationMessage}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>
        <FormControl
          error={errorsData.step2.country.error}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#D1D5DB',
              },
              '& .MuiSvgIcon-root': {
                right: '11px',
                fontSize: '2rem',
              },
            },
          }}
        >
          <RequiredSelectLabel
            className="ml-1 leading-normal text-secondary_text"
            id="demo-simple-select-label"
            text={
              ApplicationString.LocationDetailsStepperComponent.fieldLabels
                .country
            }
          />
          <Select
            data-testid="select-country"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            open={selectCountriesProps.open}
            onOpen={selectCountriesProps.onOpen}
            onClose={selectCountriesProps.onClose}
            MenuProps={{
              disableScrollLock: true,
              marginThreshold: 20,
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            sx={{
              '& fieldset': `{ border: 0.5, borderColor: '#D1D5DB', ${currentTheme === 'dark' && { border: 0.5, borderColor: 'transparent' }} }`,
            }}
            label={
              ApplicationString.LocationDetailsStepperComponent.fieldLabels
                .country
            }
            value={formData.step2.country}
            disabled={autoFilledFields.country}
            className="rounded-full pl-1 bg-[#F9FAFB] dark:bg-dark_formFieldBg text-secondary_text font-base"
            onChange={handleCountryChange}
          >
            {countriesDataOptions &&
              countriesDataOptions.map((country) => {
                return (
                  <MenuItem key={country.value} value={country.label}>
                    {country.label}
                  </MenuItem>
                );
              })}
            {formData.step2.country &&
              formData.step2.country !== '' &&
              !countriesDataOptions.some(
                (option) => option.label === formData.step2.country
              ) && (
                <MenuItem
                  key={formData.step2.country}
                  value={formData.step2.country}
                >
                  {formData.step2.country}
                </MenuItem>
              )}
          </Select>

          {errorsData.step2.country.error && (
            <FormHelperText className="text-error" sx={{ margin: '0px 14px' }}>
              {errorsData.step2.country.ValidationMessage}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};

export default LocationDetailsStepperComponent;
