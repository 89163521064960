export const localStorageEnums = {
  theme: 'theme',
  userInfo: 'userInfo',
  settings: 'settings',
  step: 'step',
  skippedSteps: 'skippedSteps',
  addedAmenities: 'addedAmenities',
  stepsData: 'stepsData',
  lastAccessUrl: 'lastAccessUrl',
  isStepperHasError: 'isStepperHasError',
  isSlotError: 'isSlotError',
  booking: 'booking',
};

export const categoryEnums = {
  watercraft: 1,
  landVehicles: 2,
};
export const categoryNameEnums = {
  parentKey: 'explore',
  watercraft: 'watercraft',
  landVehicles: 'land vehicles',
};
export const PaginationButtonEnums = {
  next: 'next',
  previous: 'previous',
};
export const myAccountSectionEnums = {
  accountInfo: 'accountinfo',
  settings: 'settings',
  payment: 'payment',
  changePassword: 'changepassword',
};
export const dashboardBookingSectionEnums = {
  confirmed: 'active',
  cancelled: 'cancelled',
  completed: 'completed',
  rejected: 'rejected',
};
export const navigationKeys = {
  delete: 'delete',
  logout: 'logout',
};

// used in payload to account/exists api
export const verificationTypeEnums = {
  email: 'email',
  phone: 'mobile',
};

// refresh token - if token expiry time is near to this time, will refresh the token
export const refreshTokenCallTime = 900; // in seconds

// Specify settings version to use
export const settingslatestVersion = 'latest'; // use this to get the latest settings
export const settingsVersion: string | number = settingslatestVersion; // change this to get desired version

export const loginSignupFormEnums = {
  maxPasswordLength: 64,
  maxPhoneNumberLength: 10,
  maxEmailLength: 255,
  maxTextLength: 255,
};

export const EditProfileFormEnums = {
  maxPasswordLength: 64,
  minPasswordLength: 6,
  maxZipCodeLength: 6,
  maxPhoneNumberLength: 10,
  maxEmailLength: 255,
  maxTextLength: 50,
};

export const LocationDetailsFormEnums = {
  maxCharTextLength: 255,
  maxDigitLengthforZipCode: 6,
};
export const CountryCodeEnums = {
  defaultCountryCode: '+91',
};

export const signupPasswordStrengthEnums = {
  weak: 'weak',
  medium: 'medium',
  strong: 'strong',
};

export const timeFrameData = [
  { label: '12:00 AM', value: 0 },
  { label: '01:00 AM', value: 1 },
  { label: '02:00 AM', value: 2 },
  { label: '03:00 AM', value: 3 },
  { label: '04:00 AM', value: 4 },
  { label: '05:00 AM', value: 5 },
  { label: '06:00 AM', value: 6 },
  { label: '07:00 AM', value: 7 },
  { label: '08:00 AM', value: 8 },
  { label: '09:00 AM', value: 9 },
  { label: '10:00 AM', value: 10 },
  { label: '11:00 AM', value: 11 },
  { label: '12:00 PM', value: 12 },
  { label: '01:00 PM', value: 13 },
  { label: '02:00 PM', value: 14 },
  { label: '03:00 PM', value: 15 },
  { label: '04:00 PM', value: 16 },
  { label: '05:00 PM', value: 17 },
  { label: '06:00 PM', value: 18 },
  { label: '07:00 PM', value: 19 },
  { label: '08:00 PM', value: 20 },
  { label: '09:00 PM', value: 21 },
  { label: '10:00 PM', value: 22 },
  { label: '11:00 PM', value: 23 },
];

export const myBookingsStatusEnums = {
  active: 'active',
  cancelled: 'cancelled',
  rejected: 'rejected',
  completed: 'completed',
  requested: 'requested',
};

export const myBookingsfilterOptions = [
  { label: 'All Bookings', value: 'all' },
  { label: 'Active Bookings', value: myBookingsStatusEnums.active },
  { label: 'Completed Bookings', value: myBookingsStatusEnums.completed },
  { label: 'Cancelled Bookings', value: myBookingsStatusEnums.cancelled },
  { label: 'Rejected Bookings', value: myBookingsStatusEnums.rejected },
  {
    label: 'Pending Approval Bookings',
    value: myBookingsStatusEnums.requested,
  },
];

export const updateProfilePhotoFilesFormats = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'image/webp',
];
export const contactUsAttachment = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
];
export const uploadMaxFileSize = 5; // in MB

export const myListingsStatusEnums = {
  activated: true,
  deactivate: false,
};

export const myBookingsRatingType = 'vehicle';

export const stepperLocalStepDefaultValue = 0;
export const stepperStepDefaultValue = 0;
export const BooleanEnums = {
  true: true,
  false: false,
};
export const filterDateFormat = 'MM/DD/YYYY';
export const systemColorModeEnum = {
  dark: 'dark',
  light: 'light',
};

export const stepsNumberEnum = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
  step7: 7,
};

export const addressComponentsEnums = {
  locality: 'locality',
  state: 'administrative_area_level_1',
  country: 'country',
  zipCode: 'postal_code',
};

export const timeFrameHourEnum = {
  oneHour: 'oneHour',
  twoHours: 'twoHours',
  fourHours: 'fourHours',
  eightHours: 'eightHours',
  twelveHours: 'twelveHours',
  twentyFourHours: 'twentyFourHours',
};

export const gapHoursValues = {
  oneHour: 1,
  twoHours: 2,
  fourHours: 4,
  eightHours: 8,
  twelveHours: 12,
  twentyFourHours: 24,
};

export const bookingsPageSize = 2000;

export const PlaceApiCountries = {
  USA: 'us',
  India: 'in',
};
