/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clockIcon from '../../Assets/clock.png';
import ApplicationString from '../../Constants/applicationString';
import { timeFrameData } from '../../Utils/enums';
import {
  // convertTo12HourFormatForLabel,
  getTimeValue,
  isNonformatTime,
} from '../../Utils/utils';

interface TimeDropdownProps {
  initialPickUpTime: string | null;
  initialDropOffTime: string | null;
  onPickUpTimeChange: (time: string) => void;
  onDropOffTimeChange: (time: string) => void;
  disabledPickUpTimes?: string[];
  disabledDropOffTimes?: string[];
  // eslint-disable-next-line react/require-default-props
  borderRadious?: string | null;
}

const TimeDropdown: React.FC<TimeDropdownProps> = ({
  initialPickUpTime,
  initialDropOffTime,
  onPickUpTimeChange,
  onDropOffTimeChange,
  disabledPickUpTimes = [],
  disabledDropOffTimes = [],
  borderRadious = 'rounded-3xl',
}) => {
  const [pickUpTime, setPickUpTime] = useState<string | null>(
    initialPickUpTime
  );
  const [dropOffTime, setDropOffTime] = useState<string | null>(
    initialDropOffTime
  );
  const [
    disabledDropOffTimesOnPickUpTime,
    setDisabledDropOffTimesOnPickUpTime,
  ] = useState<string[]>(disabledDropOffTimes);
  const [isPickUpOpen, setIsPickUpOpen] = useState<boolean>(false);
  const [isDropOffOpen, setIsDropOffOpen] = useState<boolean>(false);
  const pickUpRef = useRef<HTMLDivElement>(null);
  const dropUpRef = useRef<HTMLDivElement>(null);

  const addTimesAfterCurrentPicked = (
    disableDate: string[],
    currentPickedTime: string
  ): void => {
    const currentPickedValue = getTimeValue(currentPickedTime);

    if (currentPickedValue === null) {
      return;
    }

    const newDisabledTimes = timeFrameData
      .filter((timeFrame) => timeFrame.value > currentPickedValue)
      .map((timeFrame) => timeFrame.label);

    const combinedDisableDate = [...disableDate, ...newDisabledTimes];

    // Remove duplicates
    setDisabledDropOffTimesOnPickUpTime(
      Array.from(new Set(combinedDisableDate))
    );
  };

  const handleClickOutsidePickUp = (event: MouseEvent) => {
    if (
      pickUpRef.current &&
      !pickUpRef.current.contains(event.target as Node)
    ) {
      setIsPickUpOpen(false);
    }
  };

  const handleClickOutsideDropUp = (event: MouseEvent) => {
    if (
      dropUpRef.current &&
      !dropUpRef.current.contains(event.target as Node)
    ) {
      setIsDropOffOpen(false);
    }
  };

  const togglePickUpDropdown = () => setIsPickUpOpen(!isPickUpOpen);
  const toggleDropOffDropdown = () => setIsDropOffOpen(!isDropOffOpen);

  const handlePickUpSelect = (time: string) => {
    if (!disabledPickUpTimes.includes(time)) {
      addTimesAfterCurrentPicked(disabledDropOffTimes, time);
      setPickUpTime(time);
      setIsPickUpOpen(false);
      onPickUpTimeChange(time);
      setDropOffTime('');
      setIsDropOffOpen(true);
      onDropOffTimeChange('');
    }
  };

  const handleDropOffSelect = (time: string) => {
    if (!disabledDropOffTimesOnPickUpTime.includes(time)) {
      setDropOffTime(time);
      setIsDropOffOpen(false);
      onDropOffTimeChange(time); // Call the callback function with the selected time
    }
  };

  useEffect(() => {
    if (pickUpTime) {
      const currentPickedValue = getTimeValue(pickUpTime);
      if (currentPickedValue === null) {
        return;
      }
      const setOfDisabledTimes = timeFrameData
        .filter((timeFrame) => timeFrame.value > currentPickedValue)
        .map((timeFrame) => timeFrame.label);

      setDisabledDropOffTimesOnPickUpTime(setOfDisabledTimes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPickUpOpen) {
      document.addEventListener('mousedown', handleClickOutsidePickUp);
    } else {
      document.removeEventListener('mousedown', handleClickOutsidePickUp);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePickUp);
    };
  }, [isPickUpOpen]);

  useEffect(() => {
    if (isDropOffOpen) {
      document.addEventListener('mousedown', handleClickOutsideDropUp);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDropUp);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropUp);
    };
  }, [isDropOffOpen]);

  return (
    <div className="flex sm:flex-row flex-col gap-2">
      <div
        className="relative w-full"
        ref={pickUpRef}
        data-testid="pickup-container"
      >
        <button
          data-testid="pickup-time-button"
          type="button"
          onClick={togglePickUpDropdown}
          className={`w-full ${borderRadious || 'rounded-3xl'} dark:border-dark_borderStroke gap-1 flex-col p-4 dark:bg-dark_bg_secondary bg-white border border-gray-300 text-gray-700 py-3 px-4 focus:outline-none flex justify-between`}
        >
          <div className="flex w-full justify-between">
            <div className="flex gap-2 items-center">
              <img src={clockIcon} className="w-4 h-4" alt="clock icon" />
              <span className="text-secondary_text dark:text-dark_text_sub_primary">
                {ApplicationString.timeDropdownData.pickUpLabel}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-gray-800 dark:text-dark_text_sub_primary text-sm"
              />
            </div>
          </div>
          <span className="mt-2 text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold">
            {pickUpTime?.includes('NaN')
              ? isNonformatTime(pickUpTime)
              : pickUpTime || ApplicationString.timeDropdownData.selectPickUp}
          </span>
        </button>
        {isPickUpOpen && (
          <ul
            className="absolute overflow-scroll overflow-x-auto h-80 shadow-xl z-20 w-full bg-white border dark:shadow-2xl rounded-xl dark:shadow-slate-700 border-gray-300  mt-1 dark:text-darkPrimary dark:border-dark_borderStroke dark:bg-dark_bg_secondary"
            data-testid="pickup-dropdown"
          >
            {timeFrameData.map((time) => (
              <li
                data-testid={`pickup-option-${time.value}`}
                aria-hidden="true"
                key={time.value}
                onClick={() => handlePickUpSelect(time.label)}
                className={`${time.label === pickUpTime ? 'bg-darkPrimary hover:bg-darkPrimary dark:bg-dark_bg_surface dark:text-darkPrimary text-white' : 'hover:bg-gray-100'} cursor-pointer dark:hover:bg-dark_bg_surface py-2 px-4  ${disabledPickUpTimes.includes(time.label) ? 'text-gray-400 cursor-not-allowed' : ''}`}
              >
                {time.label}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div
        className="relative w-full"
        ref={dropUpRef}
        data-testid="dropoff-container"
      >
        <button
          data-testid="dropoff-time-button"
          type="button"
          disabled={!pickUpTime}
          onClick={toggleDropOffDropdown}
          className={`w-full ${borderRadious || 'rounded-3xl'} dark:border-dark_borderStroke flex-col gap-1 p-4  dark:bg-dark_bg_secondary bg-white border border-gray-300 text-gray-700 py-3 px-4 focus:outline-none flex justify-between`}
        >
          <div className="flex w-full justify-between">
            <div className="flex gap-2 items-center">
              <img src={clockIcon} className="w-4 h-4" alt="clock icon" />
              <span className="text-secondary_text dark:text-dark_text_sub_primary">
                {ApplicationString.timeDropdownData.dropOffLabel}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-gray-800 dark:text-dark_text_sub_primary text-sm"
              />
            </div>
          </div>
          <span className="mt-2 text-lg md:text-2xl dark:text-dart_web_accent text-title_text font-semibold">
            {dropOffTime?.includes('NaN')
              ? isNonformatTime(dropOffTime)
              : dropOffTime || ApplicationString.timeDropdownData.selectDropOff}
          </span>
        </button>
        {isDropOffOpen && (
          <ul
            className="absolute overflow-scroll overflow-x-auto h-80 shadow-xl z-20 w-full dark:shadow-2xl dark:shadow-slate-700 rounded-xl bg-white border border-gray-300  mt-1 dark:text-darkPrimary dark:border-dark_borderStroke dark:bg-dark_bg_secondary"
            data-testid="dropoff-dropdown"
          >
            {timeFrameData.map((time) => (
              <li
                data-testid={`dropoff-option-${time.value}`}
                aria-hidden="true"
                key={time.value}
                onClick={() => handleDropOffSelect(time.label)}
                className={`${time.label === dropOffTime ? 'bg-darkPrimary hover:bg-darkPrimary dark:bg-dark_bg_surface dark:text-darkPrimary text-white' : 'hover:bg-gray-100'} cursor-pointer dark:hover:bg-dark_bg_surface py-2 px-4  ${disabledDropOffTimesOnPickUpTime.includes(time.label) ? 'text-gray-400 cursor-not-allowed' : ''}`}
              >
                {time.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default TimeDropdown;
