import React, { useState, useEffect } from 'react';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';

interface IstarRatingProps {
  rating: number;
  currentRating: number;
  setCurrentRating: React.Dispatch<React.SetStateAction<number>>;
}

const StarRating: React.FC<IstarRatingProps> = ({
  rating,
  currentRating,
  setCurrentRating,
}) => {
  const [hoverRating, setHoverRating] = useState(0);

  useEffect(() => {
    setCurrentRating(Number(rating));
  }, [rating]);

  const handleMouseOver = (index: number) => {
    setHoverRating(index);
  };

  const handleMouseOut = () => {
    setHoverRating(0);
  };

  const handleClick = (index: number) => {
    setCurrentRating(index);
  };

  const renderStars = () => {
    const stars = [];
    const effectiveRating = hoverRating || currentRating;

    for (let i = 1; i <= 5; i += 1) {
      let starIcon;
      if (i <= effectiveRating) {
        starIcon = <StarIcon className="text-ratingStar size-10" />;
      } else if (i - 0.5 <= effectiveRating) {
        starIcon = <StarHalfIcon className="text-ratingStar size-10" />;
      } else {
        starIcon = <StarOutlineIcon className="text-ratingStar size-10" />;
      }

      stars.push(
        <div
          key={i}
          tabIndex={0}
          role="button"
          data-testid="star-rating"
          onMouseOver={() => handleMouseOver(i)}
          onFocus={() => handleMouseOver(i)}
          onMouseOut={handleMouseOut}
          onBlur={handleMouseOut}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick(i);
            }
          }}
          onClick={() => handleClick(i)}
          className="cursor-pointer"
        >
          {starIcon}
        </div>
      );
    }
    return stars;
  };

  return <div className="flex space-x-1">{renderStars()}</div>;
};

export default StarRating;
